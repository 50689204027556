import styled from 'styled-components';
import breakPoints from 'theme/Media';
import { applyStyleModifiers } from 'styled-components-modifiers';

export const carouselImagePositionModifiers = {
  fixed: ({ navHeight }) => ({
    styles: `
      .HeroDetailInner {
        .ProductHeroSliderStyled {
          position: fixed;
          top: ${navHeight || 63}px;
        }
      }
    `,
  }),
  absolute: () => ({
    styles: `
      .HeroDetailInner {
        .ProductHeroSliderStyled {
          position: absolute;
          top: auto;
          bottom: 170px;
        }
      }
    `,
  }),
};

export const HeroWrapper = styled.div.attrs({
  className: 'HeroWrapper',
})`
  * {
    box-sizing: border-box;
  }
`;

export const HeroDetailStyled = styled.div.attrs({
  className: 'HeroDetailStyled',
})`
  > .ComponentModal {
    .ModalWrapper {
      .modal__backdrop {
        background: rgba(0, 0, 0, 0.6);
      }
      .modal__Content {
        padding: 0;
        overflow: visible;
        background-color: transparent;

        button {
          background-color: ${({ theme }) => theme.farmProject.gray_3};
          padding: 0.5rem;
          border-radius: 0.125rem;
          right: -0.8rem;
          top: -0.8rem;
          cursor: pointer;

          svg {
            width: 1rem;
            height: 1rem;
          }
        }

        .ModalInner {
          max-width: unset;

          .ResponsiveEmbed {
            width: 100%;
            padding: 25%;
          }
        }
      }
    }
  }
  @media ${breakPoints.large_break} {
    padding: 4rem 1rem;
    max-width: 64rem;
    margin: auto;

    > .ComponentModal {
      .ModalWrapper {
        .modal__Content {
          max-width: 60rem;

          button {
              top: -0.8rem;
              opacity: 1;
              right: -0.8rem;
            }
          }
        }
      }
    }
    ${applyStyleModifiers(carouselImagePositionModifiers)};
  }
  @media ${breakPoints.xxLarge_break} {
    padding: 4rem 3rem;
    max-width: 69rem;
  }
`;

export const HeroDetailInner = styled.div.attrs({
  className: 'HeroDetailInner',
})`
  position: relative;

  .PlayButtonWrapper {
    .ButtonText {
      padding: 0.5rem 1rem;
      border: solid 1px ${({ theme }) => theme.farmProject.dark_gray}20;
      border-radius: 0.125rem;

      span {
        padding-left: 1.125rem;
      }

      .Icon {
        width: 1.3rem;
        height: 1rem;
        position: absolute;
        left: 0.5rem;

        svg {
          * {
            fill: ${({ theme }) => theme.farmProject.white};
          }
        }
      }
    }
  }

  .ProductHeroSliderMobileStyled {
    display: block;
  }

  .ProductHeroSliderStyled {
    display: none;
    position: relative;
  }

  .AffirmWrapper .Image {
    margin-bottom: 0.5rem;
  }

  @media ${breakPoints.large_break} {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    padding: 1rem;

    .ProductHeroSliderMobileStyled {
      display: none;
    }

    .ProductHeroSliderStyled {
      display: block;
    }
  }
`;

export const ProductInformation = styled.div.attrs({
  className: 'ProductInformation',
})`
  padding: 2rem 1rem;
  max-width: 29rem;
  margin: auto;

  > .Button {
    height: 2.5rem;
    width: 100%;
    margin-top: ${({ isGlowRing }) => (isGlowRing ? 1.25 : 0)}rem;

    span {
      font-size: 0.875rem;
    }
  }

  .RateStarsWrapper {
    margin: 0.25rem 0;
    justify-content: flex-start;
    display: inline-flex;
    vertical-align: middle;
    margin-right: 0.7rem;
  }

  .ProductGiftListStyled {
    margin: 0.8rem 0;
  }
  .Price {
    margin-top: 0.6rem;
    margin-bottom: 0.3rem;
    display: block;
  }
  .RatingWrapper {
    margin-top: 0.5rem;
  }

  .GrowEnvironmentToggleStyled {
    .ToggleStyled {
      margin-left: 0;
    }
    .ButtonGroup {
      background: transparent;
      margin-top: 0;
      padding-left: 0;
      .Button {
        margin-left: 0.5rem;
        margin-right: 0.5rem;
        border-width: 1px;

        // unset hover state override
        &:hover {
          background-color: ${({ theme }) => theme.farmProject.dark_gray};
          border: 1px solid transparent;
          span {
            color: ${({ theme }) => theme.farmProject.gray_3};
          }
        }
      }
    }
    margin-top: 0.6rem;
    margin-bottom: 0.3rem;
  }

  @media ${breakPoints.large_break} {
    padding-top: 0;
    margin-right: 0;
    padding-right: 0;
    max-width: 25rem;
    flex-shrink: 0;
    width: 45%;
  }
  @media ${breakPoints.xxLarge_break} {
    padding-top: 0;
    margin-right: 0;
    padding-right: 0;
    max-width: 26rem;
    flex-shrink: 0;
    width: 40%;
  }
`;

export const ProductDescription = styled.div.attrs({
  className: 'ProductDescription',
})`
  margin: 1rem 0;

  .Text {
    font-size: 1rem;
    a {
      color: ${({ theme }) => theme.farmProject.green};
    }
  }
`;

export const PlayButtonWrapper = styled.div.attrs({
  className: 'PlayButtonWrapper',
})`
  position: absolute;
  left: 1rem;
  top: 23rem;
  z-index: 2;

  .ButtonText {
    cursor: pointer;
    background-color: ${({ theme }) => theme.farmProject.green};
    border-color: ${({ theme }) => theme.farmProject.green};
    transition: all ease-in-out 0.2s;

    span {
      color: ${({ theme }) => theme.farmProject.white};
    }

    .Icon {
      svg {
        * {
          fill: ${({ theme }) => theme.farmProject.white};
        }
      }
    }

    &:hover {
      background-color: ${({ theme }) => theme.farmProject.white};
      border-color: ${({ theme }) => theme.farmProject.green};

      span {
        color: ${({ theme }) => theme.farmProject.dark_gray};
      }

      .Icon {
        svg {
          * {
            fill: ${({ theme }) => theme.farmProject.green};
          }
        }
      }
    }
  }

  @media ${breakPoints.xxLarge_break} {
    top: 31rem;
  }

  @media ${breakPoints.large_break} {
    left: 2rem;
  }
`;
