import styled from 'styled-components';
import { applyStyleModifiers } from 'styled-components-modifiers';

export const modifiers = {
  nativeHeight: () => ({
    styles: `
      height: auto;
      padding-top: 60%;
    `,
  }),
  cover: () => ({
    styles: ` 
      img {
        height: 100%;
        object-fit: cover;
        object-position: center;
      }
    `,
  }),
  relativePosition: () => ({
    styles: ` 
      height: auto;
      width: 100%;
      position: relative;

      .gatsby-image-wrapper {
        position: relative;
        top: auto;
        left: auto;
        transform: none;

        picture {
          img {
            position: absolute;
            top: auto;
            left: auto;
            transform: none;
          }
        }
      }
    `,
  }),
};

const ImageStyled = styled.div.attrs({
  className: 'Image',
})`
  width: 100%;
  height: 100%;
  display: block;
  overflow: hidden;
  position: relative;
  box-sizing: border-box;

  .gatsby-image-wrapper {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    max-width: unset;
    max-height: unset;

    picture {
      img {
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
      }
    }
  }
  ${applyStyleModifiers(modifiers)};
`;

export default ImageStyled;
