import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import { Field, Form, Formik } from 'formik';
import * as Yup from 'yup';

import { Select, Title, SquaredButton, Input, Text } from 'elements';
import { Accordion } from 'components';
import { QuestionFormStyled, NameEmail } from '../ReviewsAndQuestions.styled';

import { YOTPO_FARMSTAND_ID, YOTPO_GLOW_RINGS_ID, productMap, postQuestion, YOTPO_APP_KEY } from 'reduxState/userGenerated/userGenerated';
import { setOpenModal, setLabel } from 'reduxState/modal';

const QuestionForm = ({ isVisible, onSubmit, productId }) => {
  const messages = useSelector((state) => state.contentful.messages);
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [didSubmit, setDidSubmit] = useState(false);
  const dispatch = useDispatch();
  let timeout;

  useEffect(() => {
    return () => {
      clearTimeout(timeout);
    };
    /* eslint-disable-next-line */
  }, []);

  if (!messages) return null;

  const handleQuestionPost = async (values) => {
    try {
      const params = {
        review_content: values.content,
        display_name: values.display_name,
        email: values.email,
        appkey: YOTPO_APP_KEY,
        sku: productId || values.product,
        product_title: productMap[productId || values.product].title,
        product_url: productMap[productId || values.product].url,
        prevent_duplicate_review: true,
      };

      dispatch(postQuestion(params));

      setIsSubmitted(true);
      timeout = setTimeout(() => {
        setDidSubmit(true);
        onSubmit && onSubmit();
      }, 2000);
    } catch (error) {
      dispatch(setOpenModal('error'));
      dispatch(setLabel(error?.message || error?.error));
    }
  };

  return (
    <div>
      <Accordion
        isOpen={isVisible && !didSubmit}
        content={
          isSubmitted ? (
            <Text modifiers='brandColor' content='Thank you for posting a question, we will post our response shortly.'></Text>
          ) : (
            <QuestionFormStyled>
              <Title content='ASK A QUESTION' />
              <Formik
                initialValues={{ content: '', display_name: '', email: '' }}
                validationSchema={Yup.object().shape({
                  content: Yup.string().required(messages.LGE_required),
                  display_name: Yup.string().required(messages.LGE_required),
                  email: Yup.string().email(messages.LGE_email_invalid).required(messages.LGE_required),
                  ...(!productId && { product: Yup.string().required(messages.LGE_required) }),
                })}
                onSubmit={handleQuestionPost}
              >
                {({ isSubmitting, errors, touched }) => {
                  const hasError = !!Object.keys(errors).find((fieldName) => Object.keys(touched)?.includes?.(fieldName));
                  return (
                    <Form>
                      <NameEmail>
                        <Field
                          component={Input}
                          name='display_name'
                          label='Full Name'
                          placeholder='Type your name here'
                          helperText='*Required'
                        />
                        <Field component={Input} name='email' label='Email' placeholder='Type your e-mail here' helperText='*Required' />
                      </NameEmail>
                      {!productId && (
                        <Field
                          component={Select}
                          options={[
                            { label: 'Farmstand', value: YOTPO_FARMSTAND_ID },
                            { label: 'Glow Rings', value: YOTPO_GLOW_RINGS_ID },
                          ]}
                          name='product'
                          label='Product*'
                          placeholder='Select the product for your review'
                          helperText='*Required'
                        />
                      )}
                      <Field
                        component={Input}
                        name='content'
                        label='Question'
                        placeholder='Type your question here'
                        helperText='*Required'
                      />
                      <SquaredButton
                        label='POST'
                        type='submit'
                        disabled={hasError}
                        modifiers={[hasError && 'disabled', isSubmitting && 'loading']}
                        eventData={{ action: `Ask A Question: POST` }}
                      />
                    </Form>
                  );
                }}
              </Formik>
            </QuestionFormStyled>
          )
        }
      ></Accordion>
    </div>
  );
};

QuestionForm.defaultProps = {
  isVisible: false,
  onSubmit: null,
  productId: null,
};
QuestionForm.propTypes = {
  isVisible: PropTypes.bool,
  onSubmit: PropTypes.func,
  productId: PropTypes.oneOf(Object.keys(productMap)),
};
export default QuestionForm;
