import axios from 'axios';

import logError from 'utils/errorHandler';
import { getAuth } from 'firebase/auth';
import { getStorage, getCookie } from './storageManager';
import { gcsUrlPrefix, authDomain } from './envConfig';
import store from 'reduxState/store';
import { setOpenModal, setLabel, MODALS } from 'reduxState/modal';

const HTTP_CODE_EXPECTATION_FAILED = 417;

// Set baseUrl
axios.defaults.baseURL = gcsUrlPrefix || '';

/**
 * * getUtmParams
 * @returns {object} query string utm parameters
 */
const getUtmParams = () => {
  // from GTM
  const params = window?.dataLayer?.find((data) => data.event === 'Pageview');

  if (params) {
    return {
      utm_source: (params.campaignSource || document.referrer || '').toString() || undefined,
      utm_medium: (params.campaignMedium || 'referrer').toString() || undefined,
      utm_campaign: (params.campaignName || '').toString() || undefined,
      utm_term: (params.campaignKeyword || '').toString() || undefined,
      utm_content: (params.campaignContent || '').toString() || undefined,
    };
  }
  return {};
};

const getUserIds = (url) => {
  // get analytics ids from cookie if possible
  const gaClientId = getCookie('_ga');
  const ids = {};

  if (gaClientId && ((!!gcsUrlPrefix && url.includes(gcsUrlPrefix)) || url.startsWith('/'))) {
    ids.gaClientId = gaClientId;
  }
  if (!url.includes('content') && !url.includes('yotpo')) {
    // add our own LG id if GA id is not present
    ids.lgClientId = getStorage('lgClientId');
  }

  return ids;
};

const getCredentialsOption = (url = '') => ({ withCredentials: !!url && !url?.includes('content') && !url?.includes('yotpo') });

const axiosGet = (url, config = {}, authToken) => {
  // Add any UTM params to header
  if (!url.includes('content') && !url.includes('yotpo')) {
    config.headers = Object.assign({}, config.headers, { ...getUtmParams() });
  }

  config.headers = Object.assign({}, config.headers, getUserIds(url));

  const makeRequest = () => {
    return axios
      .get(url, { ...config, ...getCredentialsOption(url) })
      .then((response) => response)
      .catch((error) => {
        // Capture the error
        logError(error, 'axiosGet');
        throw error;
      });
  };

  // get refreshed auth token if private request
  if (authToken && !url.includes('yotpo')) {
    const auth = getAuth();
    const user = auth.currentUser;
    if (!user && !!authToken) {
      // use authToken from state - not ideal as it could be stale
      config.headers = Object.assign({}, config.headers, { idToken: authToken, authDomain });
      return makeRequest();
    }
    if (!user) {
      throw new Error('There was a problem processing your request. Please login to continue');
    }
    return user
      .getIdToken()
      .then((idToken) => {
        config.headers = Object.assign({}, config.headers, { idToken, authDomain });
        return makeRequest();
      })
      .catch((error) => {
        logError(error, 'firebaseGetToken');
        throw error;
      });
  } else {
    return makeRequest();
  }
};

const axiosPost = (url, body, config = {}, authToken) => {
  // Add any UTM params to header

  if (!url.includes('content') && !url.includes('yotpo')) {
    config.headers = Object.assign({}, config.headers, { ...getUtmParams() });
  }

  config.headers = Object.assign({}, config.headers, getUserIds(url));

  const makeRequest = () => {
    return axios
      .post(url, body, { ...config, ...getCredentialsOption(url) })
      .then((response) => response)
      .catch((error) => {
        // Capture the error
        logError(error, 'axiosPost');

        if (!config?.supressErrorCodeExpectation && error?.response?.status === HTTP_CODE_EXPECTATION_FAILED) {
          store.dispatch(setOpenModal(MODALS.ERROR));
          store.dispatch(
            setLabel({
              id: 'error',
              label: error?.response?.data?.message,
            })
          );
        }
        throw error;
      });
  };

  // get refreshed auth token if token is to private endpoint
  if (authToken) {
    const auth = getAuth();
    const user = auth.currentUser;
    if (!user && !!authToken) {
      config.headers = Object.assign({}, config.headers, { idToken: authToken, authDomain });
      return makeRequest();
    }
    if (!user) {
      throw new Error('There was a problem processing your request. Please login to continue');
    }
    return user
      .getIdToken()
      .then((idToken) => {
        config.headers = Object.assign({}, config.headers, { idToken, authDomain });
        return makeRequest();
      })
      .catch((error) => {
        logError(error, 'firebaseGetToken');
        throw error;
      });
  } else {
    return makeRequest();
  }
};

const axiosDelete = (url, config = {}, authToken) => {
  // Add any UTM params to header
  if (!url.includes('content') && !url.includes('yotpo')) {
    config.headers = Object.assign({}, config.headers, { ...getUtmParams() });
  }

  config.headers = Object.assign({}, config.headers, getUserIds(url));

  const makeRequest = () => {
    return axios
      .delete(url, { ...config, ...getCredentialsOption(url) })
      .then((response) => response)
      .catch((error) => {
        // Capture the error
        logError(error, 'axiosDelete');
        throw error;
      });
  };

  // get refreshed auth token if token is to private endpoint
  if (authToken) {
    const auth = getAuth();
    const user = auth.currentUser;
    if (!user && !!authToken) {
      config.headers = Object.assign({}, config.headers, { idToken: authToken, authDomain });
      return makeRequest();
    }
    if (!user) {
      throw new Error('There was a problem processing your request. Please login to continue');
    }
    return user
      .getIdToken()
      .then((idToken) => {
        config.headers = Object.assign({}, config.headers, { idToken, authDomain });
        return makeRequest();
      })
      .catch((error) => {
        logError(error, 'firebaseGetToken');
        throw error;
      });
  } else {
    return makeRequest();
  }
};

export { axiosGet, axiosPost, axiosDelete, getUtmParams, getUserIds };
