import { createSlice, createSelector } from '@reduxjs/toolkit';

import { yotpoAppKey } from 'utils/envConfig';
import { AXIOS } from 'constants/redux';
import { YOTPO_WIDGET, YOTPO_QUESTIONS, YOTPO_PRODUCTS } from 'constants/thirdPartyUrls';
import paths from 'constants/paths';
import { FARMSTAND_12, FARMSTAND_18, FARMSTAND_24, FARMSTAND_30, FARMSTAND_36 } from 'constants/sku';
import { lgUrlPrefix } from 'utils/envConfig';

/**
 * * UGC reducer - yotpo integrated reviews, questions, answers, social
 *
 * @param state
 *
 */

export const YOTPO_APP_KEY = yotpoAppKey; // dev app key

export const YOTPO_FARMSTAND_ID = 'generic-farmstand';
export const YOTPO_GLOW_RINGS_ID = 'generic-glow-rings';

export const YotpoGroupId = {
  FARMSTAND: 'farmstand',
};

export const YotpoFarmstandId = {
  [FARMSTAND_12]: '11',
  [FARMSTAND_18]: '12',
  [FARMSTAND_24]: '13',
  [FARMSTAND_30]: '14',
  [FARMSTAND_36]: '15',
};

export const productMap = {
  [YOTPO_FARMSTAND_ID]: { id: YOTPO_FARMSTAND_ID, title: 'The Farmstand', url: `${lgUrlPrefix}${paths.FARMSTAND}` },
  [FARMSTAND_12]: {
    id: YotpoFarmstandId[FARMSTAND_12],
    title: '12-plant Farmstand',
    url: `${lgUrlPrefix}${paths.FARMSTAND}/12`,
  },
  [FARMSTAND_18]: {
    id: YotpoFarmstandId[FARMSTAND_18],
    title: '18-plant Farmstand',
    url: `${lgUrlPrefix}${paths.FARMSTAND}/18`,
  },
  [FARMSTAND_24]: {
    id: YotpoFarmstandId[FARMSTAND_24],
    title: '24-plant Farmstand',
    url: `${lgUrlPrefix}${paths.FARMSTAND}/24`,
  },
  [FARMSTAND_30]: {
    id: YotpoFarmstandId[FARMSTAND_30],
    title: '30-plant Farmstand',
    url: `${lgUrlPrefix}${paths.FARMSTAND}/30`,
  },
  [FARMSTAND_36]: {
    id: YotpoFarmstandId[FARMSTAND_36],
    title: '36-plant Farmstand',
    url: `${lgUrlPrefix}${paths.FARMSTAND}/36`,
  },
  [YOTPO_GLOW_RINGS_ID]: { id: YOTPO_GLOW_RINGS_ID, title: 'Glow Rings', url: `${lgUrlPrefix}${paths.shopPages.GLOW_RINGS.SIZE_4}` },
};

const initialState = {
  reviews: {
    [YOTPO_FARMSTAND_ID]: { all: [], total: 0, average: 5, grouping_data: {} },
    [YOTPO_GLOW_RINGS_ID]: { all: [], total: 0, average: 5, grouping_data: {} },
    site: { all: [], total: 0, average: 5, byRating: { 1: 0, 2: 0, 3: 0, 4: 0, 5: 0 } }, // all reviews across products
  },
  questions: { [YOTPO_FARMSTAND_ID]: [], [YOTPO_GLOW_RINGS_ID]: [] },
  socialPosts: [],
  loading: {
    questions: true,
  },
};

const userGeneratedSlice = createSlice({
  name: 'userGenerated',
  initialState,
  reducers: {
    setLoadingReviews(state, { payload }) {
      state.loading.reviews = payload;
    },
    fetchProductReviewsComplete(state, { payload: { response, productId } }) {
      state.reviews[productId].all = response.reviews.map((review) => {
        const productTitle = response.grouping_data[review.id]
          ? response.grouping_data[review.id].product_name
          : productMap[productId]
          ? productMap[productId].title
          : review.content.toLowerCase().includes('glow') || review.content.toLowerCase().includes('rings')
          ? productMap[YOTPO_GLOW_RINGS_ID].title
          : productMap[YOTPO_FARMSTAND_ID].title;

        return {
          ...review,
          productTitle,
          product_id: productId,
        };
      });
      state.reviews[productId].total = response.pagination.total;
      state.reviews[productId].average = response.bottomline.average_score;
      state.reviews[productId].groupingData = response.grouping_data;
    },
    setSiteReviews(state, { payload }) {
      state.reviews.site = payload;
    },
    setQuestions(state, { payload }) {
      state.questions[payload.productId] = payload.response.questions.map((question) => ({
        ...question,
        product_id: payload.productId,
      }));
    },
    setSocialPosts(state, { payload }) {
      state.socialPosts = payload;
    },
    setLoadingQuestions(state, { payload }) {
      state.loading.questions = payload;
    },
  },
});

const dateSort = (a, b) => {
  if (a.created_at < b.created_at) {
    return 1;
  } else if (a.created_at > b.created_at) {
    return -1;
  } else {
    return 0;
  }
};

// if no id is provided, return all product questions
export const getQuestionsById = createSelector(
  [
    (state, productId) => {
      if (!productId) {
        return state.userGenerated.questions[YOTPO_FARMSTAND_ID].concat(state.userGenerated.questions[YOTPO_GLOW_RINGS_ID]).sort(dateSort);
      }
      return state.userGenerated.questions[productId];
    },
  ],
  (questions) => questions
);

// if no id is provided, return all product reviews
export const getReviewsById = createSelector(
  [
    (state, productId) => {
      if (!productId) {
        return state.userGenerated.reviews.site.all;
      }
      return state.userGenerated.reviews[productId].all;
    },
  ],
  (reviews) => reviews
);

// if no id is provided, return total number of all product reviews
export const getTotalReviews = createSelector(
  [
    (state, productId) => {
      if (!productId) {
        return state.userGenerated.reviews.site.total;
      }
      return state.userGenerated.reviews[productId].total;
    },
  ],
  (total) => total
);

export const getUserGenerated = (state) => state.userGenerated;
export const getCumulativeReview = createSelector(getUserGenerated, (userGenerated) => userGenerated.reviews.site);
export const getSocialPosts = createSelector(getUserGenerated, (userGenerated) => userGenerated.socialPosts);
export const getFarmstandReviewGroupingData = createSelector(
  getUserGenerated,
  (userGenerated) => userGenerated.reviews[YOTPO_FARMSTAND_ID].groupingData || {}
);

const { actions, reducer } = userGeneratedSlice;

export const {
  setQuestions,
  setLoadingQuestions,
  fetchProductReviewsComplete,
  setSiteReviews,
  setCumulativeReview,
  setLoadingReviews,
  setSocialPosts,
} = actions;

export const fetchReviewsByProduct = (productId, pageSize = 10, pageNum = 1) => {
  const url = `${YOTPO_WIDGET}/${YOTPO_APP_KEY}/products/${productId}/reviews.json?per_page=${pageSize}&page=${pageNum}&sort=date`;
  return {
    type: AXIOS,
    payload: {
      url,
      method: 'GET',
      onSuccess: (response) => fetchProductReviewsComplete({ ...response, productId }),
      setLoading: setLoadingReviews,
    },
  };
};

export const fetchSiteReviews = (pageSize = 10, pageNum = 1) => {
  const url = `${YOTPO_WIDGET}/${YOTPO_APP_KEY}/products/yotpo_site_reviews/reviews.json?per_page=${pageSize}&page=${pageNum}&sort=date`;
  return {
    type: AXIOS,
    payload: {
      url,
      method: 'GET',
      onSuccess: ({ response }) =>
        setSiteReviews({
          all: response.reviews,
          total: response.bottomline?.total_review,
          average: response.bottomline?.average_score,
          byRating: response.bottomline?.star_distribution || {},
        }),
      setLoading: setLoadingReviews,
    },
  };
};

export const fetchQuestions = (productId) => {
  return {
    type: AXIOS,
    payload: {
      url: `${YOTPO_PRODUCTS}/${YOTPO_APP_KEY}/${productId}/questions`,
      method: 'GET',
      onSuccess: (response) => setQuestions({ ...response, productId }),
      setLoading: setLoadingQuestions,
    },
  };
};

export const postQuestion = (data) => {
  return {
    type: AXIOS,
    payload: {
      url: `${YOTPO_QUESTIONS}/send_confirmation_mail`,
      method: 'POST',
      data,
    },
  };
};

export const postReview = (data) => {
  return {
    type: AXIOS,
    payload: {
      url: `${YOTPO_WIDGET}/reviews`,
      method: 'POST',
      data,
    },
  };
};

export default reducer;
