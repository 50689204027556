const green = '#3D714E';
const yellow = '#ffc656';
const pink = '#f1b9a9';
const magenta = '#9e4777';
const red = '#e84b37';
const white = '#fff';

export const farmTheme = {
  farmProject: {
    //Font-family
    font_family: 'Rubik, sans-serif',
    font_family_text: 'Source Serif Pro, serif',

    //Color Pallete
    gray: '#41423e',
    gray_1: '#e8e8e3',
    gray_2: '#efeeeb',
    gray_3: '#f6f6f4',
    gray_4: '#f7f4f0',
    gray_5: '#F9F9F9',
    mid_gray: '#c5c5c1',
    light_gray: '#82827f',
    dark_gray: '#383a36',
    dark_gray_2: '#767674',
    red,
    green,
    light_green: '#a6dab6',
    light_green_2: '#dbe8dc',
    light_green_3: '#FFFAEF',
    light_green_4: '#EFEDE8',
    light_green_5: '#C7DEBC',
    light_green_6: '#EFF6EB',
    light_green_7: '#F1F9EC',
    green_disabled: '#6C6E68',
    yellow,
    pink,
    magenta,
    white,
    dark_green: '#2F7444',
    dark_green2: '#3D714E',

    brand_primary: green,
    brand_secondary: yellow,
    accent_primary: pink,
    accent_secondary: magenta,
    error: red,
  },
  layout: {
    navHeight: '3.5rem',
    navAccessibilityHeight: '5.4rem',
  },
};
