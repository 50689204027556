import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { HeaterSelectorStyled, OptionButtonGroup, SelectOptionButton } from './HeaterSelector.styled';
import { Link, Text } from 'elements';

import growingEnvironments from 'constants/growingEnvironments';
const { OUTDOORS, WITH_HEATER } = growingEnvironments;

const options = [
  { label: 'Yes', value: true },
  { label: 'No', value: false },
];

const HeaterSelector = ({ title, linkCta, field, form }) => {
  const [selectedOption, setSelectedOption] = useState(field.value === WITH_HEATER);

  const onSelectOption = (option) => {
    const growEnvValue = option ? WITH_HEATER : OUTDOORS;
    setSelectedOption(option);
    const setField = form?.setFieldValue;
    if (field?.name && setField) setField(field.name, growEnvValue);
  };

  const renderEnvOption = ({ label, value }, index) => {
    const isSelected = value === selectedOption;
    const modifiers = [isSelected ? 'primaryNoHover' : 'invertedNoHover'];

    return (
      <SelectOptionButton
        type='button'
        key={index}
        modifiers={modifiers}
        label={label}
        ariaLabel={label}
        onClick={() => onSelectOption(value, index)}
        ariaSelected={isSelected ? 'true' : 'false'}
      />
    );
  };

  return (
    <HeaterSelectorStyled>
      {!!title && <Text modifiers={['brandFont', 'bold', 'small', 'left']} content={title} as='span' />}
      <OptionButtonGroup>{options.map(renderEnvOption)}</OptionButtonGroup>
      {!!linkCta?.linkText && <Link modifiers={['brandFont', 'bold', 'small', 'left']} content={linkCta.linkText} href={linkCta.linkUrl} />}
    </HeaterSelectorStyled>
  );
};

HeaterSelector.propTypes = {
  title: PropTypes.string,
  linkCta: PropTypes.object,
  field: PropTypes.object,
  form: PropTypes.object,
};

export default HeaterSelector;
