import React from 'react';
import PropTypes from 'prop-types';
import { navigate } from 'gatsby';
import SquaredButtonBase from '@lettucegrow/atoms.squared-button';

import { prodLinkPathRegex } from 'utils/pattern-utils';
import { trackClick } from 'utils/googleTagManager';

const SquaredButton = ({
  id,
  type,
  disabled,
  onClick,
  label,
  modifiers,
  as,
  href,
  target,
  className,
  dataNw,
  ariaLabel,
  ariaSelected,
  children,
  eventData,
}) => {
  const handleClick = () => {
    if (eventData?.action) trackClick(eventData);

    onClick && onClick();
    const origin = href?.split('?')[0];
    const isHardCodedProdLink = origin?.includes('lettucegrow.com') && !origin?.includes('@');
    const isNewTab = target?.includes('blank');
    const isExternalOrActionLink = origin && (origin?.includes('http') || !origin?.startsWith('/'));
    if (isNewTab) {
      href && window.open(href);
    } else if (isHardCodedProdLink) {
      const pathPattern = prodLinkPathRegex;
      const path = href.match(pathPattern);
      path && path[1] && navigate(path[1]);
    } else if (isExternalOrActionLink) {
      window.location.href = href;
    } else {
      href && navigate(href);
    }
  };

  return (
    <SquaredButtonBase
      id={id}
      type={type}
      disabled={disabled}
      onClick={handleClick}
      label={label}
      modifiers={modifiers}
      as={as}
      target={target}
      className={className}
      dataNw={dataNw}
      ariaLabel={ariaLabel}
      ariaSelected={ariaSelected}
    >
      {children}
    </SquaredButtonBase>
  );
};

SquaredButton.defaultProps = {
  id: null,
  type: '',
  disabled: false,
  onClick: null,
  label: '',
  modifiers: null,
  as: '',
  href: '',
  className: '',
  ariaLabel: null,
  ariaSelected: null,
  eventData: {},
};

SquaredButton.propTypes = {
  id: PropTypes.string,
  type: PropTypes.string,
  disabled: PropTypes.bool,
  onClick: PropTypes.func,
  label: PropTypes.string,
  modifiers: PropTypes.any,
  as: PropTypes.string,
  href: PropTypes.string,
  target: PropTypes.string,
  className: PropTypes.string,
  dataNw: PropTypes.string,
  ariaLabel: PropTypes.string,
  ariaSelected: PropTypes.string,
  children: PropTypes.node,
  eventData: PropTypes.shape({
    action: PropTypes.string,
    currentUrl: PropTypes.string,
  }),
};

export default SquaredButton;
