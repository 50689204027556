import styled from 'styled-components';
import breakPoints from 'theme/Media';

export const ClimateModalStyled = styled.div.attrs({
  className: 'ClimateModal',
})`
  .ModalInner {
    .ModalButtonGroup {
      margin-top: 2rem;
    }
  }

  .Link {
    margin-top: 0.5rem;
  }

  .Title {
    margin-top: 1rem;
  }

  .Text {
    &:last-of-type {
      margin-bottom: 1rem;
    }
  }

  @media ${breakPoints.medium_break} {
    .ModalInner {
      max-width: 30rem;
      .Text {
        &:last-of-type {
          margin-bottom: 0rem;
        }
      }
      .ModalButtonGroup {
        margin-top: 3rem;
      }
    }

    .modal__Content {
      padding-top: 1.2rem;
      padding-bottom: 1.6rem;
    }

    .Title {
      margin-top: 0;
    }

    .ImageLink {
      margin-bottom: 0;
    }
  }
`;

export const ImageLink = styled.div.attrs({
  className: 'ImageLink',
})`
  margin-bottom: 2rem;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  .Image {
    width: 6.25rem;
    height: 6.25rem;
    margin: auto;
    margin-bottom: 0.5rem;
  }

  .Link {
    display: block;
    margin: auto;
    margin-top: 0;
  }

  @media ${breakPoints.medium_break} {
    .Image {
      width: 9rem;
      height: 9rem;
    }
  }
`;
