import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { useLocation } from '@reach/router';
import _take from 'lodash/take';
import _compact from 'lodash/compact';
import { navigate } from 'gatsby';

import { Text, Icon, Input, ButtonText, Link } from 'elements';
import CloseIcon from 'elements/icon/assets/close.svg';
import SearchWrapper, { SearchResults, Spacer } from './Search.styled';

import paths from 'constants/paths';
import { BLOG_POSTS, PLANT_TYPE, FAQS, PLANT_BUNDLE } from 'constants/contentful';
import { useContent } from 'utils/hooks';
import { getSupplies } from 'reduxState/catalog';

const LANDING_PAGES = {
  'How It Works': paths.HOW_IT_WORKS,
  'The Farmstand': paths.FARMSTAND,
  'Our Mission': paths.OUR_MISSION,
  Resources: paths.BLOG,
  Shop: paths.SHOP_PRODUCTS,
  FAQ: paths.FAQ,
  Recipes: paths.RECIPES,
  Seedlings: paths.SEEDLINGS,
  'Gift Cards': paths.GIFT_CARD,
  'Refer A Friend': paths.faqPosts.REFERRAL,
  'Virtual Consultation': paths.VIRTUAL_SHOPPING,
  'Contact Us': paths.CONTACT_US,
};

const EDUCATION_PAGE = {
  TITLE: 'Education Contact Page',
  PATH: paths.EDUCATION,
  KEY_WORDS: ['education', 'give', 'school', 'kid', 'child'],
};

const Search = ({ isExpanded, onClose, isPageScrolled }) => {
  const [searchTerm, setSearchTerm] = useState('');
  const location = useLocation();
  const blogPosts = useContent(BLOG_POSTS, !!searchTerm);
  const seedlings = useContent(PLANT_TYPE, !!searchTerm);
  const seedlingBundles = useContent(PLANT_BUNDLE, !!searchTerm);
  const faqs = useContent(FAQS, !!searchTerm);
  const supplies = useSelector(getSupplies);

  useEffect(() => {
    // reset search state on navigation
    if (!!searchTerm && !location.pathname.includes(paths.SEARCH_RESULTS)) {
      setSearchTerm('');
    }
    /* eslint-disable-next-line */
  }, [location.pathname]);

  const handleSearchChange = (ev) => {
    setSearchTerm(ev.target.value);
  };

  const handleEnter = (ev) => {
    if (ev?.keyCode === 13) {
      navigate(`${paths.SEARCH_RESULTS}?q=${searchTerm}`);
    }
  };

  const getLink = (href, title) => {
    return (
      <Link href={href} key={href}>
        <Text modifiers={['darkGrayColor', 'brandFont', 'small']}>{title}</Text>
      </Link>
    );
  };

  const getBlogPosts = () => {
    if (!blogPosts?.length) return [];
    const blogLinks = blogPosts.map((post) => {
      if (post.fields.title?.toLowerCase?.()?.includes?.(searchTerm?.toLowerCase?.())) {
        return getLink(`${paths.BLOG}/${post.fields.slug}`, post.fields.title);
      } else {
        return null;
      }
    });
    if (!_compact(blogLinks).length) return [];
    return (
      <>
        <Text content='TOP ARTICLE SUGGESTIONS' modifiers={['brandFont', 'lightGrayColor']} />
        {_take(_compact(blogLinks), 3)}
      </>
    );
  };

  const getSeedlings = () => {
    if (!seedlings?.length || !seedlingBundles?.length) return [];
    const seedlingLinks = seedlings.map((seedling) => {
      if (seedling.fields.friendlyName?.toLowerCase?.()?.includes?.(searchTerm?.toLowerCase?.())) {
        return getLink(`${paths.SEEDLINGS}/${seedling.fields.slug}`, seedling.fields.friendlyName);
      } else {
        return null;
      }
    });
    const bundlesLinks = seedlingBundles.map((bundle) => {
      if (
        bundle.fields.plantsLinks?.find?.((plant) =>
          plant.fields?.friendlyName?.toLowerCase?.()?.includes?.(searchTerm?.toLowerCase?.())
        ) ||
        bundle.fields.name?.toLowerCase?.()?.includes?.(searchTerm?.toLowerCase?.())
      ) {
        return getLink(`${paths.BUNDLES}/${bundle.fields.slug}`, bundle.fields.name);
      } else {
        return null;
      }
    });
    const all = [...seedlingLinks, ...bundlesLinks];
    if (!_compact(all).length) return [];
    return (
      <>
        <Text content='SEEDLINGS' modifiers={['brandFont', 'lightGrayColor']} />
        {_take(_compact(all), 3)}
      </>
    );
  };

  const getFAQs = () => {
    if (!faqs?.length) return [];
    const faqLinks = faqs.map((faq) => {
      if (faq.fields.question?.toLowerCase?.()?.includes?.(searchTerm?.toLowerCase?.())) {
        return getLink(`${paths.FAQ}/${faq.fields.slug}`, faq.fields.question);
      } else {
        return null;
      }
    });

    if (!_compact(faqLinks).length) return [];
    return (
      <>
        <Text content='FAQS' modifiers={['brandFont', 'lightGrayColor']} />
        {_take(_compact(faqLinks), 3)}
      </>
    );
  };

  const getEducationLink = () => {
    const term = searchTerm?.toLowerCase?.();
    const titleMatches = EDUCATION_PAGE.TITLE.toLowerCase().includes(term);
    const keyWordsMatches = !!EDUCATION_PAGE.KEY_WORDS.find((kw) => kw.includes(term) || term.includes(kw));
    return (titleMatches || keyWordsMatches) && getLink(EDUCATION_PAGE.PATH, EDUCATION_PAGE.TITLE);
  };

  const Supplies = () => {
    if (!Object.values(supplies)?.length) return [];
    const supplyLinks = Object.values(supplies).map((item) => {
      if (item.name?.toLowerCase?.()?.includes?.(searchTerm?.toLowerCase?.())) {
        return getLink(`${paths.SHOP}/${item.slug}`, item.name);
      } else {
        return null;
      }
    });
    if (!_compact(supplyLinks).length) return [];
    return (
      <>
        <Text content='SHOP' modifiers={['brandFont', 'lightGrayColor']} />
        {supplyLinks}
      </>
    );
  };

  return (
    <>
      <Spacer isExpanded={isExpanded} />
      <SearchWrapper isExpanded={isExpanded} isPageScrolled={isPageScrolled}>
        {isExpanded && (
          <>
            <Input
              value={searchTerm}
              onKeyDown={handleEnter}
              onChange={handleSearchChange}
              modifiers='clear'
              placeholder='Search lettucegrow.com'
              autoFocus
            />
            <ButtonText label='SEARCH' href={`${paths.SEARCH_RESULTS}?q=${searchTerm}`} modifiers='underline' />
            <Icon modifiers='small' onClick={onClose}>
              <CloseIcon></CloseIcon>
            </Icon>
          </>
        )}
        {!!searchTerm && !location.pathname.includes(paths.SEARCH_RESULTS) && (
          <SearchResults>
            <Text content='QUICK LINKS' modifiers={['brandFont', 'lightGrayColor']} />
            {Object.keys(LANDING_PAGES).map((pageTitle) => {
              if (
                pageTitle.toLowerCase().includes(searchTerm.toLowerCase()) ||
                pageTitle === 'Shop' ||
                pageTitle === 'Seedlings' ||
                pageTitle === 'Resources' ||
                pageTitle === 'FAQ'
              ) {
                return getLink(LANDING_PAGES[pageTitle], pageTitle);
              } else {
                return null;
              }
            })}
            {getEducationLink()}
            {getBlogPosts()}
            {getSeedlings()}
            {getFAQs()}
            <Supplies />
          </SearchResults>
        )}
      </SearchWrapper>
    </>
  );
};

Search.propTypes = {
  isExpanded: PropTypes.bool,
  isPageScrolled: PropTypes.bool,
  onClose: PropTypes.func,
};

export default Search;
