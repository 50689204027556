import React from 'react';
import PropTypes from 'prop-types';

import { ContentSection } from 'components';
import { GatsbyImage, SquaredButton, Text, Title } from 'elements';

import { ContentWrapper } from 'staticPages/HowItWorks/HowItWorks.styled';
import { LettuceCommunitySection, CommunityCards, Card } from './GrowCommunity.styled';
import { graphql, StaticQuery } from 'gatsby';

const GrowCommunity = ({ id, title, body, testimonials, callToAction }) => {
  const imageData = graphql`
    query {
      contentfulSectionBlocks(contentful_id: { eq: "72jmO8e1iOhD6UddOPNWhD" }) {
        percentages {
          ... on ContentfulCalloutBlock {
            contentful_id
            mediaContent {
              description
              gatsbyImageData(layout: FULL_WIDTH, placeholder: DOMINANT_COLOR, formats: [AUTO, WEBP], quality: 70)
            }
          }
        }
      }
    }
  `;
  const ctaLabel = callToAction?.fields?.linkText;
  const ctaLink = callToAction?.fields?.linkUrl;

  return (
    <StaticQuery
      query={imageData}
      render={(data) => {
        const testimonialsImagesData = data?.contentfulSectionBlocks?.percentages;
        return (
          <LettuceCommunitySection key={id}>
            <ContentSection animation='fadeInUp'>
              <ContentWrapper>
                <Title as='h3' modifiers='secondarySmall' content={title} />
                <Text content={body} />
              </ContentWrapper>
              <ContentWrapper>
                <CommunityCards>
                  {testimonials.map((testimonial) => {
                    const id = testimonial?.sys.id;
                    const { title } = testimonial?.fields;
                    const testimonialImage = testimonialsImagesData?.find?.((image) => image.contentful_id === id)?.mediaContent;

                    return (
                      <Card key={id}>
                        <GatsbyImage image={testimonialImage?.gatsbyImageData} alt={testimonialImage?.description} />
                        <Text content={title} />
                      </Card>
                    );
                  })}
                </CommunityCards>
                <SquaredButton label={ctaLabel} href={ctaLink} target='_blank' as='a' />
              </ContentWrapper>
            </ContentSection>
          </LettuceCommunitySection>
        );
      }}
    />
  );
};

GrowCommunity.propTypes = {
  id: PropTypes.string,
  title: PropTypes.string,
  body: PropTypes.string,
  testimonials: PropTypes.array,
  callToAction: PropTypes.object,
};

export default GrowCommunity;
