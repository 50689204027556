import styled from 'styled-components/macro';
import breakPoints from 'theme/Media';

export const FormWrapper = styled.div.attrs({
  className: 'FormWrapper',
})`
  padding: 2rem 0 0;

  @media ${breakPoints.large_break} {
    padding: 1rem 0 0;
  }
`;

export const FormGroup = styled.div.attrs({
  className: 'FormGroup',
})`
  form > div:not(.TextWrapper) {
    margin: 1rem auto;
    max-width: 86%;
  }

  form > .Button:last-of-type {
    margin: 1rem auto 0;
  }

  a {
    color: ${({ theme }) => theme.farmProject.brand_primary};
  }

  .Button:not(.SelectSizeButton) {
    width: 100%;
    max-width: 15.5rem;
    height: 2.25rem;
    margin: auto;
    margin-top: 1.75rem;
    margin-bottom: 1.5rem;
  }

  .PhotoContainer {
    .Text:first-of-type {
      line-height: normal;
    }
    .AddPhotoBtnGroup {
      margin: 0 1rem 0 0;
    }
    .PhotoWrapper {
      overflow: hidden;
    }
  }

  .FarmstandSizeStyled,
  .EnvSelectorStyled,
  .HeaterSelectorStyled,
  .LightsSelectorStyled {
    .Text {
      align-self: flex-start;
      text-align: left;
    }
    .Button {
      &:not(:last-of-type) {
        margin: 0 0.5rem 0 0;
      }
      &:last-of-type {
        margin: 0;
      }
    }
    .SelectSizeButton {
      width: 2.5rem;
    }
  }

  .InputStyled {
    div {
      width: 100%;
      max-width: 100%;
    }
  }

  .buttonGroup .Button:not(.submitButton) {
    margin-right: 1rem;
  }

  @media ${breakPoints.large_break} {
    form > div:not(.TextWrapper) {
      margin: 1.25rem auto;
      max-width: 28rem;
    }
  }
`;

export const TextWrapper = styled.div.attrs({
  className: 'TextWrapper',
})`
  margin: auto;
  padding: 0 1.25rem;

  .Title {
    letter-spacing: 0.1rem;
    margin-bottom: 0.5rem;
    margin-top: 0;
  }

  @media ${breakPoints.large_break} {
    .Title {
      font-size: 3.125rem;
      line-height: 1.1;
      font-weight: 300;
    }

    .Text {
      font-size: 1rem;
    }
  }
`;
