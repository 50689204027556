import { environment } from 'utils/envConfig';

export default function logError(error) {
  const readableError = error?.response?.data || error?.response || error;
  if (environment === 'production') {
    const initializeLogRocket = new Promise((resolve) => {
      window.LogRocket && window.LogRocket.init('yibjp2/lg-prod', { release: 'lettucegrow-' + process.env.GATSBY_APP_VERSION });
      resolve(readableError);
    });
    initializeLogRocket.then(() => {
      window.LogRocket ? window.LogRocket.captureException(readableError) : console.error(readableError);
    });
  }
  console.error(readableError);
}
