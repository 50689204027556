import React, { useRef } from 'react';
import { Formik, Form, Field } from 'formik';
import PropTypes from 'prop-types';
import { useLocation } from '@reach/router';

import { ImageSlider, Toggle } from 'components';
import { Image, Title, Text, Checkbox, SquaredButton } from 'elements';

import {
  HarvestProductCardStyled,
  HarvestProducWrapper,
  HarvestProducInformation,
  SeedlingListWrapper,
  GlowRingCheckWrapper,
  GlowInfoWrapper,
  ProductMedia,
  FarmstandSizeStyled,
  SizeButtonGroup,
  SelectSizeButton,
} from './HarvestProductMain.styled';

import { replaceText } from 'utils/string-utils';
import { formatPrice } from 'utils/cart-utils';

const HarvestProductMain = ({
  topPickSize,
  bundlesSeedlings,
  toggleFirstLabel,
  toggleSecondLabel,
  buttonPrice,
  isGRChecked,
  selectedSize,
  selectedOption,
  selectedGlowRing,
  availableSizes,
  sectionData,
  glowRingsData,
  pickLabelData,
  onSelectSize,
  onToggleOption,
  onGlowRingsCheck,
  onAddItemsToCart,
  shouldHidePlantsList = false,
  cardModifiers,
  renderRedemptionSection,
}) => {
  const isFirstOptionSelected = selectedOption === 0;
  const { title = '', body = '', body2 = '', mediaBackground: images = [] } = sectionData || {};
  const formRef = useRef();
  const { pathname } = useLocation();
  const shouldEnableSizeSelector =
    pathname.indexOf('the-knot') === -1 && pathname.indexOf('costco') === -1 && pathname.indexOf('/amazonbundle') === -1;

  const onToggleSection = (isFirst) => onToggleOption?.(isFirst ? 0 : 1);

  const handleGlowRingCheckboxUpdate = (newCheckValue) => {
    onGlowRingsCheck?.(newCheckValue);
  };

  const renderSizeButton = (farmSize, key) => {
    const isSelected = selectedSize === farmSize;
    const sizeLabel = farmSize?.toString();
    const hasTopPickLabel = sizeLabel === topPickSize?.toString?.() && !!pickLabelData?.text;
    const modifiers = [isSelected ? 'primaryNoHover' : 'invertedNoHover', hasTopPickLabel && 'topPick'];

    const onClick = () => onSelectSize && onSelectSize(farmSize);

    return <SelectSizeButton key={key} label={sizeLabel} topPickLabel={pickLabelData?.text} onClick={onClick} modifiers={modifiers} />;
  };

  return (
    <HarvestProductCardStyled modifiers={cardModifiers}>
      <HarvestProducWrapper>
        <ProductMedia>
          <ImageSlider images={images} />
        </ProductMedia>
        <HarvestProducInformation>
          <Title as='h3' content={title} />
          {toggleFirstLabel && toggleSecondLabel && (
            <Toggle
              startOn={true}
              labelOn={toggleFirstLabel}
              labelOff={toggleSecondLabel}
              onChange={onToggleSection}
              onModifiers={isFirstOptionSelected ? 'primaryNoHover' : 'invertedNoHover'}
              offModifiers={!isFirstOptionSelected ? 'primaryNoHover' : 'invertedNoHover'}
              shouldUseDefaultModifiers={false}
            />
          )}
          <Text content={body} modifiers={['brandFont', 'light']} isHTML />
          {shouldEnableSizeSelector && (
            <FarmstandSizeStyled>
              <SizeButtonGroup>{availableSizes?.map(renderSizeButton)}</SizeButtonGroup>
            </FarmstandSizeStyled>
          )}
          {renderRedemptionSection && renderRedemptionSection()}
          {!shouldHidePlantsList && (
            <SeedlingListWrapper>
              <Text modifiers={['brandFont', 'small', 'midGrayColor']}>{bundlesSeedlings}</Text>
            </SeedlingListWrapper>
          )}
          {glowRingsData && (
            <GlowRingCheckWrapper>
              <Image picture={glowRingsData?.mediaContent?.fields?.file?.url} alt={glowRingsData?.mediaContent?.fields?.description} />
              <GlowInfoWrapper>
                <Text content={glowRingsData?.title} modifiers={['brandFont']} />
                <Text content={glowRingsData?.body} modifiers={['brandFont', 'small']} />
                <Formik innerRef={formRef} initialValues={{ addGlowRing: isGRChecked || false }}>
                  {({ setFieldValue, values }) => (
                    <Form>
                      <Field
                        component={Checkbox}
                        onChange={() => {
                          setFieldValue('addGlowRing', !values.addGlowRing);
                          handleGlowRingCheckboxUpdate(!values.addGlowRing);
                        }}
                        name='addGlowRing'
                        label={replaceText(glowRingsData?.headline, [
                          { key: '__PRICE__', value: formatPrice(selectedGlowRing?.priceCents / 100, 0) },
                        ])}
                        value={values.addGlowRing}
                      />
                    </Form>
                  )}
                </Formik>
              </GlowInfoWrapper>
            </GlowRingCheckWrapper>
          )}
          {onAddItemsToCart && (
            <SquaredButton modifiers='tertiary' label={body2 ? body2 : `ADD TO CART - ${buttonPrice}`} onClick={onAddItemsToCart} />
          )}
        </HarvestProducInformation>
      </HarvestProducWrapper>
    </HarvestProductCardStyled>
  );
};

HarvestProductMain.propTypes = {
  topPickSize: PropTypes.number,
  bundlesSeedlings: PropTypes.string,
  toggleFirstLabel: PropTypes.string,
  toggleSecondLabel: PropTypes.string,
  buttonPrice: PropTypes.string,
  isGRChecked: PropTypes.bool,
  selectedSize: PropTypes.number,
  selectedOption: PropTypes.number,
  selectedGlowRing: PropTypes.object,
  availableSizes: PropTypes.array,
  sectionData: PropTypes.object,
  glowRingsData: PropTypes.object,
  pickLabelData: PropTypes.object,
  onSelectSize: PropTypes.func,
  onToggleOption: PropTypes.func,
  onGlowRingsCheck: PropTypes.func,
  onAddItemsToCart: PropTypes.func,
  shouldHidePlantsList: PropTypes.bool,
  cardModifiers: PropTypes.any,
  renderRedemptionSection: PropTypes.func,
};

export default HarvestProductMain;
