import React, { useEffect, useContext, createContext } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import 'utils/firebaseConfig';
import {
  getAuth,
  onAuthStateChanged,
  onIdTokenChanged,
  signInWithEmailAndPassword,
  createUserWithEmailAndPassword,
  updatePassword,
  signOut as firebaseSignOut,
  sendPasswordResetEmail as firebaseSendPasswordResetEmail,
  confirmPasswordReset as firebaseConfirmPasswordReset,
  checkActionCode as firebaseCheckActionCode,
} from 'firebase/auth';

import { userAuthenticated, idTokenChanged, userLogout, getUserAuthToken, setUserValue } from 'reduxState/user';
import logError from 'utils/errorHandler';

const auth = getAuth();

/**
 * * useAuth hook for firebase auth functions and user from redux
 */

export const authContext = createContext();

// Provider component that wraps your app and makes auth object ...
// ... available to any child component that calls useAuth().
/* eslint-disable-next-line */
export function ProvideAuth({ children }) {
  const auth = useProvideAuth();
  return <authContext.Provider value={auth}>{children}</authContext.Provider>;
}

// Hook for child components to get the auth object ...
// ... and re-render when it changes.
export const useAuth = () => {
  return useContext(authContext);
};

export const useProvideAuth = () => {
  const dispatch = useDispatch();
  const userAuthToken = useSelector(getUserAuthToken);

  const signIn = (email, password) => {
    return signInWithEmailAndPassword(auth, email, password)
      .then((response) => {
        return response.user;
      })
      .catch((error) => {
        if (error?.code === 'auth/wrong-password') return null;
        logError(error);
        return null;
      });
  };

  const signUp = (email, password) => {
    return createUserWithEmailAndPassword(auth, email, password)
      .then((response) => {
        return response.user;
      })
      .catch((error) => {
        logError(error);
        return null;
      });
  };

  const signOut = () => {
    return firebaseSignOut(auth)
      .then(() => {
        dispatch(userLogout());
      })
      .catch((error) => {
        logError(error);
      });
  };

  const sendPasswordResetEmail = (email) => {
    return firebaseSendPasswordResetEmail(auth, email)
      .then(() => {
        return true;
      })
      .catch((error) => {
        logError(error);
        return false;
      });
  };

  const confirmPasswordReset = (code, password) => {
    return firebaseConfirmPasswordReset(auth, code, password)
      .then(() => {
        return true;
      })
      .catch((error) => {
        logError(error);
        return false;
      });
  };

  const checkActionCode = (code) => {
    return firebaseCheckActionCode(auth, code)
      .then(() => {
        return true;
      })
      .catch((error) => {
        logError(error);
        return false;
      });
  };

  // programmatically change a user's password.
  // currently used for updating guest -> account status
  const changePassword = async (oldPassword, newPassword) => {
    try {
      const user = auth.currentUser;
      if (!user) return false;
      return updatePassword(user, newPassword)
        .then(() => true)
        .catch(() => false);
    } catch (error) {
      logError(error);
      return false;
    }
  };

  // Subscribe to user on mount
  // Because this sets state in the callback it will cause any ...
  // ... component that utilizes this hook to re-render with the ...
  // ... latest auth object.
  // not called if only idToken changes
  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (user) {
        user.getIdToken().then((authToken) => {
          dispatch(userAuthenticated({ authToken, email: user.email }));
        });
      } else {
        dispatch(setUserValue({ label: 'isAuthenticationPending', value: false }));
      }
    });

    // Cleanup subscription on unmount
    return () => unsubscribe();
    // eslint-disable-next-line
  }, []);

  // update authToken if it was refreshed
  useEffect(() => {
    const unsubscribe = onIdTokenChanged(auth, (fbUser) => {
      if (fbUser) {
        fbUser.getIdToken().then((authToken) => {
          if (authToken !== userAuthToken) {
            dispatch(idTokenChanged({ authToken }));
          }
        });
      }
    });

    return () => unsubscribe();
    // eslint-disable-next-line
  }, []);

  // Return the user object and auth methods
  return {
    signIn,
    signUp,
    signOut,
    sendPasswordResetEmail,
    confirmPasswordReset,
    checkActionCode,
    changePassword,
  };
};
