import styled from 'styled-components';
import breakPoints from 'theme/Media';

export const HowItWorksStyled = styled.div.attrs({
  className: 'HowItWorks',
})`
  margin: auto;

  .HeroSection {
    max-height: 46rem;

    .HeroInner {
      .Title {
        font-size: 2.25rem;
        max-width: 16rem;
        padding-right: 0;
        margin-bottom: 1rem;
      }

      .Text {
        max-width: 19rem;
      }

      .Image {
        picture {
          img {
            width: 115%;
            height: auto;
          }
        }
      }
    }
  }

  @media ${breakPoints.medium_break} {
    .HeroSection {
      height: 42rem;
      padding: 4rem 2rem 5rem;

      &::before {
        height: 101%;
      }

      .HeroInner {
        max-width: 33.5rem;

        .Title {
          font-size: 3rem;
          max-width: 20rem;
          margin-left: 0;
        }

        .Text {
          max-width: 22rem;
        }

        .Image {
          height: 101%;

          picture {
            img {
              width: 105%;
            }
          }
        }
      }
    }

    .SectionInner {
      max-width: 33.5rem;
      margin: auto;
    }
  }

  @media ${breakPoints.large_break} {
    .HeroSection {
      .HeroInner {
        max-width: 62rem;

        .Title {
          font-size: 4rem;
          max-width: 30rem;
        }

        .Image {
          picture {
            img {
              width: 115%;
              height: auto;
            }
          }
        }
      }
    }

    .SectionInner {
      max-width: 62rem;
    }
  }
`;

export const ContentWrapper = styled.div.attrs({
  className: 'ContentWrapper',
})``;

export const TradGardeningSection = styled.div.attrs({
  className: 'TradGardeningSection',
})`
  .ContentSection {
    .SectionInner {
      .ContentWrapper {
        .Title {
          font-size: 1.75rem;
          margin-bottom: 1rem;
        }

        > .Text {
          max-width: 17rem;
          margin: auto;
        }
      }
    }
  }

  @media ${breakPoints.medium_break} {
    .ContentSection {
      .SectionInner {
        .ContentWrapper {
          .Title {
            font-size: 2.3125rem;
            margin-bottom: 1rem;
          }

          > .Text {
            max-width: 26rem;
          }
        }
      }
    }
  }

  @media ${breakPoints.large_break} {
    .ContentSection {
      .SectionInner {
        .ContentWrapper {
          .Title {
            font-size: 3rem;
            max-width: 40rem;
            margin: 0 auto 1.5rem;
          }
        }
      }
    }
  }
`;

export const GardeningTable = styled.div.attrs({
  className: 'GardeningTable',
})`
  margin: 2.5rem 0 0;

  @media ${breakPoints.medium_break} {
    margin-top: 3rem;
  }

  @media ${breakPoints.large_break} {
    max-width: 45rem;
    margin: 4rem auto 0;
  }
`;

export const TableHead = styled.div.attrs({
  className: 'TableHead',
})`
  display: flex;
  justify-content: space-between;
  align-items: center;

  @media ${breakPoints.medium_break} {
    justify-content: flex-end;
    margin-bottom: 0.5rem;
  }
`;

export const TheadCell = styled.div.attrs({
  className: 'TheadCell',
})`
  display: flex;
  justify-content: flex-start;
  width: 50%;

  &:last-of-type {
    width: 50%;

    .Text {
      color: ${({ theme }) => theme.farmProject.green};
    }
  }

  .Text {
    font-family: ${({ theme }) => theme.farmProject.font_family};
    line-height: normal;
    width: 7rem;
    display: block;
    text-align: left;
    margin-left: 0;
  }

  @media ${breakPoints.medium_break} {
    width: 33%;
    justify-content: center;

    .Text {
      text-align: center;
    }

    &:last-of-type {
      width: 33%;
    }
  }
`;

export const TableBody = styled.div.attrs({
  className: 'TableBody',
})``;

export const TableRow = styled.div.attrs({
  className: 'TableRow',
})`
  margin: 1.5rem 0;
  display: flex;

  &:last-of-type {
    margin-bottom: 0;
  }

  @media ${breakPoints.medium_break} {
    padding: 1.5rem 0;
    border-bottom: solid 1px ${({ theme }) => theme.farmProject.dark_gray};
    margin: 0;

    &:last-of-type {
      border-bottom: none;
      padding-bottom: 0;
    }
  }
`;

export const TBodyCell = styled.div.attrs({
  className: 'TBodyCell',
})`
  width: 100%;
  display: flex;
  flex-wrap: wrap;

  .Text {
    font-family: ${({ theme }) => theme.farmProject.font_family};
    text-align: left;
    width: calc(50% - 8px);
    display: block;

    &:first-of-type {
      font-size: 1rem;
      width: 100%;
      padding-bottom: 0.45rem;
      border-bottom: solid 1px ${({ theme }) => theme.farmProject.dark_gray};
      margin-bottom: 0.45rem;
    }

    &:last-of-type {
      width: calc(50% - 8px);
      color: ${({ theme }) => theme.farmProject.green};
      margin-left: 8px;
    }
  }

  @media ${breakPoints.medium_break} {
    .Text {
      width: 33%;
      text-align: center;

      &:first-of-type {
        font-size: 0.9rem;
        text-align: left;
        width: 33%;
        border-bottom: none;
        padding-bottom: 0;
        margin-bottom: 0;
      }

      &:last-of-type {
        width: 33%;
        margin-left: 0;
      }
    }
  }
`;

export const HealthySection = styled.div.attrs({
  className: 'HealthySection',
})`
  .ContentSection {
    .SectionInner {
      .ContentWrapper {
        .Title {
          max-width: 18rem;
          margin: auto;
          margin-bottom: 1rem;
        }

        .Text {
          max-width: 18rem;
          margin: auto;
        }
      }
    }
  }

  @media ${breakPoints.medium_break} {
    .ContentSection {
      .SectionInner {
        .ContentWrapper {
          .Title {
            font-size: 3rem;
            max-width: 30rem;
          }

          .Text {
            max-width: 23rem;
            margin: auto;
          }
        }
      }
    }
  }

  @media ${breakPoints.large_break} {
    .ContentSection {
      .SectionInner {
        .ContentWrapper {
          .Title {
            font-size: 5rem;
            max-width: 50rem;
          }

          .Text {
            max-width: 34rem;
          }
        }
      }
    }
  }
`;

export const PercentageWrapper = styled.div.attrs({
  className: 'PercentageWrapper',
})`
  width: 100%;
  height: auto;
  margin: 3rem auto 0;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  flex-direction: column;

  div {
    &:nth-child(2) {
      margin: -1.5rem auto;
    }
  }

  @media ${breakPoints.medium_break} {
    flex-direction: row;

    div {
      &:nth-child(2) {
        margin: 0 -1.875rem;
      }
    }
  }

  @media ${breakPoints.large_break} {
    max-width: 55rem;
    margin: auto;
    margin-top: 4rem;

    div {
      &:nth-child(2) {
        margin: 0 -2rem;
      }
    }
  }
`;

export const Percentage = styled.div.attrs({
  className: 'Percentage',
})`
  width: 11.25rem;
  height: 11.25rem;
  border: solid 1px ${({ theme }) => theme.farmProject.green};
  margin: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  border-radius: 100%;

  .Text {
    font-family: ${({ theme }) => theme.farmProject.font_family};
    font-size: 2.625rem;
    color: ${({ theme }) => theme.farmProject.green};
    line-height: normal;
    width: 100%;
    margin: 0 auto;
    display: block;
  }

  .Text + .Text {
    font-size: 0.75rem;
  }

  @media ${breakPoints.large_break} {
    width: 17rem;
    height: 17rem;
    margin: 0;

    .Text {
      font-size: 4.25rem;
    }

    .Text + .Text {
      font-size: 1rem;
    }
  }
`;
