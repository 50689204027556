import { createAction, createSlice, createSelector } from '@reduxjs/toolkit';
import { AXIOS } from 'constants/redux';
import { prettyFormatPhone } from 'utils/form-utils';
import { setCountries } from 'reduxState/globalUI';

import { deliveryStatus } from 'constants/deliveries';
import { getStorage } from 'utils/storageManager';
import { formatForUTCTimestamp } from 'utils/date-utils';

/**
 * * user - Redux Reducer
 *
 * user  Content type from Contentful
 *
 * @param state
 *
 */

const now = new Date();
now.setHours(0, 0, 0, 0);

export const initialState = {
  email: '',
  password: '', // only used for temp guest password
  firstName: '',
  lastName: '',
  address: '',
  postStop: '',
  city: '',
  state: '',
  zip: '', // no default here- will be set by initial /catalog response (BE guesses user zip for guest user)
  countryId: '',
  isZipSupported: true,
  phone: '',
  authToken: '',
  hasAccount: false,
  myFarm: null,
  myOrders: null,
  myOrdersDeliveries: [],
  areMyOrdersDeliveriesFetched: false,
  devices: [],
  areDevicesFetched: false,
  hasPurchasedFarmstand: false,
  hasTakenSurvey: false,
  isFromMobile: false,
  isAuthenticationPending: true,
  isGuest: true,
  userSetEnvironment: 'DEFAULT',
};

const isAuthAction = (action) => [idTokenChanged.toString(), userLogin.toString(), userAuthenticated.toString()].includes(action.type);

// reducer, action types, action creators all in 1 createSlice
const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    // set fetched data into store
    setUserValue(state, { payload }) {
      state[payload.label] = payload.value;
    },
    setUserFromCheckout(state, { payload }) {
      const { email, firstName, lastName, address, postStop, city, state: userState, zip, phone } = payload;
      state.email = email;
      state.firstName = firstName;
      state.lastName = lastName;
      state.address = address;
      state.postStop = postStop;
      state.city = city;
      state.state = userState;
      state.zip = zip;
      state.phone = phone;
    },
    setUserZip(state, { payload }) {
      state.zip = payload;
      state.userSetEnvironment = 'DEFAULT';
    },
    // user has a Firebase account, and has intentionally logged in with their account credentials
    userLogin(state) {
      state.isGuest = false;
      state.password = initialState.password; // reset for the case of newly created account
    },
    userLogout() {
      return { ...initialState, isAuthenticationPending: false };
    },
    setMyFarm(state, { payload }) {
      const { address, postStop, city, state: userState, zip, phone } = payload.customer;
      state.myFarm = payload;
      state.email = payload.contact.email;
      state.firstName = payload.contact.name ? payload.contact.name.split(' ')[0] : '';
      state.lastName = payload.contact.name ? payload.contact.name.split(' ')[1] : '';
      state.address = address;
      state.postStop = postStop;
      state.city = city;
      state.state = userState;
      state.zip = city ? zip : ''; // dont set zip after GC only purchase (no other address field will be present)
      state.phone = phone.includes('+1') ? prettyFormatPhone(phone) : phone;
      state.hasPurchasedFarmstand = payload.devices.length > 0 ? true : false;
    },
    setMyOrders(state, { payload }) {
      state.myOrders = payload;
    },
    setMyOrdersDeliveries(state, { payload }) {
      state.myOrdersDeliveries = payload.orders;
      state.areMyOrdersDeliveriesFetched = true;
    },
    fetchUserDevicesComplete(state, { payload }) {
      state.devices = payload;
      state.areDevicesFetched = true;
    },
    setUserFromOrderConfirmation(state, { payload }) {
      const { hasPurchasedFarmstand, hasTakenSurvey } = payload;
      state.hasPurchasedFarmstand = hasPurchasedFarmstand;
      state.hasTakenSurvey = hasTakenSurvey;
    },
    setUserSetEnvironment(state, { payload }) {
      if (payload) {
        /**
         * OUTDOOR should be considered OUTDOOR.
         * Any other values (WITH_HEAT, CUSTOM, INDOOR etc) is considered INDOOR.
         */
        state.userSetEnvironment = payload === 'OUTDOOR' ? 'OUTDOOR' : 'INDOOR';
      } else {
        state.userSetEnvironment = 'DEFAULT';
      }
    },
    setIsZipSupported(state, { payload }) {
      state.isZipSupported = payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(setCountries.toString(), (state, { payload }) => {
      state.countryId = payload.detected?.id;
    });
    builder.addMatcher(isAuthAction, (state, { payload }) => {
      state.authToken = payload?.authToken || state.authToken;
      state.email = payload?.email || state.email;
      state.isAuthenticationPending = false;
    });
  },
});

// Extract the action creators object and the reducer
const { actions, reducer } = userSlice;

// Extract and export action creators from slice by name
export const {
  setUserValue,
  userLogin,
  userLogout,
  setUserZip,
  setMyFarm,
  setMyOrders,
  setMyOrdersDeliveries,
  fetchUserDevicesComplete,
  setUserFromCheckout,
  setUserFromOrderConfirmation,
  setUserSetEnvironment,
  setIsZipSupported,
} = actions;

export const idTokenChanged = createAction('user/idTokenChanged');

// user has a Firebase account, but has not intentionally created a lettuce grow account or logged in with existing account (isGuest = true)
export const userAuthenticated = createAction('user/userAuthenticated');

export const userCreateAccount = createAction('user/createAccount');

export const userCreateFarmDevice = createAction('user/createFarmstandDevice');

export const userUpdateFarmDevice = createAction('user/updateFarmstandDevice');

export const userDeleteFarmDevice = createAction('user/deleteFarmstandDevice');

// Export the reducer as the default
export default reducer;

export const getUser = (state) => state.user;

export const getEmail = createSelector(getUser, (user) => user.email);

export const getUserSetEnv = createSelector(getUser, (user) => user.userSetEnvironment);

export const getUserIsFromMobile = createSelector(getUser, (user) => user.isFromMobile);

export const getLoginState = createSelector([(state) => state.user], (user) => !!user.authToken && !user.isGuest);

export const getMyFarm = createSelector(
  (state) => state.user,
  (user) => user.myFarm
);

export const getUserAuthToken = createSelector(
  (state) => state.user,
  (user) => user.authToken
);

// HEADS UP - this should be used sparingly and should NOT be used to determine logged in status, see getLoginState above
export const getIsGuest = createSelector(
  (state) => state.user,
  (user) => user.isGuest
);

export const getHasAccount = createSelector(getUser, (user) => user.hasAccount);

export const getCustomer = createSelector(getMyFarm, (myFarm) => myFarm?.customer || {});

export const getContact = createSelector(getMyFarm, (myFarm) => myFarm?.contact || {});

export const getRecommendation = createSelector(getMyFarm, (myFarm) => myFarm?.recommendation);

export const getHasTakenSurvey = createSelector(getUser, (user) => user.hasTakenSurvey);

export const getHasPurchasedFarmstand = createSelector(getUser, (user) => user.hasPurchasedFarmstand);

export const getHasPurchasedOrAddedFarmstand = createSelector(getUser, (user) => user.hasPurchasedFarmstand || !!user.devices.length);

export const getUserOrders = createSelector(getUser, (user) => (user.myOrders ? user.myOrders : {}));

export const getAllUserDevices = createSelector(getUser, (user) => user.devices || []);

export const getAreUserDevicesFetched = createSelector(getUser, (user) => user.areDevicesFetched);

export const getMyOrdersDeliveries = createSelector(getUser, (user) => user.myOrdersDeliveries);

export const getAreMyOrdersDeliveriesFetched = createSelector(getUser, (user) => user.areMyOrdersDeliveriesFetched);

export const getCustomerMixes = createSelector(getMyFarm, (myFarm) => myFarm?.customerMixes || []);

export const getHasCustomerMixes = createSelector(getCustomerMixes, (mixes) => !!mixes.length);

export const getCredits = createSelector(getMyFarm, ({ credits }) => [...credits]?.sort((x, y) => y.created - x.created) || []);

export const getCategorizedCredits = createSelector(getCredits, (credits) => {
  const defaultCategory = 'General';
  const currentTimestamp = formatForUTCTimestamp(new Date() + 1);
  let currentCategory;
  let currentExpirationDate;

  return credits.reduce((result, currentCredit) => {
    currentExpirationDate = formatForUTCTimestamp(currentCredit.endDate);
    currentCategory =
      currentCredit?.type === 'GIFT'
        ? `Lettuce Grow Gift Card #${currentCredit.code}`
        : currentCredit?.category?.displayName || defaultCategory;

    if (currentExpirationDate && currentExpirationDate < currentTimestamp) return result;
    if (currentCredit.valueCents - currentCredit.usedCents <= 0) return result;

    (result[currentCategory] = result[currentCategory] || []).push(currentCredit);
    return result;
  }, {});
});

export const getIsMyFarmLoaded = createSelector(getMyFarm, (myFarm) => !!myFarm?.customer?.id || !!myFarm?.contact?.id);

export const getUserHasOrders = createSelector(getMyFarm, (myFarm) => {
  return !!myFarm?.customerOrders?.length || !!myFarm?.deliveries?.length;
});

export const getHasPaymentDataOrCredits = createSelector([getUserOrders, getCredits], (orders, credits) => {
  const { cards, currentSubscriptions } = orders;
  const hasPaymentData = !!cards?.length || !!currentSubscriptions?.length;
  const hasCredits = !!credits?.length;

  return hasPaymentData || hasCredits;
});

export const getCustomerOrderById = createSelector([getUserOrders, (_, id) => id], (orders, id) =>
  orders?.customerOrders?.find((order) => order.orderDetails.orderNumber === id)
);

// HEADS UP - a pending order is any order with at least one delivery in pending status
export const getPendingOrders = createSelector(getMyOrdersDeliveries, (orders) => {
  const _isOrderPending = (order) =>
    order.deliveries.find((delivery) => delivery.status === deliveryStatus.PENDING || delivery.status === 'PICKED_UP');
  return orders.filter(_isOrderPending).sort((a, b) => b.created - a.created);
});

export const getDeliveredOrders = createSelector([getMyOrdersDeliveries, getPendingOrders], (allOrders, pendingOrders) =>
  allOrders.filter((order) => !pendingOrders.find((pendingOrder) => pendingOrder.id === order.id)).sort((a, b) => b.created - a.created)
);

export const getOrderById = createSelector([getMyOrdersDeliveries, (_, id) => id], (orders, id) =>
  orders?.find((order) => order.id === id)
);

// HEADS UP - outdated selectors getting delivery data from myFarm property
export const getAllDeliveries = createSelector(getMyFarm, (myFarm) => myFarm?.deliveries || []);
export const getDeliveryById = createSelector([getAllDeliveries, (state, id) => id], (deliveries, id) =>
  deliveries?.find((delivery) => delivery.customerDelivery?.id === id)
);

export const getUserCountryId = createSelector([(state) => state.user], (user) => user.countryId);

export const getUserZip = createSelector(getUser, (user) => user.zip);

export const getCAPIUserInfo = createSelector(getUser, (user) => ({
  user_id: getStorage('lgClientId'),
  email: user.email || undefined,
  phoneNumber: user.phone || undefined,
  firstName: user.firstName || undefined,
  lastName: user.lastName || undefined,
  city: user.city || undefined,
  state: user.state || undefined,
  zip: user.zip || undefined,
}));

/**
 * * fetchMyFarm - Async Action Creator to hit catalog BE endpoint
 *
 */ export const fetchMyFarm = () => ({
  type: AXIOS,
  payload: {
    url: '/app/lgcom/v2/myFarm',
    method: 'GET',
    onSuccess: setMyFarm,
  },
});

/**
 * * fetchMyOrders - Async Action Creator to hit myOrders BE endpoint
 */

export const fetchMyOrders = () => ({
  type: AXIOS,
  payload: {
    url: '/app/lgcom/myOrders',
    method: 'GET',
    onSuccess: setMyOrders,
  },
});

/**
 * * fetchMyOrdersDeliveries - Async Action Creator to hit myOrdersDeliveries BE endpoint
 */

export const fetchMyOrdersDeliveries = () => ({
  type: AXIOS,
  payload: {
    url: '/app/lgcom/v1/myOrdersDeliveries',
    method: 'GET',
    onSuccess: setMyOrdersDeliveries,
  },
});

/**
 * * fetchUserDevices - Async Action Creator to hit deviceSetup BE endpoint
 */

export const fetchUserDevices = () => ({
  type: AXIOS,
  payload: {
    url: '/app/lgcom/deviceSetup',
    method: 'GET',
    onSuccess: fetchUserDevicesComplete,
  },
});
