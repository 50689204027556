import React, { useRef, useState } from 'react';
import { Formik, Field, Form } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';

import { Title, SquaredButton, Input } from 'elements';
import { ImageUploads, SizeSelector, Flex, WarningModal } from 'components';
import { FormWrapper, FormGroup, TextWrapper } from './FarmForm.styled';

import EnvSelector from './components/env-selector/EnvSelector';
import HeaterSelector from './components/heater-selector/HeaterSelector';
import LightsSelector from './components/lights-selector/LightsSelector';

import { getDevicePayload, getValidationSchema, initialFormValues } from './FormHelpers';
import growingEnvironments from 'constants/growingEnvironments';
import { getContentMessages } from 'app/reduxState/contentful';
import { getFarmstandsSizes } from 'app/reduxState/catalog';
import { getUserAuthToken, userCreateFarmDevice, userDeleteFarmDevice, userUpdateFarmDevice } from 'app/reduxState/user';
import { hasErrorOnTouchedFields } from 'utils/form-utils';

const { OUTDOORS, WITH_HEATER } = growingEnvironments;

const FarmForm = ({ farmstand, onSubmit }) => {
  const [isFetching, setIsFetching] = useState(false);
  const dispatch = useDispatch();
  const deleteModalRef = useRef();
  const farmSizes = useSelector(getFarmstandsSizes);
  const authToken = useSelector(getUserAuthToken);
  const messages = useSelector(getContentMessages);
  const isEdit = !!farmstand?.id;

  const initialValues = isEdit ? farmstand : initialFormValues;
  const titleAndSubmitCTALabel = `${isEdit ? 'EDIT' : 'ADD'} FARMSTAND`;
  const farmstandName = isEdit ? farmstand.name : 'New Farmstand';
  const description = `${isEdit ? 'Edit' : 'Add'} your Farmstand's information using the fields below.`;

  const handleSubmit = async (values, { resetForm }) => {
    const onEndSubmitting = () => {
      resetForm();
      onSubmit?.();
    };

    setIsFetching(true);
    const deviceData = getDevicePayload(values);
    const action = isEdit ? userUpdateFarmDevice : userCreateFarmDevice;
    dispatch(action({ id: isEdit ? farmstand.id : null, deviceData, authToken, setIsFetching, onSubmit: onEndSubmitting }));
  };

  const handleOpenDeleteModal = () => {
    deleteModalRef.current?.openModal();
  };

  const handleDelete = () => {
    setIsFetching(true);
    deleteModalRef.current?.closeModal();
    dispatch(userDeleteFarmDevice({ id: farmstand.id, authToken, setIsFetching, onSubmit }));
  };

  return (
    <FormWrapper>
      <FormGroup>
        <Formik initialValues={initialValues} onSubmit={handleSubmit} validationSchema={getValidationSchema(messages)}>
          {({ setFieldValue, values, errors, touched }) => {
            const isOutdoorsEnv = [OUTDOORS, WITH_HEATER].includes(values.growingEnvironment);
            const isSubmitDisabled = hasErrorOnTouchedFields({ errors, touched });

            return (
              <Form noValidate>
                <TextWrapper>
                  <Title content={titleAndSubmitCTALabel} modifiers={['secondarySmall', 'center']} />
                </TextWrapper>
                <ImageUploads
                  values={{ image: values.image }}
                  setFieldValue={setFieldValue}
                  title={farmstandName}
                  description={description}
                  acceptedTypes={['image/*']}
                />
                <Field
                  required
                  variant='filled'
                  name='name'
                  type='text'
                  label='Farmstand Name'
                  autoComplete='name'
                  helperText='*Required'
                  component={Input}
                />
                <Field
                  required
                  variant='filled'
                  name='zipCode'
                  inputMode='numeric'
                  label='Zip Code'
                  helperText='*Required'
                  component={Input}
                />
                <Field title='SELECT FARMSTAND SIZE*' name='size' sizes={farmSizes} component={SizeSelector} />
                <Field title='SELECT YOUR GROWING LOCATION*' name='growingEnvironment' component={EnvSelector} />
                {isOutdoorsEnv && <Field title='USING A FARMSTAND HEATER?' name='growingEnvironment' component={HeaterSelector} />}
                <Field title='GLOW RINGS™*' name='glowRings' component={LightsSelector} />
                <Flex className='buttonGroup'>
                  {isEdit && !isFetching && (
                    <SquaredButton
                      type='button'
                      label='DELETE'
                      modifiers={['whiteActive', 'lightGrayColor']}
                      onClick={handleOpenDeleteModal}
                    />
                  )}
                  <SquaredButton
                    type='submit'
                    className='submitButton'
                    label={titleAndSubmitCTALabel}
                    disabled={isSubmitDisabled || isFetching}
                    modifiers={[isFetching ? 'loading' : 'tertiary', isSubmitDisabled && 'disabled']}
                  />
                </Flex>
              </Form>
            );
          }}
        </Formik>
      </FormGroup>
      <WarningModal
        ref={deleteModalRef}
        title='DELETE FARMSTAND'
        content='Are you sure you want to delete this Farmstand?<br/>This cannot be undone.'
        cancelCTA={{ linkText: 'CANCEL' }}
        confirmCTA={{ linkText: 'YES, DELETE' }}
        onConfirm={handleDelete}
      />
    </FormWrapper>
  );
};

FarmForm.propTypes = {
  onSubmit: PropTypes.func,
  farmstand: PropTypes.object,
};

export default FarmForm;
