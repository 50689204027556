import React from 'react';
import { Field } from 'formik';
import { useSelector } from 'react-redux';
import axios from 'axios';
import PropTypes from 'prop-types';

import { Input } from 'elements';

import { getContentMessages } from 'app/reduxState/contentful';
import { axiosGet } from 'utils/api-utils';
import { authDomain } from 'utils/envConfig';
import logError from 'utils/errorHandler';
import { isLikeDomain } from 'utils/string-utils';

import { whiteListedDomains } from './constants';

const FormikEmailInput = ({
  name = 'email',
  label = 'Email',
  onBlur,
  required,
  autoComplete = 'email',
  variant,
  helperText,
  emailInUseError,
  shouldValidate = true,
  dataNw,
  disabled,
  modifiers,
}) => {
  const invalidEmailError = useSelector(getContentMessages)?.LGE_email_invalid;

  const handleEmailBlur = async (ev, formValues) => {
    onBlur?.(ev, formValues);
  };

  const getEmailDomain = (email = '') => {
    const [splitEmail, afterAtString = ''] = email.split('@');
    const afterAtStringValid = isLikeDomain(afterAtString);
    return splitEmail[0] !== '' && afterAtStringValid ? afterAtString : null;
  };

  const getEmailDomainError = async (email) => {
    let isValidEmail = false;

    const domain = getEmailDomain(email);
    if (domain) {
      isValidEmail = whiteListedDomains.find((whiteListedDomain) => whiteListedDomain === domain);
      if (!isValidEmail) {
        isValidEmail = !!(await axios.get(`https://dns.google/resolve?name=${domain}&type=MX`))?.data?.Answer?.length;
      }
    }

    return isValidEmail ? null : invalidEmailError;
  };

  const getEmailIsInUseError = async (email) => {
    if (!emailInUseError || !getEmailDomain(email)) return null;
    let isAccountInUse = false;

    try {
      // the /checkAccount call is needed as well for successful guest → new user creation flow
      const response = await axiosGet(`/app/public/checkAccount/${email}`, { headers: { authDomain } });
      isAccountInUse = !!response?.data;
    } catch (error) {
      isAccountInUse = false;
      logError(error);
    }
    return isAccountInUse ? emailInUseError : null;
  };

  const onValidate = async (email) => {
    if (!shouldValidate) return null;

    const domainError = await getEmailDomainError(email);
    if (domainError) return domainError;

    const emailIsInUseError = await getEmailIsInUseError(email);
    if (emailIsInUseError) return emailIsInUseError;

    return null;
  };

  return (
    <Field
      name={name}
      label={label}
      variant={variant}
      type='email'
      component={Input}
      onBlur={handleEmailBlur}
      validate={onValidate}
      required={required}
      autoComplete={autoComplete}
      helperText={helperText}
      dataNw={dataNw}
      disabled={disabled}
      modifiers={modifiers}
    />
  );
};

FormikEmailInput.propTypes = {
  name: PropTypes.string,
  label: PropTypes.string,
  variant: PropTypes.string,
  autoComplete: PropTypes.string,
  onBlur: PropTypes.func,
  required: PropTypes.bool,
  dataNw: PropTypes.string,
  helperText: PropTypes.string,
  emailInUseError: PropTypes.any,
  shouldValidate: PropTypes.bool,
  disabled: PropTypes.bool,
  modifiers: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
};

export default FormikEmailInput;
