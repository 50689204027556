import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Link, navigate } from 'gatsby';
import { useLocation } from '@reach/router';

import { openCartModal } from 'reduxState/cart';
import { getLoginState, getMyFarm, getUserIsFromMobile, fetchMyFarm } from 'reduxState/user';

import { Image, Icon } from 'elements';
import Logo from './assets/logo.svg';
import NavStyled, { NavButtonsWrappers, Spacer, CartWrapper, IconWrapper } from './Navigation.styled';
import Sandwich from './Sandwich';
import Menu from './Menu';

import ChevronLeft from 'elements/icon/assets/left.svg';
import ChevronRight from 'elements/icon/assets/right.svg';
import CartIcon from 'elements/icon/assets/cart.svg';
import CartFilledIcon from 'elements/icon/assets/filled-cart.svg';
import UserIcon from 'elements/icon/assets/user.svg';
import ChevronIcon from 'elements/icon/assets/chevron-thin.svg';
import SearchIcon from 'elements/icon/assets/search.svg';

import paths from 'constants/paths';
import { useToggleBodyScrolling, useScrollListener } from 'utils/hooks';

import Search from './Search';
import AccountMenu from './AccountMenu';

const MenuItem = {
  ACCOUNT_SUB_MENU: 'subMenu',
  SEARCH: 'search',
  CART_DRAWER: 'cartDrawer',
  MOBILE_MENU: 'mobileMenu',
};

const Navigation = () => {
  const cart = useSelector((state) => state.cart);
  const myFarm = useSelector(getMyFarm);
  const dispatch = useDispatch();
  const location = useLocation();
  const [selectedMenu, setSelectedMenu] = useState(location.pathname.includes(paths.SEARCH_RESULTS) ? MenuItem.SEARCH : '');
  const isLoggedIn = useSelector(getLoginState);
  const isFromMobile = useSelector(getUserIsFromMobile);
  const isCheckout = location.pathname.includes(paths.CHECKOUT);
  const isPageScrolled = useScrollListener(20);
  useToggleBodyScrolling(selectedMenu === MenuItem.MOBILE_MENU);

  // close menu if menu is open when route changes
  useEffect(() => {
    if (!location.pathname.includes(paths.SEARCH_RESULTS)) {
      setSelectedMenu('');
    }
  }, [location]);

  useEffect(() => {
    if (isLoggedIn && !myFarm) {
      dispatch(fetchMyFarm());
    }
  }, [isLoggedIn, dispatch, myFarm]);

  const cartItemQty = cart.items.reduce((total, item) => {
    total += item.qty;
    return total;
  }, 0);

  const handleCartOpen = () => {
    setSelectedMenu('');
    dispatch(openCartModal());
  };

  const handleMenuClick = (menuClicked) => {
    if (menuClicked === selectedMenu) {
      setSelectedMenu('');
    } else {
      setSelectedMenu(menuClicked);
    }
  };

  const goBack = () => navigate(-1);

  const goForward = () => navigate(1);

  const isAccountSubMenuOpen = selectedMenu === MenuItem.ACCOUNT_SUB_MENU;

  return (
    <>
      <Spacer />
      <NavStyled isBasic={isCheckout} modifiers={(isPageScrolled || !!cart.sideModalToShow) && 'fixedTop'}>
        {!isFromMobile && !isCheckout && (
          <Sandwich isMenuOpen={selectedMenu === MenuItem.MOBILE_MENU} onClick={() => handleMenuClick(MenuItem.MOBILE_MENU)} />
        )}
        {isFromMobile && (
          <NavButtonsWrappers>
            <Icon modifiers='small' onClick={goBack}>
              <ChevronLeft />
            </Icon>
            <Icon modifiers='small' onClick={goForward}>
              <ChevronRight />
            </Icon>
          </NavButtonsWrappers>
        )}

        <Link to={paths.HOME}>
          <Image picture={Logo} alt='logo' />
        </Link>

        {!isCheckout && (
          <>
            <Menu isOpen={selectedMenu === MenuItem.MOBILE_MENU} isPageScrolled={isPageScrolled} />
            <div className='NavigationAccount' data-nw='account-menu-icon'>
              <IconWrapper onClick={() => handleMenuClick(MenuItem.ACCOUNT_SUB_MENU)} data-test-id='account-nav-expand'>
                <Icon modifiers={['small', isLoggedIn && 'brandStroke']}>
                  <UserIcon />
                </Icon>
                <Icon modifiers={['small', isAccountSubMenuOpen ? 'rotate90' : 'rotate270']}>
                  <ChevronIcon />
                </Icon>
              </IconWrapper>
              <AccountMenu isOpen={isAccountSubMenuOpen} />
            </div>
            <Icon modifiers='small' onClick={() => handleMenuClick(MenuItem.SEARCH)}>
              <SearchIcon></SearchIcon>
            </Icon>
            <CartWrapper data-nw='cart-button' type='button' title='Your Cart' onClick={handleCartOpen}>
              <Icon modifiers='small'>{cartItemQty > 0 ? <CartFilledIcon /> : <CartIcon />}</Icon>
              <span className='cart-icon__qty'>{cartItemQty <= 99 ? cartItemQty : '99+'}</span>
            </CartWrapper>
          </>
        )}
      </NavStyled>
      <Search onClose={() => handleMenuClick('')} isExpanded={selectedMenu === MenuItem.SEARCH} isPageScrolled={isPageScrolled} />
    </>
  );
};

Navigation.defaultProps = {};
Navigation.propTypes = {};

export default Navigation;
