import { createAction, createSlice, createSelector } from '@reduxjs/toolkit';
import { AXIOS } from 'constants/redux';
import { GIFT_CARD } from 'constants/contentful';
import { getAffirmMin } from 'reduxState/appSettings';

import { getTotalCost } from 'utils/cart-utils';
import { HALO_LIGHTS_SKUS } from 'constants/sku';
import shopCategories from 'constants/shopCategories';

const { FARMSTAND, SEEDLINGS, SUPPLIES, EXTENSION, BUNDLES, CUSTOM_BUNDLES } = shopCategories;

/**
 * * cart - Redux Reducer
 *
 * cart  Content type from Contentful
 *
 * @param state
 *
 */

export const initialState = {
  items: [],
  giftCard: {
    imageUrl: '',
    amount: '',
  },
  subtotalCents: 0,
  shippingCents: 0,
  baseShippingCents: 0,
  shippingMessages: [], // returned from /cartPreview for shipping tooltip content
  taxCents: 0,
  discounts: {},
  urlDiscount: null,
  manualDiscounts: [],
  promoCents: 0,
  giftCents: 0,
  giftBalanceCents: 0,
  giftsWithPurchase: [],
  creditCents: 0,
  creditBalanceCents: 0,
  totalCents: 0,
  minGiftDate: null,
  shipDate: null,
  termsOfServiceVersion: '',
  verifiedAddress: {
    address: '',
    postStop: '',
    city: '',
    state: '',
    zip: '73301',
    success: false,
    code: '',
  },
  selectedShippingOptions: [],
  shippingOptions: [],
  shouldOverrideAddress: false,
  hasVerifiedAddressError: false, // ! Can not submit with this true
  hasCalledVerifiedAddress: false,
  isLoadingCartPreview: false,
  isCheckingOut: false,
  isAltPaymentReady: false,
  sideModalToShow: '',
  cartSeedlingsExpanded: false,
  isGift: false,
  lastAddedFarmstandId: '',
  hasBERemovedSeedlings: false,
  cartPreviewError: {},
  paymentType: 'credit',
  canUpdate: true,
  forceCartPreviewFetchOnCheckout: false,
  isAltDuplicatedOrder: false,
  isLoadingDuplicateOrder: false,
};

// sort and return items in cart
const sortCart = (items) => {
  if (items.length < 2) return items;
  // take items and split them up into two piles
  // farmstands - seedlings
  const farmstands = [];
  const supplies = [];
  const seedlings = [];
  const misc = [];
  items.forEach((item) => {
    if (item.category === FARMSTAND) {
      farmstands.push(item);
    } else if (item.category === SUPPLIES) {
      supplies.push(item);
    } else if (item.category === SEEDLINGS) {
      seedlings.push(item);
    } else {
      misc.push(item);
    }
  });
  // sort each of these piles (price - price - name)
  const priceDescendingSort = (a, b) => b.priceCents - a.priceCents;
  if (farmstands.length >= 2) farmstands.sort(priceDescendingSort);
  if (supplies.length >= 2) supplies.sort(priceDescendingSort);
  if (misc.length >= 2) misc.sort(priceDescendingSort);
  if (seedlings.length >= 2) {
    seedlings.sort((a, b) => {
      const nameA = a.name.toUpperCase();
      const nameB = b.name.toUpperCase();
      if (nameA < nameB) {
        return -1;
      }
      if (nameA > nameB) {
        return 1;
      }

      // names must be equal
      return 0;
    });
  }
  // spread back into return value in the order that we want!
  return [...farmstands, ...supplies, ...seedlings, ...misc];
};

// reducer, action types, action creators all in 1 createSlice
const cartSlice = createSlice({
  name: 'cart',
  initialState,
  reducers: {
    addBulkItems(state, { payload }) {
      const { items } = payload;
      items.forEach((item) => {
        if (item.qty > 0) {
          const foundItemIdx = state.items.findIndex((cartItem) => cartItem.sku === item.sku);
          if (foundItemIdx > -1) {
            // Item already exists in cart. Add to qty.
            state.items[foundItemIdx].qty += item.qty;
          } else {
            // Push new item into cart array
            state.items.push(item);
          }
        }
      });
    },
    addBulkItemsAndDiscount(state, { payload }) {
      const { items, discount } = payload;
      items.forEach((item) => {
        if (item.qty > 0) {
          const foundItemIdx = state.items.findIndex((cartItem) => cartItem.sku === item.sku);
          if (foundItemIdx > -1) state.items[foundItemIdx].qty += item.qty;
          else state.items.push(item);
        }
      });

      if (discount) state.discounts = { [discount]: 0, ...state.discounts };
      const isDiscountSaved = state.manualDiscounts.includes(discount);
      if (!isDiscountSaved && discount) state.manualDiscounts.push(discount);
    },
    addBulkItemsAndDiscountArray(state, { payload }) {
      const { items, discount } = payload;

      items.forEach((item) => {
        if (item.qty > 0) {
          const foundItemIdx = state.items.findIndex((cartItem) => cartItem.sku === item.sku);
          if (foundItemIdx > -1) state.items[foundItemIdx].qty += item.qty;
          else state.items.push(item);
        }
      });

      const reducedDiscounts = discount.reduce((acc, curr) => {
        acc[curr] = 0;
        return acc;
      }, {});

      state.discounts = { ...state.discounts, ...reducedDiscounts };
      discount.forEach((item) => {
        const isDiscountSaved = state.manualDiscounts.includes(item);
        if (!isDiscountSaved) state.manualDiscounts.push(item);
      });
    },
    addItem(state, { payload }) {
      // look to see if item exists in cart already
      const foundItemIdx = state.items.findIndex((item) => item.sku === payload.item.sku);
      if (foundItemIdx > -1) {
        // Item already exists in cart.
        if (payload.item.sku === GIFT_CARD.id) {
          // Item is Gift Card. Overwrite old gift card options with this one.
          state.items[foundItemIdx] = payload.item;
        } else {
          // For all other items just update the qty
          state.items[foundItemIdx].qty += payload.item.qty;
        }
      } else {
        // Push new item into cart array
        state.items.push(payload.item);
      }
    },
    replaceSeedlings(state, { payload }) {
      const { items } = payload;
      const categoriesToRemove = [SEEDLINGS, BUNDLES, CUSTOM_BUNDLES];
      const newItems = state.items.filter((item) => !categoriesToRemove.includes(item.category));
      items.forEach((item) => {
        if (item.qty > 0) {
          const foundItemIdx = newItems.findIndex((cartItem) => cartItem.sku === item.sku);
          if (foundItemIdx > -1) {
            // Item already exists in cart. Add to qty.
            newItems[foundItemIdx].qty += item.qty;
          } else {
            // Push new item into cart array
            newItems.push(item);
          }
        }
      });
      state.items = newItems;
    },
    setGiftAmount(state, { payload }) {
      state.giftCard.amount = payload;
    },
    setGiftImage(state, { payload }) {
      state.giftCard.imageUrl = payload;
    },
    removeItem(state, { payload }) {
      state.items = state.items.filter((item) => item.sku !== payload);
    },
    removeItemOfType(state, { payload }) {
      state.items = state.items.filter((item) => item.type !== payload.data);
    },
    removeItemsOfCategories(state, { payload }) {
      state.items = state.items.filter((item) => !payload.includes(item.category));
    },
    updateItem(state, { payload }) {
      state.items.forEach((item) => {
        if (item.sku === payload.sku) {
          return (item.qty = payload.qty);
        }
      });
    },
    setDiscount(state, { payload }) {
      // the BE only applies the first promotion code if there are multiple, so add new promo as first obj entry
      state.discounts = { [payload]: 0, ...state.discounts };

      // track code in manual discounts as well to differentiate between BE credit auto-application
      const isDiscountSaved = state.manualDiscounts.includes(payload);
      if (!isDiscountSaved) state.manualDiscounts.push(payload);
    },
    setUrlDiscount(state, { payload }) {
      state.urlDiscount = payload;
      state.discounts = { [payload]: 0, ...state.discounts };
    },
    removeDiscount(state, { payload }) {
      delete state.discounts[payload];
    },
    setGiftState(state, { payload }) {
      state.isGift = payload;
    },
    toggleGiftCheckoutState(state) {
      state.isGift = !state.isGift;
    },
    // set a value (value) into cart property (label)
    setCartValue(state, { payload }) {
      state[payload.label] = payload.value;
    },
    // set values from Order Preview
    setCartPreview(state, { payload }) {
      state.hasBERemovedSeedlings = getTotalItems(payload.items) < getTotalItems(state.items);
      state.subtotalCents = payload.subtotalCents;
      state.shippingMessages = payload.shippingMessages;
      // state.shippingCents = payload.shippingCents; - HEADS UP - shipping gets set by user interaction now
      state.taxCents = payload.taxCents;
      state.totalCentsChange = payload.totalCents - state.totalCents;
      state.totalCents = payload.totalCents;
      state.items = payload.items;
      state.items = sortCart(state.items);
      state.discounts = payload.discounts;
      state.promoCents = payload.promoCents;
      state.giftCents = payload.giftCents;
      state.giftBalanceCents = payload.giftBalanceCents;
      state.giftsWithPurchase = payload.giftsWithPurchase;
      state.creditCents = payload.creditCents;
      state.creditBalanceCents = payload.creditBalanceCents;
      state.minGiftDate = payload.minGiftDate;
      state.manualDiscounts = state.manualDiscounts.filter((md) => Object.keys(state.discounts).includes(md));
      state.shipDate = payload.shipDate;
    },
    setCartPreviewError(state, { payload }) {
      state.cartPreviewError = payload;
    },
    setLastAddedFarmstandId(state, { payload }) {
      state.lastAddedFarmstandId = payload;
    },
    setHasBERemovedSeedlings(state, { payload }) {
      state.hasBERemovedSeedlings = payload;
    },
    // set the verified address from backend verify address endpoint
    setVerifiedAddress(state, { payload: { address, code, success } }) {
      state.verifiedAddress = {
        ...address,
        code,
        success,
      };
      state.hasVerifiedAddressError = false;
      state.hasCalledVerifiedAddress = true;
    },
    setVerifiedAddressError(state) {
      state.verifiedAddress.success = false;
      state.hasVerifiedAddressError = true;
    },
    // set loading cart preview state
    setLoadingCartPreview(state, { payload }) {
      state.isLoadingCartPreview = payload;
    },
    // action that doesn't change store, but only triggers the `/cartPreview` axios request on cartMiddleware.js
    loadCartPreview(state) {
      return state;
    },
    setShippingOptions(state, { payload }) {
      state.shippingOptions = payload;
    },
    openCartModal(state, { payload }) {
      state.sideModalToShow = 'cart';
      state.cartSeedlingsExpanded = !!payload;
    },
    openOrderSummaryModal(state) {
      state.sideModalToShow = 'orderSummary';
    },
    closeModal(state) {
      state.sideModalToShow = '';
      state.cartSeedlingsExpanded = false;
    },
    clearDiscounts(state) {
      state.discounts = initialState.discounts;
    },
    // remove all items from cart and clear values
    clearCart(state, { payload }) {
      return { ...initialState, urlDiscount: payload?.urlDiscount };
    },
    setIsAltDuplicatedOrder(state, { payload }) {
      state.isAltDuplicatedOrder = payload;
      state.isLoadingDuplicateOrder = false;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(checkDuplicatedOrderAction.toString(), (state) => {
      state.isLoadingDuplicateOrder = true;
    });
    builder.addCase(submitAltPayment.toString(), (state) => {
      state.isCheckingOut = true;
    });
    builder.addMatcher(isAltPayStallAction, (state /*action*/) => {
      state.isCheckingOut = false;
    });
  },
});

const isAltPayStallAction = (action) => {
  return [cancelPayIntentPoll.toString(), submitAltPaymentFailed.toString()].includes(action.type);
};

// Extract the action creators object and the reducer
const { actions, reducer } = cartSlice;

// Extract and export action creators from slice by name
export const {
  addItem,
  addBulkItems,
  addBulkItemsAndDiscount,
  addBulkItemsAndDiscountArray,
  replaceSeedlings,
  removeItem,
  removeItemOfType,
  removeItemsOfCategories,
  updateItem,
  setDiscount,
  setUrlDiscount,
  removeDiscount,
  setCartValue,
  setCartOrder,
  setCartPreview,
  setCartPreviewError,
  setVerifiedAddress,
  setVerifiedAddressError,
  setShippingOptions,
  setLastAddedFarmstandId,
  setHasBERemovedSeedlings,
  clearDiscounts,
  clearCart,
  setLoadingCartPreview,
  loadCartPreview,
  openCartModal,
  openOrderSummaryModal,
  closeModal,
  toggleGiftCheckoutState,
  setGiftState,
  setGiftAmount,
  setGiftImage,
  setIsAltDuplicatedOrder,
} = actions;

export const doLogout = ({ auth }) => ({ type: 'LOGOUT', payload: { auth } });

export const giftValuesChanged = createAction('cart/GIFT_VALUES_CHANGED');

export const checkDuplicatedOrderAction = createAction('cart/CHECK_DUPLICATED_ORDER');

export const cancelPayIntentPoll = createAction('cart/CANCEL_PAY_INTENT_POLL');
export const submitAltPayment = createAction('cart/SUBMIT_ALT_PAY');
export const submitAltPaymentSucceeded = createAction('cart/SUBMIT_ALT_PAY_SUCCEEDED');
export const submitAltPaymentFailed = createAction('cart/SUBMIT_ALT_PAY_FAILED');

/**
 * Just for local use of setCartPreview action
 */
const getTotalItems = (items) => {
  return items?.reduce((acc, curr) => acc + curr.qty, 0);
};

// Minimum # of seedlings that need to be in cart to checkout
const MIN_NUM_SEEDLINGS = 1;

export const getCart = createSelector([(state) => state.cart], (cart) => cart);

export const getCartItems = createSelector(getCart, (cart) => cart.items);

export const getCartSideModalToShow = createSelector(getCart, (cart) => cart.sideModalToShow);

export const getCartHasBERemovedSeedlings = createSelector(getCart, (cart) => cart.hasBERemovedSeedlings);

export const getCartTotalCents = createSelector(getCart, (cart) => cart.totalCents);

export const getCartSubTotalCents = createSelector(getCart, (cart) => cart.subtotalCents);

export const getCartTaxCents = createSelector(getCart, (cart) => cart.taxCents);

export const getCartShippingCents = createSelector(getCart, (cart) => cart.shippingCents);

export const getCartGiftBalanceCents = createSelector(getCart, (cart) => cart.giftBalanceCents);

export const getCartGiftCents = createSelector(getCart, (cart) => cart.giftCents);

export const getCartCreditBalanceCents = createSelector(getCart, (cart) => cart.creditBalanceCents);

export const getCartCreditCents = createSelector(getCart, (cart) => cart.creditCents);

export const getCartPromoCents = createSelector(getCart, (cart) => cart.promoCents);

export const getCartShipDate = createSelector(getCart, (cart) => cart.shipDate);

export const getCartSeedlingsExpanded = createSelector(getCart, (cart) => cart.cartSeedlingsExpanded);

export const getCartDiscounts = createSelector(getCart, (cart) => cart.discounts);

export const getIsLoadingCartPreview = createSelector(getCart, (cart) => cart.isLoadingCartPreview);

export const getCartManualDiscounts = createSelector(getCart, (cart) => cart.manualDiscounts);

export const getMinGiftDate = createSelector(getCart, (cart) => cart.minGiftDate);

export const getTestGiftSendTime = createSelector(getCart, (cart) => cart.testGiftSendTime);

export const getIsGift = createSelector(getCart, (cart) => cart.isGift);

export const getCanUpdate = createSelector(getCart, (cart) => cart.canUpdate);

export const getIsCheckingOut = createSelector(getCart, (cart) => cart.isCheckingOut);

export const getPaymentType = createSelector(getCart, (cart) => cart.paymentType);

export const getTermsOfServiceVersion = createSelector(getCart, (cart) => cart.termsOfServiceVersion);

export const getCartPreviewError = createSelector(getCart, (cart) => cart.cartPreviewError);

export const getCartShippingMessages = createSelector(getCart, (cart) => cart.shippingMessages);

export const getSelectedShippingOptions = createSelector(getCart, (cart) => cart.selectedShippingOptions);

export const getVerifiedAddress = createSelector(getCart, (cart) => cart.verifiedAddress);

export const getUrlDiscount = createSelector(getCart, (cart) => cart.urlDiscount);

export const getForceCartPreviewFetchOnCheckout = createSelector(getCart, (cart) => cart.forceCartPreviewFetchOnCheckout);

export const getIsAltDuplicatedOrder = createSelector(getCart, ({ isAltDuplicatedOrder, isLoadingDuplicateOrder }) => ({
  isDuplicatedOrder: isAltDuplicatedOrder,
  isLoadingDuplicateOrder,
}));

export const getCartTotalCost = createSelector(
  [getCartSubTotalCents, getCartTaxCents, getCartShippingCents, getCartPromoCents, getCartGiftCents, getCartCreditCents],
  (subtotalCents, taxCents, shippingCents, promoCents, giftCents, creditCents) =>
    getTotalCost({
      subtotalCents,
      taxCents,
      shippingCents,
      promoCents,
      giftCents,
      creditCents,
    })
);

// Selector for getting number of seedlings in cart, counting also the seedlings in the bundle
export const getNumSeedlingsInCart = createSelector(getCartItems, (items) => {
  return items.reduce((acc, curr) => {
    if (curr.category === SEEDLINGS) {
      return acc + curr.qty;
    }
    if (curr.category === BUNDLES || curr.category === CUSTOM_BUNDLES) {
      return acc + curr.qty * curr.plantCount;
    }
    return acc;
  }, 0);
});

export const getOnlyGiftCardInCart = createSelector(getCartItems, (items) => items?.length === 1 && items[0].sku === GIFT_CARD.id);

export const getNotEnoughSeedlingsInCart = createSelector(getNumSeedlingsInCart, (numSeedlings) => {
  return numSeedlings > 0 && numSeedlings < MIN_NUM_SEEDLINGS;
});

// Selector for getting an item in cart
export const getItemInCart = createSelector([getCartItems, (_, sku) => sku], (items, sku) => {
  // If no items in cart, return null
  if (!items) return null;

  // iterate through the current items in the cart, searching for the sku we've been given
  return items.find((cartItem) => cartItem.sku === sku);
});

export const getPlantsInCart = createSelector(getCartItems, (items) => {
  // If no items in cart, return null
  if (!items) return null;

  // iterate through the current items in the cart, searching for all Seedlings and Bundles
  const plantsCategories = [SEEDLINGS, BUNDLES, CUSTOM_BUNDLES];
  return items.filter((cartItem) => plantsCategories.includes(cartItem.category));
});

export const getFarmstandsInCart = createSelector(getCartItems, (items) => {
  // If no items in cart, return null
  if (!items) return null;

  // iterate through the current items in the cart, searching for all Farmstands
  return items.filter((cartItem) => cartItem.category === FARMSTAND);
});

export const getSuppliesInCart = createSelector(getCartItems, (items) => {
  if (!items) return [];

  return items.filter((cartItem) => cartItem.category === SUPPLIES || cartItem.category === EXTENSION);
});

export const getLightsInCart = createSelector(getCartItems, (items) => {
  if (!items) return null;

  return items.filter((cartItem) => HALO_LIGHTS_SKUS.includes(cartItem.sku));
});

// Selector for checking if there is a gift card in the cart
export const getCartHasGiftCard = createSelector(getCartItems, (items) => {
  // If no items in cart, return null
  if (!items) return null;

  // iterate through the current items in the cart, searching for a giftcard
  return items.findIndex((cartItem) => cartItem.sku === GIFT_CARD.id) > -1;
});

// Selector for checking if there is a farmstand in the cart
export const getCartHasFarmstand = createSelector(getCartItems, (items) => {
  // If no items in cart, return null
  if (!items) return null;

  // iterate through the current items in the cart, searching for a farmstand
  return items.findIndex((cartItem) => cartItem.category === FARMSTAND) > -1;
});

// Selector to find out whether or not to show affirm
export const getShouldShowAffirm = createSelector(
  [getAffirmMin, getCartHasGiftCard, getCartTotalCost],
  (affirmMin, isGiftCardInCart, cartTotal) => {
    return cartTotal / 100 >= affirmMin && !isGiftCardInCart;
  }
);

export const getShippingOptions = createSelector(getCart, (cart) => cart.shippingOptions);

export const getShippingOptionsById = createSelector(getShippingOptions, (shippingOptions) =>
  Object.fromEntries(shippingOptions.map((option) => [option.category, option]))
);

export const getIsVerifyAddressReady = createSelector(getCart, (cart) => {
  return !(cart.hasCalledVerifiedAddress && !cart.verifiedAddress.success && !cart.shouldOverrideAddress);
});
/**
 * * verifyAddress - Async Action Creator to hit verify address BE point
 *
 * @param {object} values - address values, address, postStop, city, state, zip
 *
 */
export const verifyAddress = (values) => ({
  type: AXIOS,
  payload: {
    url: '/app/public/verifyShippingAddress',
    method: 'POST',
    withCredentials: true,
    data: values,
    onSuccess: setVerifiedAddress,
    onFailure: setVerifiedAddressError,
  },
});

/**
 * * cartPreview - Async Action Creator to hit cart preview BE point
 *
 * @param {object} values - address values, address, postStop, city, state, zip
 *
 */

export const cartPreview = (values, isLoggedIn) => {
  return {
    type: AXIOS,
    payload: {
      url: isLoggedIn ? '/app/lgcom/v2/cartPreview' : '/app/public/v2/cartPreview',
      method: 'POST',
      data: values,
      withCredentials: true,
      onSuccess: setCartPreview,
      onFailure: setCartPreviewError,
      label: 'cartPreview',
      setLoading: setLoadingCartPreview,
    },
  };
};

export const fetchShippingOptions = ({ zip, items, promoCode }) => {
  const url = `app/public/getShippingOptions`;
  const params = {
    address: { zipcode: zip },
    items,
    promoCode,
  };
  return {
    type: AXIOS,
    payload: {
      url,
      method: 'POST',
      data: params,
      withCredentials: true,
      onSuccess: setShippingOptions,
      label: 'shippingOptions',
    },
  };
};

// Export the reducer as the default
export default reducer;
