import { race, take, put, select, takeLatest, call } from 'redux-saga/effects';
import { navigate } from 'gatsby';
import { getAuth, createUserWithEmailAndPassword } from 'firebase/auth';

import { axiosPost, axiosGet } from 'utils/api-utils';
import { environment, authDomain } from 'utils/envConfig';
import { getStorage } from 'utils/storageManager';
import logError from 'utils/errorHandler';
import { trackEnhancedCheckout } from 'utils/googleTagManager';
import { postOrderCleanup, createStripeOrder } from 'app/pages/checkout/CheckoutHelpers';

import { resetReservation } from 'reduxState/reservation/reservation';
import {
  clearCart,
  setCartValue,
  getCart,
  checkDuplicatedOrderAction,
  setIsAltDuplicatedOrder,
  cancelPayIntentPoll,
  submitAltPaymentFailed,
  submitAltPaymentSucceeded,
  submitAltPayment,
} from 'reduxState/cart';
import {
  userLogout,
  setUserValue,
  getLoginState,
  getCAPIUserInfo,
  fetchMyFarm,
  fetchUserDevices,
  getCustomer,
  getUserAuthToken,
} from 'reduxState/user';
import { getCatalogEnvironment } from 'reduxState/catalog';
import paths from 'constants/paths';
import { shopCategories } from 'constants';
import { checkIsDuplicatedOrder } from 'utils/duplicate-order-util';

function* guestAuthToken({ payload }) {
  const auth = getAuth();
  const { values } = payload;
  try {
    const { SimpleCrypto } = yield import('simple-crypto-js');
    const key = SimpleCrypto?.generateRandom?.();
    const password = values.email + key;
    yield put(setUserValue({ label: 'password', value: password }));
    const guestSignupResponse = yield createUserWithEmailAndPassword(auth, values.email, password);
    const authToken = yield guestSignupResponse.user.getIdToken();
    return authToken;
  } catch (error) {
    yield put(submitAltPaymentFailed(error.message));
  }
}

function* checkAccount({ payload }) {
  const { email } = payload;
  try {
    const hasAccount = yield axiosGet(`/app/public/checkAccount/${email}`, {
      headers: { authDomain },
    });

    if (hasAccount && hasAccount.data) {
      yield put(setUserValue({ label: 'hasAccount', value: true }));
    }
  } catch (error) {
    logError(error);
  }
}

function* checkDuplicatedOrder() {
  const cart = yield select(getCart);
  const userAuthToken = yield select(getUserAuthToken);

  if (!userAuthToken) return;

  const isDuplicate = yield checkIsDuplicatedOrder({ userAuthToken, cart });
  yield put(setIsAltDuplicatedOrder(isDuplicate));
}

function* doLogout() {
  yield put(setCartValue({ label: 'canUpdate', value: true }));
  yield put(setCartValue({ label: 'paymentType', value: 'credit' }));
}

function updateDataLayer({ payload }) {
  const { values, cart } = payload;

  if (environment === 'production') {
    const lgUserId = getStorage('lgClientId');
    window.LogRocket && window.LogRocket.identify(lgUserId, { name: values.firstName + ' ' + values.lastName, email: values.email });
  }

  trackEnhancedCheckout({ actionField: { step: 2, option: cart.paymentType }, products: cart.items });
}

function* createPayIntent({ payload }) {
  const { amount, authToken, e } = payload;
  try {
    const payIntentForm = new FormData();
    payIntentForm.append('amount', amount);
    const configReq = { withCredentials: true, supressErrorCodeExpectation: true };
    const formUrlConfig = { ...configReq, headers: { 'Content-Type': 'application/x-www-form-urlencoded' } };
    const { data } = yield axiosPost('/app/lgcom/stripe/paymentIntent', payIntentForm, formUrlConfig, authToken);
    return { paymentIntentId: data.id, clientSecret: data.client_secret };
  } catch (error) {
    e.complete('fail');
    yield put(submitAltPaymentFailed(e.message));
    throw new Error(error.message);
  }
}

function* confirmPayment({ payload }) {
  const { clientSecret, e, stripe } = payload;
  try {
    const { /*paymentIntent,*/ error } = yield stripe.confirmCardPayment(
      clientSecret,
      { payment_method: e.paymentMethod.id },
      { handleActions: false }
    );

    if (error) {
      throw new Error(error.message);
    }
  } catch (error) {
    e.complete('fail');
    yield put(submitAltPaymentFailed(e.message));
    throw new Error(e.message);
  }
}

function* checkPayIntentStatus({ payload }) {
  const { paymentIntentId, e, authToken } = payload;
  try {
    const { data } = yield axiosGet(`/app/lgcom/stripe/paymentIntent/${paymentIntentId}`, {}, authToken);
    const isCanceled = ['canceled'].includes(data.status);
    const isSuccessful = ['requires_capture', 'succeeded'].includes(data.status);
    if (isSuccessful) {
      e.complete('success');
      yield put(submitAltPaymentSucceeded());
    } else if (isCanceled) {
      e.complete('fail');
      yield put(cancelPayIntentPoll());
    } else {
      // keep going recursively
      yield call(checkPayIntentStatus, { payload });
    }
  } catch (error) {
    e.complete('fail');
    yield put(submitAltPaymentFailed(e.message));
    throw new Error(error.message);
  }
}

function* createOrder({ payload }) {
  const { values, cart, user, authToken, e } = payload;
  const catalogEnvironment = yield select(getCatalogEnvironment);
  const reservation = yield select((state) => state.reservation);
  const order = createStripeOrder(values, cart.shouldOverrideAddress, null, cart, user, reservation, catalogEnvironment);
  const type = e.methodName;
  const { exp_month, exp_year, last4, brand } = e.paymentMethod.card;
  const response = yield axiosPost(
    '/app/lgcom/cartCheckoutStripe',
    { ...order, exp_month, exp_year, last4, brand, type },
    { withCredentials: true, supressErrorCodeExpectation: true },
    authToken
  );
  return response.data;
}

function* submitAltPaymentFlow({ payload }) {
  const { e, stripe, values, altPayType } = payload;
  const isLoggedIn = yield select(getLoginState);
  const capiData = yield select(getCAPIUserInfo);
  const customer = yield select(getCustomer);
  const cart = yield select((state) => state.cart);
  const user = yield select((state) => state.user);
  let authToken;

  try {
    if (user.authToken) {
      authToken = user.authToken;
    } else {
      authToken = yield call(guestAuthToken, { payload: { values } });
    }
    yield call(updateDataLayer, { payload: { values, cart } });

    const amount = cart.totalCents / 100;
    const { paymentIntentId, clientSecret } = yield call(createPayIntent, { payload: { amount, authToken, e } });
    yield call(confirmPayment, { payload: { clientSecret, e, stripe } });
    const { resolved } = yield race({
      paymentIntent: call(checkPayIntentStatus, { payload: { paymentIntentId, authToken, e } }),
      resolved: take([cancelPayIntentPoll.type, submitAltPaymentSucceeded.type]),
    });
    if (resolved?.type === submitAltPaymentSucceeded.type) {
      const order = yield call(createOrder, { payload: { e, cart, user, values, authToken } });
      navigate(paths.ORDER_CONFIRMATION + `/${order.orderNumber}`);
      const urlDiscount = cart.urlDiscount;
      const shouldClearUrlDiscount = urlDiscount && Object.keys(order.discounts)?.includes?.(urlDiscount);
      postOrderCleanup({ order: { ...order, eventPayMethod: altPayType }, cart, isLoggedIn, capiData, customer });
      yield put(clearCart({ urlDiscount: shouldClearUrlDiscount ? null : urlDiscount }));
      yield put(resetReservation());
      if (isLoggedIn) {
        yield put(fetchMyFarm());
        const hasFarmstandItems = !!order.items?.filter?.((item) => item.category === shopCategories.FARMSTAND)?.length;
        if (hasFarmstandItems) yield put(fetchUserDevices());
      }
    }
  } catch (error) {
    logError(error);
    window.dataLayer?.push({
      event: 'formSubmitFailure',
      formId: 'checkout',
    });
  }
}

function* submitAltPaymentSaga() {
  yield takeLatest(submitAltPayment.toString(), submitAltPaymentFlow);
}

function* guestAuthTokenSaga() {
  yield takeLatest('CHECKOUT_AUTH_TOKEN', guestAuthToken);
}

function* logoutSaga() {
  yield takeLatest(userLogout.toString(), doLogout);
}

function* checkAccountSaga() {
  yield takeLatest('CHECK_ACCOUNT', checkAccount);
}

function* checkDuplicatedOrderSaga() {
  yield takeLatest(checkDuplicatedOrderAction.toString(), checkDuplicatedOrder);
}

export default [submitAltPaymentSaga, guestAuthTokenSaga, logoutSaga, checkAccountSaga, checkDuplicatedOrderSaga];
