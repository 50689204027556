/**
 * The Order Summary for Lettuce Grow
 *
 * A similar component to the Cart, but with just a difference to warrant a different component.
 * On mobile devices, this component will be seen via a modal upon clicking a banner at the top of the screen.
 * On desktop, this component will be seen in the body of the checkout page
 *
 * Each item is represented with it's own line item (no containerization in v1)
 * A 'keep shopping' link is present at the bottom of this component
 */

import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import _partition from 'lodash/partition';

import { closeModal } from 'reduxState/cart';
import LineItem from './LineItem';
import OrderSummaryFooter from './OrderSummaryFooter';
import EmptyOrderMessage from './EmptyOrderMessage';
import { Text } from 'elements';
import { OrderSummaryContainer, CheckoutHeader, Header, ItemsContainer } from './OrderSummary.styled';

import { getTotalCost, formatPrice } from 'utils/cart-utils';
import paths from 'constants/paths';

const OrderSummary = ({ isOpen, isCheckout, completedCart }) => {
  const reduxCart = useSelector((state) => state.cart);
  const dispatch = useDispatch();
  // Completed cart is for completed orders to show on the order confirmation page
  const cart = completedCart ? completedCart : reduxCart;
  const [seedlingItems, nonSeedlingItems] = useMemo(() => _partition(cart.items, (item) => item.category === 'Seedlings'), [cart.items]);

  const lineItems = nonSeedlingItems.map((item, index) => <LineItem key={`line-item-${index}`} item={item} />);
  const seedlingLineItems = seedlingItems.map((item, index) => <LineItem key={`line-item-${index}`} item={item} />);
  const seedlingItemsQty = seedlingItems.reduce((total, seedling) => {
    total += parseInt(seedling.qty);
    return total;
  }, 0);
  const seedlingItemsPrice = seedlingItems.reduce((total, seedling) => {
    total += parseInt(seedling.priceCents) * seedling.qty;
    return total;
  }, 0);

  const giftsWithPurchase = cart.giftsWithPurchase?.map((item) => {
    return <LineItem key={`free-gift-${item.sku}`} item={item} />;
  });

  const handleClose = () => {
    dispatch(closeModal());
  };
  return (
    <OrderSummaryContainer isOpen={isOpen} isCheckout={isCheckout}>
      {isCheckout && !completedCart ? (
        <CheckoutHeader>
          <Text modifiers='brandSmallWhite' content='SUMMARY'></Text>{' '}
          <Text as='span' modifiers={['brandSmall', 'inverted']} content={formatPrice(getTotalCost(cart) / 100)}></Text>
        </CheckoutHeader>
      ) : (
        <Header>
          {!completedCart && <button onClick={handleClose}>&#x2715;</button>}
          <Text modifiers={[completedCart && 'small', 'brandFont']}>ORDER SUMMARY</Text>
        </Header>
      )}
      {cart.items.length > 0 && (
        <>
          <ItemsContainer isCheckout={isCheckout}>
            {lineItems}
            {giftsWithPurchase}
            {!!seedlingItems.length && (
              <LineItem
                key='seedlings'
                item={{
                  name: 'Seedlings',
                  qty: seedlingItemsQty,
                }}
                priceCentsOverride={seedlingItemsPrice}
                slugOverride={paths.SEEDLINGS}
              />
            )}
            {seedlingLineItems}
          </ItemsContainer>
          <OrderSummaryFooter isCheckout={isCheckout} cart={cart} completedCart={completedCart} closeModal={handleClose} />
        </>
      )}
      {isOpen && cart.items.length <= 0 && <EmptyOrderMessage />}
    </OrderSummaryContainer>
  );
};

OrderSummary.propTypes = {
  isOpen: PropTypes.bool,
  isCheckout: PropTypes.bool,
  completedCart: PropTypes.object,
};

export default OrderSummary;
