export const deliveryStatus = {
  HANDDELIVERY: 'HANDDELIVERY',
  DELIVERED: 'DELIVERED',
  PENDING: 'PENDING',
  PICKED_UP: 'SHIPPED',
};

export const deliveryTypes = {
  FEDEX: {
    url: `https://www.fedex.com/apps/fedextrack/?action=track&trackingnumber=`,
    regex: /\b([0-9]{12}|100\d{31}|\d{15}|\d{18}|96\d{20}|96\d{32})\b/,
  },
  UPS: {
    url: `https://tools.usps.com/go/TrackConfirmAction.action?tLabels=`,
    regex: /\b1Z[A-Z0-9]{16}\b/,
  },
};
