import { put, select, takeLatest } from 'redux-saga/effects';

import {
  userLogout,
  userAuthenticated,
  getUser,
  getCustomer,
  userCreateAccount,
  userLogin,
  userCreateFarmDevice,
  userUpdateFarmDevice,
  userDeleteFarmDevice,
  fetchUserDevices,
} from 'reduxState/user';
import { clearDiscounts } from 'reduxState/cart';
import { identify } from 'utils/klaviyo-utils';
import { axiosDelete, axiosGet, axiosPost } from 'utils/api-utils';
import logError from 'utils/errorHandler';

function* doUserAuthenticated() {
  const user = yield select(getUser);
  const customer = yield select(getCustomer);
  identify({ user, customer });
}

function* doUserLogout() {
  yield put(clearDiscounts());
}

function* doUserCreateOrUpdateFarmstandDevice({ payload: { id, deviceData, authToken, setIsFetching, onSubmit } }) {
  try {
    const headers = { 'Content-Type': 'multipart/form-data' };
    const isEdit = !!id;
    const endpoint = `/app/lgcom/deviceSetup${isEdit ? `/${id}` : ''}`;
    yield axiosPost(endpoint, deviceData, { headers }, authToken);
    yield put(fetchUserDevices());
    onSubmit?.();
  } catch (error) {
    logError(error);
  } finally {
    setIsFetching(false);
  }
}

function* doUserRemoveFarmstandDevice({ payload: { id, authToken, setIsFetching, onSubmit } }) {
  try {
    yield axiosDelete(`/app/lgcom/deviceSetup/${id}`, {}, authToken);
    yield put(fetchUserDevices());
    onSubmit?.();
  } catch (error) {
    logError(error);
  } finally {
    setIsFetching(false);
  }
}

function* userAuthenticateSaga() {
  yield takeLatest(userAuthenticated.toString(), doUserAuthenticated);
}

function* userLogoutSaga() {
  yield takeLatest(userLogout.toString(), doUserLogout);
}

function* doUserCreateAccount({ payload: { auth, email, password, accountData, setIsFetching } }) {
  try {
    const firebaseAccountResponse = yield auth.signUp(email, password);
    if (firebaseAccountResponse?.getIdToken) {
      const authToken = yield firebaseAccountResponse.getIdToken();
      const userId = (yield axiosPost('/app/public/customer', accountData, {}, authToken))?.data?.id;
      if (userId) {
        yield axiosGet('/app/lgcom/unguest', {}, authToken);
        yield put(userLogin());
      }
    }
  } catch (error) {
    logError(error);
  } finally {
    setIsFetching(false);
  }
}

function* userCreateAccountSaga() {
  yield takeLatest(userCreateAccount.toString(), doUserCreateAccount);
}

function* userCreateFarmstandDeviceSaga() {
  yield takeLatest(userCreateFarmDevice.toString(), doUserCreateOrUpdateFarmstandDevice);
}

function* userUpdateFarmstandDeviceSaga() {
  yield takeLatest(userUpdateFarmDevice.toString(), doUserCreateOrUpdateFarmstandDevice);
}

function* userDeleteFarmstandDeviceSaga() {
  yield takeLatest(userDeleteFarmDevice.toString(), doUserRemoveFarmstandDevice);
}

export default [
  userLogoutSaga,
  userAuthenticateSaga,
  userCreateAccountSaga,
  userCreateFarmstandDeviceSaga,
  userUpdateFarmstandDeviceSaga,
  userDeleteFarmstandDeviceSaga,
];
