import { setStorage, removeStorage, getStorage } from 'utils/storageManager';
import storageKeys from 'constants/storageKeys';

import {
  addItem,
  addBulkItems,
  addBulkItemsAndDiscount,
  addBulkItemsAndDiscountArray,
  replaceSeedlings,
  removeItem,
  removeItemOfType,
  removeItemsOfCategories,
  updateItem,
  setDiscount,
  removeDiscount,
  clearCart,
  setCartPreview,
  clearDiscounts,
  setLastAddedFarmstandId,
  toggleGiftCheckoutState,
  setGiftState,
  giftValuesChanged,
} from 'reduxState/cart';
import { userLogin, setUserValue, setUserZip, setUserFromCheckout, setMyFarm, userLogout } from 'reduxState/user';
import { getLoginState } from 'reduxState/user';
import { setReservation, resetReservation } from 'reduxState/reservation/reservation';
import { MODALS, setOpenModal } from 'reduxState/modal';

/**
 * * Redux Middleware for Saving to local storage
 *
 */

// list of actions to save cart
const CART_ACTIONS = [
  addItem.toString(),
  addBulkItems.toString(),
  addBulkItemsAndDiscount.toString(),
  addBulkItemsAndDiscountArray.toString(),
  replaceSeedlings.toString(),
  removeItem.toString(),
  removeItemOfType.toString(),
  removeItemsOfCategories.toString(),
  updateItem.toString(),
  setDiscount.toString(),
  removeDiscount.toString(),
  setCartPreview.toString(),
  setLastAddedFarmstandId.toString(),
  toggleGiftCheckoutState.toString(),
  setGiftState.toString(),
];

const cartMiddleware = ({ getState }) => (next) => (action) => {
  next(action);

  // Set cart in local storage for cart actions
  if (CART_ACTIONS.includes(action.type)) {
    const cart = getState().cart;
    const currentCart = getStorage(storageKeys.CART);
    setStorage(storageKeys.CART, {
      ...currentCart,
      items: cart.items.filter((item) => item?.sku && item?.qty),
      giftCard: cart.giftCard,
      discounts: cart.discounts,
      urlDiscount: cart.urlDiscount,
      manualDiscounts: cart.manualDiscounts,
      lastAddedFarmstandId: cart.lastAddedFarmstandId,
      isGift: cart.isGift,
    });
    return;
  }

  if (action.type === giftValuesChanged.toString()) {
    const { toEmail, toName, fromName, message, notifyGiftReceiver, sendOn } = action.payload;
    const currentCart = getStorage(storageKeys.CART);
    setStorage(storageKeys.CART, { ...currentCart, toEmail, toName, fromName, message, notifyGiftReceiver, sendOn });
  }

  // Delete cart from local storage if action is clearCart
  if (action.type === clearCart.toString()) {
    removeStorage(storageKeys.CART);
    return;
  }

  // Remove cart Discounts on userLogout action
  if (action.type === userLogout.toString() || action.type === clearDiscounts.toString()) {
    const currentCart = getStorage(storageKeys.CART);
    setStorage(storageKeys.CART, { ...currentCart, discounts: {}, manualDiscounts: [] });
    return;
  }

  // Set user in local storage for user actions
  if (
    action.type === setUserValue.toString() ||
    action.type === setUserFromCheckout.toString() ||
    action.type === setMyFarm.toString() ||
    action.type === userLogin.toString() ||
    action.type === userLogout.toString() ||
    (action.type === setUserZip.toString() && !getLoginState(getState()))
  ) {
    const currUser = getState().user;
    // Not saving auth token
    setStorage(storageKeys.USER, {
      email: currUser.email,
      password: currUser.password, // temp guest pass
      firstName: currUser.firstName,
      lastName: currUser.lastName,
      address: currUser.address,
      postStop: currUser.postStop,
      city: currUser.city,
      state: currUser.state,
      zip: currUser.zip,
      phone: currUser.phone,
      isGuest: currUser.isGuest,
    });
    return;
  }

  if (action.type === setReservation.toString() || action.type === resetReservation.toString()) {
    // always set hasExpired to false (reset) so that a fresh page load won't show user error message
    setStorage('lettucegrow-reservation-428', { ...getState().reservation, hasExpired: false });
    return;
  }

  if (action.type === setOpenModal.toString() && action.payload === MODALS.INTERNATIONAL) {
    setStorage('lettucegrow-int-email-618', true);
    return;
  }
};

export default cartMiddleware;
