import styled from 'styled-components';

export const ReviewFooterStyled = styled.div.attrs({
  className: 'ReviewFooterStyled',
})`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  width: 100%;

  > .Text {
    margin-right: 1rem;
    pointer-events: none;
  }

  .VoteButton + .VoteButton {
    margin-right: 0;
  }
`;

export const VoteButton = styled.button.attrs({
  className: 'VoteButton',
})`
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  margin: 0 0.5rem;

  .Icon {
    width: 1rem;
    height: 1rem;
    margin: 0 0.45rem;
    pointer-events: none;
    svg {
      position: initial;
      transform: unset;
    }
    path {
      stroke-width: ${({ isInverted }) => (isInverted ? '8px' : 0)};
    }
  }
`;
