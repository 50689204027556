import React from 'react';
import { useLocation } from '@reach/router';
import { useSelector, useDispatch } from 'react-redux';
import { navigate } from 'gatsby';

import { getOpenModal, setCloseModal } from 'reduxState/modal';
import { Modal } from '../index';
import LoginForm from 'components/loginForm/LoginForm';
import ResetPasswordModal from 'components/modal/reset-password/ResetPassword';

import { LoginModalStyled } from './LoginModal.styled';

const LoginModal = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const openModal = useSelector(getOpenModal);

  const closeModal = () => {
    dispatch(setCloseModal());
    location?.state?.passwordResetRetry && navigate(location.pathname); //clear location state
  };

  return (
    <LoginModalStyled>
      <Modal isOpen={openModal.id === 'login'} onCloseAction={closeModal} dataNw='login-modal'>
        <LoginForm onSubmit={closeModal} isOnModal />
      </Modal>
      <ResetPasswordModal isOpen={openModal.id === 'passwordReset'} onCloseAction={closeModal} />
    </LoginModalStyled>
  );
};

export default LoginModal;
