import React, { useReducer } from 'react';
import PropTypes from 'prop-types';

const SET_SELECTED_BUNDLE_SIZE = 'SET_SELECTED_BUNDLE_SIZE';

export const SEEDLINGS_BUNDLE_SIZE = 6;

const initialState = { selectedBundleSize: null, numberOfSeedBundles: null };

export const setSelectedBundleSize = (payload) => ({
  type: SET_SELECTED_BUNDLE_SIZE,
  payload,
});

const bundleReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_SELECTED_BUNDLE_SIZE:
      return { ...state, selectedBundleSize: action.payload, numberOfSeedBundles: action.payload / SEEDLINGS_BUNDLE_SIZE };
    default:
      return state;
  }
};

export const BundleContext = React.createContext();

const BundleContextProvider = ({ children }) => {
  const [state, dispatch] = useReducer(bundleReducer, initialState);

  return <BundleContext.Provider value={{ state, dispatch }}>{children}</BundleContext.Provider>;
};

BundleContextProvider.propTypes = {
  children: PropTypes.node,
};

export default BundleContextProvider;
