import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { useLocation } from '@reach/router';

import { Title, Text, SquaredButton } from 'elements';
import { Media, Flex } from 'components';
import ConfigurableSectionStyled from './ConfigurableSection.styled';

import scrollIntoView from 'utils/scrollIntoView';
import { parseRichText } from 'utils/contentful-utils';

export const LAYOUT_OPTIONS = {
  TEXT_LEFT_IMAGE_RIGHT: 'text left, image right',
  TEXT_RIGHT_IMAGE_LEFT: 'text right, image left',
  TEXT_TOP_IMAGE_BOTTOM: 'text top, image bottom',
  TEXT_BOTTOM_IMAGE_TOP: 'text bottom, image top',
};

const ConfigurableSection = ({
  title,
  subTitle,
  body,
  callToActions,
  layout,
  titleTextColor,
  bodyTextColor,
  sectionId,
  image,
  backgroundImage,
  backgroundColor,
  customCSS,
}) => {
  const location = useLocation();
  const containerRef = useCallback(
    (node) => {
      if (!!node && location) {
        scrollIntoView(location, node, -100);
      }
    },
    [location]
  );

  const wrapperModifiers =
    layout === LAYOUT_OPTIONS.TEXT_LEFT_IMAGE_RIGHT
      ? 'alignCenter'
      : layout === LAYOUT_OPTIONS.TEXT_RIGHT_IMAGE_LEFT
      ? ['rowReverse', 'alignCenter']
      : layout === LAYOUT_OPTIONS.TEXT_TOP_IMAGE_BOTTOM
      ? ['justifyCenter', 'directionColumn', 'alignCenter']
      : layout === LAYOUT_OPTIONS.TEXT_BOTTOM_IMAGE_TOP && ['justifyCenter', 'directionColumnReverse'];
  return (
    <ConfigurableSectionStyled
      className='ConfigurableSection'
      ref={containerRef}
      id={sectionId}
      titleTextColor={titleTextColor}
      bodyTextColor={bodyTextColor}
      backgroundColor={backgroundColor}
      customCSS={customCSS}
    >
      {!!backgroundImage && <Media asset={backgroundImage.fields} className='BackgroundImage' />}
      <Flex modifiers={wrapperModifiers}>
        <div className='ConfigurableSectionCopy'>
          {!!title && <Title content={title} isHTML />}
          {!!subTitle && <Text content={subTitle} isHTML />}
          {!!body?.data && parseRichText(body)}
          {callToActions?.length && (
            <Flex>
              {callToActions.map((cta) => {
                return <SquaredButton key={cta.fields?.linkText} label={cta.fields?.linkText} href={cta.fields?.linkUrl} />;
              })}
            </Flex>
          )}
        </div>
        {!!image && <Media asset={image.fields} />}
      </Flex>
    </ConfigurableSectionStyled>
  );
};

ConfigurableSection.propTypes = {
  title: PropTypes.string,
  subTitle: PropTypes.string,
  body: PropTypes.string,
  callToActions: PropTypes.arrayOf(
    PropTypes.shape({
      fields: PropTypes.object,
    })
  ),
  sectionId: PropTypes.string,
  layout: PropTypes.oneOf(Object.values(LAYOUT_OPTIONS)),
  titleTextColor: PropTypes.string,
  bodyTextColor: PropTypes.string,
  image: PropTypes.object,
  backgroundImage: PropTypes.object,
  backgroundColor: PropTypes.string,
  customCSS: PropTypes.string,
};

ConfigurableSection.defaultProps = {
  title: '',
  subTitle: '',
  body: '',
  callToActions: [],
  sectionId: '',
  layout: LAYOUT_OPTIONS.TEXT_LEFT_IMAGE_RIGHT,
  titleTextColor: '',
  bodyTextColor: '',
  image: null,
  backgroundImage: null,
  backgroundColor: '',
  customCSS: '',
};

export default ConfigurableSection;
