import styled from 'styled-components';
import breakPoints from 'theme/Media';
import { applyStyleModifiers } from 'styled-components-modifiers';

export const modifiers = {
  selectedBorder: ({ theme }) => ({
    styles: `
      .Image {
        border: 0.4px solid ${theme.farmProject.dark_gray};
      }
    `,
  }),
  overlay: () => ({
    styles: `
      .Image {
        filter: brightness(0.5);
      }
    `,
  }),
};

export const ProductHeroSliderStyled = styled.div.attrs({
  className: 'ProductHeroSliderStyled',
})`
  .PillLabelStyled {
    top: 1.025rem;
    left: 0.8rem;
    position: absolute;
    z-index: 10;
  }

  @media ${breakPoints.large_break} {
    width: 60%;
    max-width: 32rem;
  }

  @media ${breakPoints.xLarge_break} {
    max-width: 35rem;
  }

  @media ${breakPoints.xxLarge_break} {
    width: 100%;
    max-width: 36rem;
  }
`;

export const HeroSliderInner = styled.div.attrs({
  className: 'HeroSliderInner',
})`
  background-color: ${({ theme }) => theme.farmProject.gray_3};

  @media ${breakPoints.large_break} {
    height: 27rem;
  }
  @media ${breakPoints.xxLarge_break} {
    height: 35rem;
  }
`;

export const ProductSlideStyled = styled.div.attrs({
  className: 'ProductSlideStyled',
})`
  height: 27rem;

  > div {
    height: 100%;
  }

  .Image {
    height: 100%;

    picture {
      img {
        height: 100%;
        width: auto;
      }
    }
  }
  .Video {
    width: 100%;
    max-height: 100%;
  }

  @media ${breakPoints.xxLarge_break} {
    height: 35rem;

    .Image {
      picture {
        img {
          height: auto;
          width: 105%;
        }
      }
    }
  }
`;

export const HeroSliderSecondary = styled.div.attrs({
  className: 'HeroSliderSecondary',
})`
  height: 5rem;
  max-width: 37rem;
  margin: auto;
  margin-top: 1rem;

  .Image {
    width: 92%;
  }

  .swiper-container {
    .swiper-button-prev {
      position: absolute;
      top: 50%;
      left: 4px;
      width: 1.8rem;
      height: 1.8rem;
      z-index: 5;
      line-height: normal;

      &::after {
        width: 2rem;
        height: 2rem;
        font-size: 1.75rem;
        color: ${({ theme }) => theme.farmProject.dark_gray};
      }
    }

    .swiper-button-next {
      position: absolute;
      top: 50%;
      right: 0;
      width: 1.8rem;
      height: 1.8rem;
      z-index: 5;
      line-height: normal;

      &::after {
        width: 2rem;
        height: 2rem;
        font-size: 1.75rem;
        color: ${({ theme }) => theme.farmProject.dark_gray};
      }
    }

    .swiper-wrapper {
      height: 6rem;
    }
  }
`;

export const ProductSlideThumb = styled.div.attrs({
  className: 'ProductSlideThumb',
})`
  height: 5rem;
  cursor: pointer;
  position: relative;

  .Icon {
    cursor: pointer;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-60%, -50%);
  }

  .Image {
    height: 100%;

    picture {
      img {
        height: 120%;
        width: auto;
      }
    }
  }

  ${applyStyleModifiers(modifiers)};
`;
