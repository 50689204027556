import React, { useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';

import { getShippingOptions, fetchShippingOptions } from 'reduxState/cart';
import { getUserZip } from 'reduxState/user';
import { SuggestionStyled, SuggestionInner } from '../suggestion/Suggestion.styled';

import { Text } from 'elements';

import shippingOptionCategories from 'constants/shippingOptionCategories';

const ShippingUpsell = ({ shippingCategory }) => {
  const dispatch = useDispatch();
  const cart = useSelector((state) => state.cart);
  const shippingOptions = useSelector(getShippingOptions);
  const userZip = useSelector(getUserZip);
  const hasMounted = useRef(false);

  useEffect(() => {
    if (hasMounted.current) {
      // HEADS UP - add default zip code here b/c this data is just used currently for seedling shipping messaging
      dispatch(fetchShippingOptions({ items: cart.items, zip: userZip || '73301' }));
    } else {
      hasMounted.current = true;
    }
  }, [cart.items, userZip, dispatch]);

  const seedlingShippingMessages = shippingOptions
    ?.find((opt) => opt.category === shippingCategory)
    ?.options?.find((opt) => opt.id === 'STANDARD')?.messages;

  if (!seedlingShippingMessages?.length) return null;

  return (
    <SuggestionStyled>
      <SuggestionInner>{<Text isHTML content={seedlingShippingMessages?.join(' ')} />}</SuggestionInner>
    </SuggestionStyled>
  );
};

ShippingUpsell.propTypes = {
  shippingCategory: PropTypes.oneOf(Object.values(shippingOptionCategories)),
};

ShippingUpsell.defaultProps = {
  shippingCategory: shippingOptionCategories.PLANT,
};

export default ShippingUpsell;
