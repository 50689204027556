import styled, { css } from 'styled-components/macro';
import { applyStyleModifiers } from 'styled-components-modifiers';

import breakpoints from 'theme/Media';

const modifiers = {
  fixedTop: () => ({
    styles: `
      top: 0px;
    `,
  }),
};

export const NavButtonsWrappers = styled.div.attrs({
  className: 'NavButtonsWrappers',
})`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 2.775rem;

  .Icon {
    svg {
      height: 80%;
    }
    :first-of-type svg {
      left: 20%;
    }
    :last-of-type svg {
      left: 80%;
    }
  }
`;

export default styled.nav.attrs({ className: 'Navigation' })`
  font-family: ${({ theme }) => theme.farmProject.font_family};
  font-size: 0.75rem;
  background-color: ${({ theme }) => theme.farmProject.gray_2};
  border-bottom: 1px solid ${({ theme }) => theme.farmProject.gray_1};
  height: ${({ theme }) => theme.layout.navHeight};
  padding: 0 1.4em;
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: fixed;
  transition: top 0.3s;
  top: 30px;
  left: 0;
  right: 0;
  z-index: 30;

  .Image {
    width: 160px;
    height: 20px;
  }

  .ButtonText span {
    font-size: 0.75rem;
  }

  & ~ .Search {
    display: none;
  }

  // search icon
  > .Icon {
    display: none;
    margin-left: 1rem;
    width: 1rem;
    height: 1rem;
    overflow: visible;
    svg {
      stroke-width: 0.5;
      stroke: ${({ theme }) => theme.farmProject.dark_gray};
    }

    @media ${breakpoints.large_break} {
      display: inline-block;
    }

  }

  .NavigationAccount {
    display: none;
    cursor: pointer;
    align-items: center;
    .Icon {
      width: 0.8rem;
      height: 1rem;
      svg {
        height: 90%;
        stroke: ${(props) => props.theme.farmProject.dark_gray};
      }
    }
    .Icon + .Icon {
      margin-left: 0.5rem;
      width: 0.5rem;
    }
  }

  // for nav without main menu - push account icon to flex end
  ${(props) =>
    props.isBasic &&
    css`
      & > a:first-of-type {
        flex: 1;
      }
    `}


  @media ${breakpoints.medium_break} {
    a,
    p {
      font-size: 1.25rem;
    }

    .ButtonText span {
      font-size: 1.25rem;
    }
  }

  @media ${breakpoints.large_break} {
    .NavigationAccount {
      display: flex;
    }
    a,
    p {
      font-size: 0.75rem;
    }

    .ButtonText span {
      font-size: 0.75rem;
    }
    padding: 0 2em;
    & ~ .Search {
      display: flex;
    }
  }
  ${applyStyleModifiers(modifiers)}
`;

export const Spacer = styled.div.attrs({ className: 'NavigationSpacer' })`
  height: ${({ theme }) => theme.layout.navHeight};
  font-size: 0.65rem;
  background-color: ${({ theme }) => theme.farmProject.gray_2};
`;

export const CartWrapper = styled.button.attrs({ className: 'CartWrapper' })`
  margin: 0.5rem 0;
  width: 2.3rem;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  cursor: pointer;
  .cart-icon__qty {
    font-size: 0.6rem;
    color: ${(props) => props.theme.farmProject.dark_gray};
  }
  .Icon {
    margin-right: 0.25rem;
    width: 1.35rem;
  }

  svg {
    stroke: ${({ theme }) => theme.farmProject.magenta};
    width: 99%;
    path {
      fill: ${({ theme }) => theme.farmProject.magenta};
    }
  }

  @media ${breakpoints.large_break} {
    margin-left: 1rem;

    .Icon {
      width: 1.25rem;
      height: 1.25rem;
    }
  }
`;

export const List = styled.ul.attrs({ className: 'NavList' })`
  display: flex;
  background: ${(props) => props.theme.farmProject.gray_1};
  padding: 1.6rem 1rem;
  position: fixed;
  left: 0;
  right: 0;
  top: ${({ theme, isPageScrolled }) => (isPageScrolled ? theme.layout.navHeight : theme.layout.navAccessibilityHeight)};
  bottom: 0;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  margin: 0;
  transform: ${(props) => (props.isOpen ? 'translateX(0)' : 'translateX(-100%)')};
  overflow-y: auto;
  overflow-x: hidden;

  .Button {
    min-width: 5rem;
    padding: 0.125rem 0;
    min-width: 6rem;
    height: 1.4rem;
  }

  hr {
    height: 1px;
    background: ${({ theme }) => theme.farmProject.dark_gray};
    width: 100%;
    outline: none;
    margin: 1em 0 1.7rem;
    border: none;
  }

  .SubMenu {
    display: block;
  }
  .Search {
    display: flex;
  }

  @media ${breakpoints.large_break} {
    transform: translateX(0);
    background: none;
    position: initial;
    flex-direction: row;
    flex: 2;
    justify-content: center;
    align-items: center;
    top: auto;
    position: relative;
    overflow: visible;

    .SubMenu,
    .Search {
      display: none;
    }
    .Button {
      order: 1;
    }

    hr {
      display: none;
    }
  }
`;

export const ListItem = styled.li.attrs({ className: 'ListItem' })`
  color: ${(props) => (props.isActive ? props.theme.farmProject.brand_primary : props.theme.farmProject.dark_gray)};
  font-size: 0.875rem;
  margin: 1.6rem 0;

  &:hover {
    color: ${({ theme }) => theme.farmProject.brand_primary};
  }

  @media ${breakpoints.medium_break} {
    font-size: 1rem;
  }
  @media ${breakpoints.large_break} {
    font-size: 0.75rem;
    margin: 0 0.7rem;
  }
  @media ${breakpoints.xLarge_break} {
    margin: 0 1.2rem;
  }
`;

export const SandwichWrapper = styled.div.attrs({ className: 'NavSandwichWrapper' })`
  display: block;
  .Icon svg {
    height: 90%;
  }
  @media ${breakpoints.large_break} {
    display: none;
  }
`;

export const SubMenu = styled.div.attrs({ className: 'SubMenu' })`
  width: 100%;
  li {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 0.5rem 0;
    &:first-of-type {
      margin: 2em 0;
    }
    a {
      width: 100%;
      color: ${({ theme }) => theme.farmProject.dark_gray};
    }

    a.ButtonText {
      justify-content: flex-start;
    }
  }
  .ButtonText {
    padding: 0;
  }

  .Text {
    cursor: auto;
  }

  .ButtonText {
    span {
      letter-spacing: normal;
    }
  }

  .Icon {
    svg {
      height: 100%;
      stroke: ${(props) => props.theme.farmProject.dark_gray};
    }
  }

  @media ${breakpoints.large_break} {
    width: unset;
    ul {
      position: absolute;
      background: ${({ theme }) => theme.farmProject.gray_2};
      padding: 1em;
      top: 100%;
      margin: 0;
      width: 10rem;
      right: 1rem;
      text-align: left;
    }
    li {
      &:first-of-type {
        margin: 0.5rem 0;
      }
    }
    .Icon {
      display: none;
    }
  }
`;

export const IconWrapper = styled.div.attrs({
  className: 'IconWrapper',
})`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  cursor: pointer;

  .Icon {
    cursor: pointer;
  }
`;
