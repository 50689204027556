import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import _partition from 'lodash/partition';
import { graphql, StaticQuery } from 'gatsby';

import { SlimBanner, Swiper } from 'components';
import { Text, Link, Icon } from 'elements';
import MessageBannerStyled from './MessageBanner.styled';
import InfoIcon from 'elements/icon/assets/information-icon.svg';

import paths from 'constants/paths';
import { useBreakpoint, useQueryParams } from 'utils/hooks';
import { getLoginState, getCustomer } from 'reduxState/user';

const swiperConfig = {
  slidesPerView: 1,
  autoplay: {
    delay: 4000,
  },
  loop: true,
};

const contentQuery = graphql`
  query {
    contentfulSectionBlocks(contentful_id: { eq: "2jRn8BRX9EHvCl6gejBnvg" }) {
      type
      percentages {
        ... on ContentfulSectionSimpleText {
          contentful_id
          name
          primaryButton {
            ... on ContentfulCallToAction {
              name
              linkText
              linkUrl
            }
          }
          body {
            body
          }
        }
      }
    }
  }
`;

export const PureMessageBanner = ({ isLoggedIn, customer, hasPromo99, isMobile, location, data }) => {
  const contentful = useSelector((state) => state.contentful);
  swiperConfig.autoplay.delay = (data.contentfulSectionBlocks.type && Number(data.contentfulSectionBlocks.type)) || 4000;
  const bannerItems = useMemo(() => {
    const blocks = data.contentfulSectionBlocks.percentages;
    let items = [];
    const [referralMessage, messages] = _partition(blocks, (block) => block.contentful_id === '2cJ25uZ9SBFsWp0z9V5VSI');
    const farmstandLink =
      location.pathname === paths.FARMSTAND || location.pathname === paths.SHOP_PRODUCTS
        ? `${location.pathname}#shop-farmstands`
        : paths.FARMSTAND;
    isLoggedIn &&
      customer?.referralCode &&
      items.push(
        <SlimBanner key='refer-banner' modifiers='inverted'>
          <Text
            modifiers={['brandFont', 'small']}
            as='span'
            isHTML
            content={`${referralMessage[0]?.body?.body}${isMobile ? '<br>' : ' - '}SHARE YOUR CODE: <b>${customer.referralCode}</b></p>`}
          ></Text>
        </SlimBanner>
      );

    hasPromo99 &&
      items.push(
        <SlimBanner key='promo-banner' modifiers='inverted'>
          <Text modifiers={['brandFont', 'small']} as='span'>
            <p>
              99% Invisible Fans - Use Code <b>99percentpod</b> for an Exclusive Discount!{' '}
              <Link href={paths.SHOP_PRODUCTS} content='SHOP NOW' eventData={{ action: 'SHOP NOW' }}></Link>
            </p>
          </Text>
        </SlimBanner>
      );

    if (location.pathname === paths.CHECKOUT) {
      items.push(
        <SlimBanner key='refer-banner' modifiers='inverted'>
          <Text modifiers={['brandFont', 'small']} as='span' isHTML content={contentful.messages?.LG_returnPolicy}></Text>
        </SlimBanner>
      );
    } else {
      messages.forEach((block, idx) => {
        const linkText = block.primaryButton?.linkText;
        const linkUrl = block.primaryButton?.linkUrl;
        const link = linkUrl === paths.FARMSTAND ? farmstandLink : linkUrl;
        items.push(
          <SlimBanner key={idx} modifiers='inverted'>
            <Text isHTML modifiers={['brandFont', 'small']} content={`${block.body?.body}`} as='span'></Text>
            {idx === 1 && (
              <span className='affirm-product-modal' data-page-type='product' data-amount={46900}>
                <Icon modifiers={['small', 'inverted']}>
                  <InfoIcon />
                </Icon>
              </span>
            )}
            {!!linkText && !!linkUrl && (
              <Link modifiers={['underline', 'accentColor', 'small']} content={linkText} href={link} eventData={{ action: linkText }} />
            )}
          </SlimBanner>
        );
      });
    }
    return items;
  }, [
    contentful.messages?.LG_returnPolicy,
    isLoggedIn,
    hasPromo99,
    isMobile,
    customer.referralCode,
    location.pathname,
    data.contentfulSectionBlocks,
  ]);
  return (
    <MessageBannerStyled>{<Swiper items={bannerItems} swiperProps={swiperConfig} renderSlideCard={(item) => item} />}</MessageBannerStyled>
  );
};

PureMessageBanner.propTypes = {
  isLoggedIn: PropTypes.bool,
  customer: PropTypes.object,
  hasPromo99: PropTypes.bool,
  isMobile: PropTypes.bool,
  location: PropTypes.object,
  data: PropTypes.shape({
    contentfulSectionBlocks: PropTypes.shape({
      type: PropTypes.any, // contentful hack to use existing field
      percentages: PropTypes.array,
    }),
  }),
};
PureMessageBanner.defaultProps = {
  isLoggedIn: false,
  customer: {},
  hasPromo99: false,
  isMobile: true,
  location: {},
  data: {},
};

const MessageBanner = ({ location }) => {
  const { source } = useQueryParams('pc');
  const hasPromo99 = source === '99pi';
  const isLoggedIn = useSelector(getLoginState);
  const viewportSize = useBreakpoint();
  const isMobile = viewportSize === 'SM';
  const customer = useSelector(getCustomer);
  return (
    <StaticQuery
      query={contentQuery}
      render={(data) => {
        return (
          <PureMessageBanner
            hasPromo99={hasPromo99}
            isLoggedIn={isLoggedIn}
            isMobile={isMobile}
            customer={customer}
            data={data}
            location={location}
          />
        );
      }}
    ></StaticQuery>
  );
};
MessageBanner.defaultProps = {};
MessageBanner.propTypes = {
  location: PropTypes.shape({ pathname: PropTypes.string }).isRequired,
};

export default MessageBanner;
