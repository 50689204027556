import styled from 'styled-components';
import breakPoints from 'theme/Media';

export const FarmModalStyled = styled.div.attrs({
  className: 'FarmModal',
})`
  .ComponentModal {
    .ModalWrapper {
      .modal__Content {
        .ModalInner {
          max-width: 23.5rem;
        }
      }
    }
  }

  @media ${breakPoints.medium_break} {
    .ComponentModal {
      .ModalWrapper {
        .modal__Content {
          .ModalInner {
            max-width: 29.5rem;
          }
        }
      }
    }
  }
`;
