import paths from 'constants/paths';
import shopCategories from 'constants/shopCategories';
import { KNOT_PLANT_BUNDLE_SKUS } from 'constants/sku';

export const formatPrice = (number, fixed = 2, hasDollarSign = true) => {
  let fixedDecimal = `${Number.parseFloat(number).toFixed(fixed)}`;
  if (hasDollarSign) fixedDecimal = '$' + fixedDecimal;

  //https://stackoverflow.com/questions/2901102/how-to-print-a-number-with-commas-as-thousands-separators-in-javascript
  // if formatting for view with $ - add a comma for thousands
  return hasDollarSign ? fixedDecimal.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',') : fixedDecimal.toString();
};

// if price is not whole dollar show cents (2 decimal points), otherwise just show whole dollar
export const formatDynamicDecimalPrice = (priceCents, hasDollarSign = true) => {
  return formatPrice((priceCents / 100) * 1, priceCents % 100 !== 0 ? 2 : 0, hasDollarSign);
};

// used for analytics formatting in checkout events
export const formatProductsForDataLayer = (items) => {
  return items.map((item) => {
    return {
      name: item.name,
      id: item.sku,
      price: `${item.priceCents / 100}`,
      category: item.category,
      quantity: item.qty,
    };
  });
};

export const getItemPath = (item, customBundleSlug) => {
  if (KNOT_PLANT_BUNDLE_SKUS.includes(item?.sku)) {
    return `${paths.HARVEST_PLAN.KNOT}/${item?.plantCount}`;
  } else if (item?.category === shopCategories.SEEDLINGS) {
    return `${paths.SEEDLINGS}/${item?.slug}`;
  } else if (item?.category === shopCategories.BUNDLES) {
    return `${paths.BUNDLES}/${item?.slug}`;
  } else if (item?.category === shopCategories.CUSTOM_BUNDLES) {
    return `${paths.BUNDLES}/${customBundleSlug}`;
  } else if (item?.category === shopCategories.FARMSTAND) {
    return paths.FARMSTAND;
  }
  return `${paths.SHOP_PRODUCTS}/${item?.slug}`;
};

// not a selector because this is used for both checkout and order confirmation views - so "cart" argument will be different
export const getTotalCost = ({ subtotalCents, taxCents, shippingCents, promoCents, giftCents, creditCents }) => {
  return Math.max(subtotalCents + taxCents + shippingCents - promoCents - giftCents - creditCents, 0);
};
