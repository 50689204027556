import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import styled from 'styled-components/macro';

import { getShouldShowAffirm } from 'reduxState/cart';

import { CHECKOUT_GIFT_AND_CREDITS } from 'constants/contentful';
import { useContent } from 'utils/hooks';

import { ButtonText, Text } from 'elements';

import { getTotalCost, formatPrice } from 'utils/cart-utils';
import icons from 'constants/icons';
import paths from 'constants/paths';
import colors from 'constants/styledColors';
import breakpoints from 'constants/breakpoints';
import breakPoints from 'theme/Media';

const FooterContainer = styled.section`
  position: sticky;
  bottom: 0;
  left: 0;
  width: 100%;
  display: block;
  box-sizing: border-box;
  font-family: Rubik, sans-serif;

  @media ${breakPoints.medium_break} {
    padding: 0 1.5rem;
  }
`;

const TotalsContainer = styled.section.attrs({
  className: 'TotalsContainer',
})``;

const FooterLineItem = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;

  div {
    text-align: right;
  }

  p {
    position: relative;
    span {
      font-size: ${({ theme }) => theme.sizes.x6};
      font-style: italic;
    }
  }
  p.price-discount {
    margin-right: 0.3rem;
  }
  p.affirm-product-modal {
    span {
      font-size: ${({ theme }) => theme.sizes.x10};
      font-style: initial;
      vertical-align: middle;
    }
  }
`;

const Subtotal = styled(FooterLineItem)`
  span {
    text-decoration: line-through;
    margin-right: 0.3rem;
    &:last-of-type {
      text-decoration: none;
      margin-right: 0;
    }
  }
`;

const Total = styled(FooterLineItem)`
  margin: 6px 0;
  padding: 6px 0 0;
  align-items: flex-start;

  p.affirm-preview-text {
    font-size: ${({ theme }) => theme.sizes.x7};
    display: inline-block;
  }
`;

const FooterActions = styled.section.attrs({
  className: 'FooterActions',
})`
  text-align: center;

  ul {
    display: flex;
    flex-direction: row;
    justify-content: center;

    .separator {
      margin: 0 8px;
    }
  }
  div {
    padding: 0 !important;
    button {
      width: 100%;
      margin: 10px 0;
    }
  }

  ul li {
    &:first-child,
    &.separator {
      display: none;
    }
  }
`;

const TooltipWrapper = styled.div`
  position: relative;
  margin-left: 5px;
  margin-right: auto;
  display: inline;
  cursor: pointer;

  &:hover {
    color: ${colors.MAIN_GREEN};
  }

  &:hover .tooltip-content {
    visibility: visible;
  }

  span {
    font-size: ${({ theme }) => theme.sizes.x9};

    @media (min-width: ${breakpoints.MOBILE}px) {
      font-size: ${({ theme }) => theme.sizes.x8};
    }
  }

  .tooltip-content {
    position: absolute;
    font-size: ${({ theme }) => theme.sizes.x6};
    left: 103%;
    top: 0;
    visibility: hidden;
    max-width: 220px;
    width: max-content;
    box-shadow: -1px 1px 2px 0px ${colors.GREY};
    padding: 6px;
    color: ${colors.BLACK};
    background: ${colors.WHITE};
    z-index: 1;
    text-align: left;
    &:before {
      content: '';
      background: ${colors.WHITE};
      height: 8px;
      width: 8px;
      transform: rotate(45deg);
      position: absolute;
      left: -3px;
      top: 9px;
      box-shadow: -2px 3px 2px -1px ${colors.GREY};
    }

    &:hover {
      visibility: visible;
    }

    @media (min-width: ${breakpoints.MOBILE}px) {
    }
  }
`;

/**
 * * Footer Component for the Order Summary on Lettuce Grow
 *
 * @prop {function} closeModal - the function that will close the modal in which the cart lives
 * @prop {object} cart - an object from our redux store representing all cart information
 * @prop {object} completedCart - an object from our redux store representing all cart information
 * @prop {bool} isCheckout - determine slight style adjustments, depending on where Order Summary is located
 *
 * ? Should this be refactored to be just an extension of the Cart Footer?
 *
 */

const OrderSummaryFooter = ({ closeModal, cart, completedCart, isCheckout }) => {
  const shouldShowAffirm = useSelector(getShouldShowAffirm);
  const giftAndCreditsText = useContent(CHECKOUT_GIFT_AND_CREDITS)?.[0]?.fields?.body;
  const shippingDiscount = cart.baseShippingCents - cart.shippingCents;
  const userZip = useSelector((state) => state.user.zip || '73301');
  const shippingMessages = cart.selectedShippingOptions?.map((type) => {
    return type.option.messages?.join?.(' ');
  });

  return (
    <FooterContainer isCheckout={isCheckout}>
      <TotalsContainer>
        <Subtotal>
          <Text modifiers={['bold', 'small']}>SUBTOTAL</Text>
          {/* Discounts from promo codes */}
          <div>
            {!!cart.promoCents && (
              <Text className='price-discount' as='span' modifiers='small'>
                {formatPrice(cart.subtotalCents / 100)}
              </Text>
            )}
            <Text as='span' modifiers={['bold', 'small']}>
              {formatPrice(Math.max(0, (cart.subtotalCents - cart.promoCents) / 100))}
            </Text>
          </div>
        </Subtotal>
        <FooterLineItem>
          <Text modifiers='small'>SHIPPING</Text>
          {!!shippingMessages?.length && (
            <TooltipWrapper>
              <span className={`icon-${icons.INFO}`} />
              <Text className='tooltip-content' isHTML modifiers='tiny' content={shippingMessages.join(' ')}></Text>
            </TooltipWrapper>
          )}
          {!!shippingDiscount && (
            <Text className='price-discount' dataNw='shipping-price-discount' modifiers={['small', 'lineThrough']}>
              {formatPrice(shippingDiscount / 100)}
            </Text>
          )}
          <Text dataNw='shipping-price' modifiers='small'>
            {formatPrice(cart.shippingCents / 100)}
          </Text>
        </FooterLineItem>
        <FooterLineItem>
          <Text modifiers='small'>
            {`${completedCart ? '' : 'ESTIMATED'} TAX`} <span>(based on zip code {userZip})</span>
          </Text>
          <Text modifiers='small'>{formatPrice(cart.taxCents / 100)}</Text>
        </FooterLineItem>
        {(!!cart.giftCents || !!cart.creditCents) && (
          <FooterLineItem>
            <Text modifiers='small'>GIFTS & CREDITS</Text>
            {giftAndCreditsText && (
              <TooltipWrapper>
                <span className={`icon-${icons.INFO}`} />
                <Text className='tooltip-content' isHTML modifiers='tiny' content={giftAndCreditsText}></Text>
              </TooltipWrapper>
            )}
            <Text modifiers='small'>
              {/* HEADS UP - Math.min used here to handle case of gift/credit amount larger than cart total */}-
              {formatPrice(
                Math.min(cart.subtotalCents + cart.taxCents + cart.shippingCents - cart.promoCents, cart.giftCents + cart.creditCents) / 100
              )}
            </Text>
          </FooterLineItem>
        )}
        <Total>
          <Text modifiers={['xLarge', 'brandColor']}>TOTAL</Text>
          <div>
            <Text modifiers={['xLarge', 'brandColor']}>{formatPrice(getTotalCost(cart) / 100)}</Text>
            {/* Should we show affirm? Don't show if in order conf */}
            {shouldShowAffirm && !completedCart && (
              <>
                <p className='affirm-preview-text'>{`Or $${Math.ceil(getTotalCost(cart) / 100 / 12)}/month at 0% APR`}</p>{' '}
                {!!getTotalCost(cart) && (
                  <p style={{ display: 'inline' }} className='affirm-product-modal' data-page-type='cart' data-amount={getTotalCost(cart)}>
                    <span className={`icon icon-${icons.INFO}`} />
                  </p>
                )}
              </>
            )}
          </div>
        </Total>
      </TotalsContainer>
      {!completedCart && (
        <FooterActions>
          <ul>
            <li>
              <ButtonText modifiers={['underlineContent']} onClick={closeModal}>
                Back to Checkout
              </ButtonText>
            </li>
            <li className='separator'>|</li>
            <li>
              <ButtonText modifiers={['underlineContent']} onClick={closeModal} href={paths.SHOP_PRODUCTS}>
                KEEP SHOPPING
              </ButtonText>
            </li>
          </ul>
        </FooterActions>
      )}
    </FooterContainer>
  );
};

OrderSummaryFooter.propTypes = {
  cart: PropTypes.object.isRequired,
  completedCart: PropTypes.object,
  closeModal: PropTypes.func,
  isCheckout: PropTypes.bool, // Are we in the checkout page?
};

export default OrderSummaryFooter;
