import styled from 'styled-components';

import { SquaredButton } from 'elements';

export const HeaterSelectorStyled = styled.div.attrs({
  className: 'HeaterSelectorStyled',
})`
  display: flex;
  flex-direction: column;
  margin: 1rem 0;
`;

export const OptionButtonGroup = styled.div.attrs({
  className: 'OptionButtonGroup',
})`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin: 0.25rem 0 0.5rem;
`;

export const SelectOptionButton = styled(SquaredButton).attrs({
  className: 'SelectOptionButton',
})`
  height: 2.5rem;
  width: auto;
  padding: 0.25rem;
  margin-right: 0.5rem;

  span {
    font-size: 0.875rem;
  }
`;
