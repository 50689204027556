import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { EnvSelectorStyled, OptionButtonGroup, SelectOptionButton } from './EnvSelector.styled';
import { Text } from 'elements';

import growingEnvironments from 'constants/growingEnvironments';

const { INDOORS, OUTDOORS, UNSET } = growingEnvironments;

const options = [
  { label: 'Indoors', value: INDOORS },
  { label: 'Outdoors', value: OUTDOORS },
];

const EnvSelector = ({ title, field, form }) => {
  const initialValue = !field.value || field.value === UNSET ? null : field.value === INDOORS ? INDOORS : OUTDOORS;
  const [selectedOption, setSelectedOption] = useState(initialValue);

  const onSelectOption = (option) => {
    setSelectedOption(option);
    const setField = form?.setFieldValue;
    if (field?.name && setField) setField(field.name, option);
  };

  const renderEnvOption = ({ label, value }, index) => {
    const isSelected = value === selectedOption;
    const modifiers = [isSelected ? 'primaryNoHover' : 'invertedNoHover'];

    return (
      <SelectOptionButton
        type='button'
        key={index}
        modifiers={modifiers}
        label={label}
        ariaLabel={label}
        onClick={() => onSelectOption(value, index)}
        ariaSelected={isSelected ? 'true' : 'false'}
      />
    );
  };

  return (
    <EnvSelectorStyled>
      {!!title && <Text modifiers={['brandFont', 'bold', 'small', 'left']} content={title} as='span' />}
      <OptionButtonGroup>{options.map(renderEnvOption)}</OptionButtonGroup>
    </EnvSelectorStyled>
  );
};

EnvSelector.propTypes = {
  title: PropTypes.string,
  field: PropTypes.object,
  form: PropTypes.object,
};

export default EnvSelector;
