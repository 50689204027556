import styled from 'styled-components';
import { applyStyleModifiers } from 'styled-components-modifiers';

export const modifiers = {
  nativeHeight: () => ({
    styles: `
      height: auto;
      padding-top: 60%;
    `,
  }),
  cover: () => ({
    styles: ` 
      img {
        height: 100%;
        object-fit: cover;
        object-position: center;
      }
    `,
  }),
  noTransform: () => ({
    styles: `
      img {
        position: initial;
        transform: none;
      }
    `,
  }),
};

const ImageStyled = styled.div.attrs({
  className: 'Image',
})`
  width: 100%;
  height: 100%;
  display: block;
  overflow: hidden;
  position: relative;
  box-sizing: border-box;

  picture {
    display: block;
    width: 100%;
    height: 100%;
  }

  img {
    width: 100%;
    height: auto;
    display: block;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  ${applyStyleModifiers(modifiers)};
`;

export default ImageStyled;
