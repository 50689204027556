import createSagaMiddleware from 'redux-saga';
import { all } from 'redux-saga/effects';

import cartSagas from './cartSagas';
import userSagas from './userSagas';
import catalogSagas from './catalogSagas';

export function* rootSaga() {
  yield all([...cartSagas.map((saga) => saga()), ...userSagas.map((saga) => saga()), ...catalogSagas.map((saga) => saga())]);
}

export default createSagaMiddleware();
