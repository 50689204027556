import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useLocation } from '@reach/router';
import { getCartSideModalToShow } from 'reduxState/cart';
import { Link } from 'gatsby';
import { ShopMenu } from 'constants/shopMenu';

import { WrapSwipe, Spacer } from './HorizontalSwipeMenuShop.styled';
import PropTypes from 'prop-types';
import { useScrollListener } from 'utils/hooks';
import { trackClick } from 'utils/googleTagManager';

const HorizontalSwipeMenuShop = () => {
  const cartSideModalToShow = useSelector(getCartSideModalToShow);
  const location = useLocation();
  const isPageScrolled = useScrollListener(30);

  useEffect(() => {
    if (typeof window !== 'undefined') {
      const wrapSwipe = document.querySelector('.WrapSwipe');
      const currentItem = document.querySelector('.WrapSwipe .active');
      if (wrapSwipe && currentItem) {
        wrapSwipe.scrollLeft = currentItem.offsetLeft;
      }
    }
  });

  return (
    <>
      <Spacer />
      <WrapSwipe className={isPageScrolled || !!cartSideModalToShow ? 'fixedTop' : ''}>
        {ShopMenu.map((link) => {
          const isActive = location.pathname.includes(link.path);
          return (
            <Link
              key={link.label}
              to={link.path}
              className={isActive ? 'active' : ''}
              onClick={() => trackClick({ action: link.label_alt })}
            >
              {link.label_alt}
            </Link>
          );
        })}
      </WrapSwipe>
    </>
  );
};

HorizontalSwipeMenuShop.defaultProps = {
  variation: 0,
};
HorizontalSwipeMenuShop.propTypes = {
  variation: PropTypes.number,
};

export default HorizontalSwipeMenuShop;
