export const HALO_LIGHTS = 'Z3Jvdy5kYi5CdXlhYmxlLTEzMzQ3MzUx';
export const HALO_LIGHTS_1 = 'Z3Jvdy5kYi5CdXlhYmxlLTE0Mzc1ODUx';
export const HALO_LIGHTS_2 = 'Z3Jvdy5kYi5CdXlhYmxlLTE0ODI0MzUx';
export const HALO_LIGHTS_3 = 'Z3Jvdy5kYi5CdXlhYmxlLTE0ODI0MzUy';
export const HALO_LIGHTS_5 = 'Z3Jvdy5kYi5CdXlhYmxlLTE0MjU5ODUx';
export const HALO_LIGHTS_6 = 'Z3Jvdy5kYi5CdXlhYmxlLTEzOQ';

export const FARMSTAND_12 = 'Z3Jvdy5kYi5EZXZpY2VUeXBlLTEw';
export const FARMSTAND_18 = 'Z3Jvdy5kYi5EZXZpY2VUeXBlLTEx';
export const FARMSTAND_24 = 'Z3Jvdy5kYi5EZXZpY2VUeXBlLTE';
export const FARMSTAND_30 = 'Z3Jvdy5kYi5EZXZpY2VUeXBlLTIxODg1MQ';
export const FARMSTAND_36 = 'Z3Jvdy5kYi5EZXZpY2VUeXBlLTI';

/** IMPORTANT: WE SHOULD NOT PUT HALO_LIGHTS_1 IN THE HALO_LIGHTS_SKU ARRAY */
export const HALO_LIGHTS_SKUS = [HALO_LIGHTS, HALO_LIGHTS_2, HALO_LIGHTS_3, HALO_LIGHTS_5, HALO_LIGHTS_6];
export const HALO_LIGHTS_ORDERED_SKUS = [HALO_LIGHTS_2, HALO_LIGHTS_3, HALO_LIGHTS, HALO_LIGHTS_5, HALO_LIGHTS_6];
export const ALL_HALOS = [HALO_LIGHTS_1, ...HALO_LIGHTS_SKUS];

export const FARMSTAND_SKUS = [FARMSTAND_12, FARMSTAND_18, FARMSTAND_24, FARMSTAND_30, FARMSTAND_36];

export const glowRingsMapSizeSku = {
  12: HALO_LIGHTS_ORDERED_SKUS[0],
  18: HALO_LIGHTS_ORDERED_SKUS[1],
  24: HALO_LIGHTS_ORDERED_SKUS[2],
  30: HALO_LIGHTS_ORDERED_SKUS[3],
  36: HALO_LIGHTS_ORDERED_SKUS[4],
};

export const farmMapSizeSku = {
  12: FARMSTAND_12,
  18: FARMSTAND_18,
  24: FARMSTAND_24,
  30: FARMSTAND_30,
  36: FARMSTAND_36,
};

export const PERSONAL_PLANT_BUNDLE_SKU = 'Z3Jvdy5kYi5QbGFudEJ1bmRsZS0yMTU4ODM1MQ';

export const KNOT_FOODIE_BUNDLE_SKUS = [
  'Z3Jvdy5kYi5QbGFudEJ1bmRsZS0zNDU',
  'Z3Jvdy5kYi5QbGFudEJ1bmRsZS0zNDQ',
  'Z3Jvdy5kYi5QbGFudEJ1bmRsZS0zNDM',
];

export const KNOT_NEWLYWED_BUNDLE_SKUS = [
  'Z3Jvdy5kYi5QbGFudEJ1bmRsZS0zNDA',
  'Z3Jvdy5kYi5QbGFudEJ1bmRsZS0zNDE',
  'Z3Jvdy5kYi5QbGFudEJ1bmRsZS0zNDI',
];

export const KNOT_PLANT_BUNDLE_SKUS = [...KNOT_FOODIE_BUNDLE_SKUS, ...KNOT_NEWLYWED_BUNDLE_SKUS];

export const DOLLY = 'Z3Jvdy5kYi5CdXlhYmxlLTEwMDA';
