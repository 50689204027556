export const BLOG_PAGE = { id: '4VQ6UbfsfxtfD58LOsNNh1', label: 'blogPage' };
export const BLOG_POST_PAGE = { id: '6EIU5zv0YnxJXlYTrz3Lj6', label: 'blogPostPage' };
export const BLOG_CATEGORY_PAGE = { id: '11OGqr7VcmUSGFJNvVtCWB', label: 'blogCategoryPage' };
export const BLOG_POSTS = { content_type: 'blogPost', label: 'blogPosts', order: 'fields.rank' };
export const BLOG_CATEGORIES = { content_type: 'postCategory', label: 'blogCategories', order: 'fields.rank' };
export const BUYABLES = {
  content_type: 'buyable',
  label: 'buyables',
  order: 'fields.rank',
  limit: 150,
  query: { 'fields.category[nin]': 'Seedlings,PlantBundleMix,Plant+Packs' },
};
export const FARMSTAND_DEVICES = { content_type: 'deviceType', label: 'deviceTypes', order: 'fields.size' };
export const CART_CHANGED_MODAL = { id: '6l361sQ9wycxfi6RrW0Qcg', label: 'cartChangedModal' };
export const CLIMATE_HEATER_MODAL = { id: '7jkBtw4Ml8cwIwaHGyoIdz', label: 'climateHeaterModal' };
export const CLIMATE_LIGHTING_MODAL = { id: '7ek9Uyu8r8KzUF7KMZaGih', label: 'climateLightingModal' };
export const CLIMATE_GOOD_GROW_MODAL = { id: '4QjBqSuXnH2etBDrYOqNMm', label: 'climateGoodToGrowModal' };
export const CONTACT_SECTION = { id: '509tndw5LI2g5DPGrK1qJl', label: 'contactSection' };
export const CONTACT_US = { id: '50Q7hcdtkUEdAAP3BhXuOQ', label: 'contactUs' };
export const FILL_YOUR_FARMSTAND = { id: '6dL5NaxbVodpbLfvGyZLjO', label: 'fillYourFarmstand' };
export const FYF_BUNDLES_2020 = { id: '1jekSX2sbuSPQHe39SKiqA', label: 'fillYourFarmstandBundles' };
export const FAQ_LANDING = { id: '5rfCTDpIVEryyB7FWU9vRc', label: 'faqLanding' };
export const FAQ_CATEGORIES = { content_type: 'faqCategory', label: 'faqCategories' };
export const FAQS = { content_type: 'faq', label: 'faqs' };
export const GIFT_CARD = { id: 'Z3Jvdy5kYi5CdXlhYmxlLTEwOQ', label: 'giftCard' };
export const GROW_ENVIRONMENT_MODAL = { id: '45ZG9i4sLhAfXylh9pzw8J', label: 'growEnvironmentModal' };
export const INTERNATIONAL_MODAL = { id: '2PBaLmCg0ByuTiONP3u7pD', label: 'internationalModal' };
export const NOT_FOUND = { id: '7BwcwkHvbK9lExXnbwxQQ9', label: 'notFound' };
export const FARMSTANDS_2020 = { id: '1368lss8SXKqO26v9USf0B', label: 'farmstands' };
export const GLOWRINGS_PDP_2020 = { id: 'rF9TSF2zbumtm8LKbPIv5', label: 'glowrings-pdp' };
export const FARMSTAND_BUNDLE_PDP_2020 = { id: '5uPCyUZX7GQrkTXIIAOGkd', label: 'farm-bundle-pdp' };
export const NEW_FARMSTAND_BUNDLE_PDP_2021 = { id: '7FIgQlGBdtWDyd6zVxNjZ2', label: 'farm-bundle-2-pdp' };
export const LETTUCE_GIVE = { id: '42pooB1pqiCGIPsYI9QBWu', label: 'lettuceGive' };
export const LOGIN_PAGE = { id: '78Ul6L3qyACyjA2oDpQzxR', label: 'loginPage' };
export const MY_FARM = { id: '1OT1PzOAjOQw6y0u24YM4c', label: 'myFarm' };
export const NEWSLETTER_SIGNUP = { id: '5n5iTS7J96iiYassgicaMu', label: 'newsletterSignup' };
export const ORDER_CONFIRMATION = { id: '77Ggub9ikqqyszNFBtdAZ4', label: 'orderConfirmation' };
export const PLANT_TYPE = { content_type: 'plantType', label: 'plantType', limit: 500, query: { 'fields.weblistingEnabled': true } };
export const PRIVACY = { id: '1hx5O6QSgwM0QGmwsem24q', label: 'privacy' };
export const PROMO_BANNER = { id: '7w9wY5M6ap22YJwju51AVe', label: 'promoBanner' };
export const PROMO_BANNER_MESSAGES = { id: '6HZJ2EkCCdQKAasMT2Nqel', label: 'promoBannerMessages' };
export const PRESS_SECTION = { id: 'ImssOjXCN4kLN3lnPuxxP', label: 'pressSection' };
export const RECIPES = { content_type: 'recipe', label: 'recipes', limit: 500 };
export const RECIPE_DETAIL_PAGE = { id: '2daRgzc1RNpQ2DOmb0x7OP', label: 'recipeDetailPage' };
export const REVIEWS_PAGE = { id: '5FY8CU13VToFohBjJxUY7g', label: 'reviewsPage' };
export const SEEDLINGS_SUPPLIES = { id: '44ZPb1DhKgGMgtjK6FBuUl', label: 'seedlingsSupplies' };
export const SEEDLING_PACKS = { id: '34nLDcIepP7KUs1vUtc0ny', label: 'packRecommendation' };
export const SEEDLING_DETAIL = { id: '4aVH0AS5Ghmicqteg62Oor', label: 'seedlingDetail' };
export const SEEDLINGS_REVIEW_CONFIRMATION = { id: '6gp6rEw7z4eznyl2AX5fMT', label: 'seedlingsReviewConfirmation' };
export const SOCIAL_POSTS = { id: '5w57D0LlHZOHN1jv8WksL9', label: 'socialPosts' };
export const SEEDLINGS_BUNDLE_PAGE = { id: '37sari6WHauDSQ26Jwdenm', label: 'bundleDetail' };
export const PLANT_BUNDLE = { content_type: 'plantBundle', label: 'plantBundle', limit: 500, query: { 'fields.weblistingEnabled': true } };
export const SURVEY_RESULTS_2020 = { id: '6rdwKjaoZnIjsy1a71v1ph', label: 'surveyResults' };
export const TERMS_OF_SERVICE = { id: '4mfd5ulHReeIy2GKUUqIQs', label: 'terms' };
export const VERIFY_ADDRESS_MODAL = { id: '19X61XaESFtKvDkDQpU13J', label: 'verifyAddressModal' };
export const VERIFY_ADDRESS_MODAL_PO_BOX = { id: '6Kd39Baj81WqjhBDtura1J', label: 'verifyAddressModalPoBox' };
export const VIRTUAL_SHOPPING_2020 = { id: '4biLPOsFACdbhPwWgxjqGo', label: 'virtualConsultation' };
export const WARRANTY = { id: '5epifsm2g72hAzo7qrKgsY', label: 'warranty' };
export const CHECKOUT_POLICY = { id: '62lbbiiO5ClfjA0ZNv6NDE', label: 'checkoutPolicy' };
export const CHECKOUT_GIFT_MODAL = { id: 'cHAxwfJVnhfy1oE88BtRT', label: 'checkoutGiftModal' };
export const CHECKOUT_GIFT_AND_CREDITS = { id: '7H4aXWk1B4Zn72zIxlcDJD', label: 'checkoutGiftAndCredits' };
export const ZOOEY_HARVEST_PLAN = { id: '2Wi17rdZ78t63lNzU3dQde', label: 'zooeyHarvestPlan' };
export const CHIPOTLE_HARVEST_PLAN = { id: '6H6JPPCjX4NH4hFnNrhCAB', label: 'chipotleHarvestPlan' };
export const MATTJAMES_HARVEST_PLAN = { id: '2pCaHRIKvHugUeRLTCW9z2', label: 'mattJamesHarvestPlan' };
export const THRIVE_HARVEST_PLAN = { id: 'xp3yQtR6aPPrD16aXbhnJ', label: 'thriveHarvestPlan' };
export const BRIGHTLAND_HARVEST_PLAN = { id: '3UUvoeOvIpqn1bU1gyRFnz', label: 'brightlandHarvestPlan' };
export const KNOT_HARVEST_PLAN = { id: '6NXGvSXsVW0MSHTrhz5IL3', label: 'knotHarvestPlan' };
export const COSTCO_HARVEST_PLAN = { id: '4r52StgcClC9dxweLz8CTF', label: 'costcoHarvestPlan' };
export const AMAZON_HARVEST_PLAN = { id: '7MLwsAyGsNUxfy2CDqSnUW', label: 'amazonLandingPlan' };
export const AFFIRM_LANDING = { id: '6x349cgATS6EtKZb6W17H7', label: 'affirmLanding' };
export const ACCESSIBILITY_STATEMENT = { id: '403AVF5AOiI2Kgzjyh8Qtx', label: 'accessibilityStatement' };
export const ORDER_DETAILS_PAGE = { id: '3oJZOorIjS5rmKmiMNTGeS', label: 'orderDetails' };
export const CAREERS_PAGE = { id: '5CAFcL1RGFrprImwYqc8s8', label: 'careersPage' };
export const CHECKOUT_PLANTS_MESSAGE = { id: '2JyE4SqE6RRoKIF6s2SCxJ', label: 'checkoutPlantsMessage' };
export const LOGIN_CREATE_ACCOUNT_MESSAGE = { id: '1kB6KpzFTgOxlyRE1MFzES', label: 'loginCreateAccountMessage' };

export const COMMON_SECTIONS = {
  BRANDS: 'ImssOjXCN4kLN3lnPuxxP',
  GROW_IN: '3q9GAMH3NHWhPZSmX035la',
  HOW_IT_WORKS: '2u0GgNdS7rIYsRkYOng7rq',
  PRE_SPROUTED: '5w4Qb2w1icHpewYj9FDgiC',
  IMPACT_STATS: '18KnJM7VDtqMxRABjdS2WN',
  GROW_COMMUNITY: '72jmO8e1iOhD6UddOPNWhD',
  UGC: 'xBn9m1InL2MvkkWLP4cwR',
  FARMSTAND_REVIEWED: '1g5DCv2D6YefMNp6kNpz8I',
  FAQ: '3vbbqGgVf3d6tNwrKlGv27',
  REVIEWS_SLIDER: '5iKB67LikQEZLgZsUyhQQb',
  MAKER: '33Dc6AmFNnPhobCoxuRbkn',
  MAKER_NAV: '6JxxEo07vLSPS5liDo7X6T',
  MAKER_SHOP_NAV: '5tJYe08CVyHPOv3p5Pq0r2',
  GROW_APP: '4fjsAemZVeVzNkfJnv8SwZ',
  HERE_TO_HELP: 'zRhQWKx4UIjPhBqNtUlSs',
  CONTACT_SECTION: '509tndw5LI2g5DPGrK1qJl',
  FARMSTAND_PDP_SECTION: '1ImV6ZP0zJL4RtFbCWgQ9O',
};
