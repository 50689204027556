import React from 'react';
import PropTypes from 'prop-types';

import { ContentSection } from 'components';
import { GatsbyImage, SquaredButton, Text, Title } from 'elements';

import { ContentWrapper } from 'staticPages/HowItWorks/HowItWorks.styled';
import { PreSproutedList, PreSproutedItem, PreSproutedSection } from './PreSprouted.styled';
import { graphql, StaticQuery } from 'gatsby';

const PreSprouted = ({ id, title, bullets, body, body2, callToAction }) => {
  const imageData = graphql`
    query {
      contentfulSectionBlocks(contentful_id: { eq: "5w4Qb2w1icHpewYj9FDgiC" }) {
        leftBackgroundAccent {
          description
          gatsbyImageData(layout: FULL_WIDTH, placeholder: DOMINANT_COLOR, formats: [AUTO, WEBP], quality: 70)
        }
      }
    }
  `;
  const ctaLabel = callToAction?.fields?.linkText;
  const ctaLink = callToAction?.fields?.linkUrl;

  return (
    <StaticQuery
      query={imageData}
      render={(data) => {
        return (
          <PreSproutedSection key={id}>
            <ContentSection animation='fadeMixUpDown'>
              <ContentWrapper>
                <GatsbyImage
                  modifiers='relativePosition'
                  image={data?.contentfulSectionBlocks?.leftBackgroundAccent?.gatsbyImageData}
                  alt={data?.contentfulSectionBlocks?.leftBackgroundAccent?.description}
                />
              </ContentWrapper>
              <ContentWrapper>
                <Title as='h3' modifiers='lineHeightNormal' content={title} />
                <Text content={body} />
                <PreSproutedList>
                  {bullets.map((bullet) => {
                    const id = bullet?.sys?.id;
                    const text = bullet?.fields?.body;

                    return (
                      <PreSproutedItem key={id}>
                        <Text content={text} />
                      </PreSproutedItem>
                    );
                  })}
                </PreSproutedList>
                <Text content={body2} />
                <SquaredButton label={ctaLabel} href={ctaLink} as='a' />
              </ContentWrapper>
            </ContentSection>
          </PreSproutedSection>
        );
      }}
    />
  );
};

PreSprouted.propTypes = {
  id: PropTypes.string,
  title: PropTypes.string,
  body: PropTypes.string,
  body2: PropTypes.string,
  bullets: PropTypes.array,
  callToAction: PropTypes.object,
};

export default PreSprouted;
