// eslint-disable-next-line no-undef
const { STATIC_URL = 'https://static.lettucegrow.com' } = process.env;

const paths = {
  BLOG: '/resources',
  CUSTOMER_REVIEWS: '/customer-reviews',
  DISABLE_CACHE: '/disable-content-cache',
  EXPERIENCE: '/how-it-works',
  HOME: '/',
  NOT_FOUND: '/not-found',
  MY_FARM: '/my-farm',
  ORDER_DETAILS: '/my-farm/order-details',
  OUR_MISSION: '/our-mission',
  SEEDLINGS: '/seedlings',
  SEEDLING_LIBRARY: '/resources/seedling-library',
  WHAT_WE_GROW: '/what-we-grow',
  HOW_IT_WORKS: '/the-journey',
  LOGIN: '/login',
  FARMSTAND: '/the-farmstand',
  FARMSTAND_SIZE: '/the-farmstand/:farmstandSize',
  FILL_YOUR_FARMSTAND: '/fill-your-farmstand',
  FILL_YOUR_FARMSTAND_BUNDLES: '/fill-your-farmstand-bundles',
  RECIPES: '/recipes',
  FAQ: '/faq',
  TERMS: '/terms',
  PRESS: '/press',
  CONTACT_US: '/contact-us',
  ORDER_CONFIRMATION: '/order-confirmation',
  PRIVACY: '/privacy',
  CCPA: '/privacy#california-privacy-rights',
  EXTERNAL_INSTAGRAM: 'https://www.instagram.com/lettucegrow/',
  EXTERNAL_YOUTUBE: 'https://www.youtube.com/lettucegrow',
  EXTERNAL_FACEBOOK: 'https://www.facebook.com/LettuceGrow/',
  EXTERNAL_TWITTER: 'https://www.twitter.com',
  EXTERNAL_TFP: 'https://www.thefarmproject.com',
  EXTERNAL_TIKTOK: 'https://www.tiktok.com/@lettuce.grow',
  AFFIRM_SUCCESS: '/affirm-complete',
  WARRANTY: '/warranty',
  SURVEY_RESULTS: '/survey-results',
  OPEN_SURVEY: '/typeform-survey',
  CHECKOUT: '/checkout',
  SHOP_PRODUCTS: '/shop',
  SEEDLING_PACK: '/seedling-pack',
  SEARCH_RESULTS: '/search-results',
  SEEDLING_REVIEW: '/seedling-review',
  SEEDLING_REVIEW_CONFIRMATION: '/seedling-review-confirmation',
  GIFT_CARD: '/shop/gift-card',
  VIRTUAL_SHOPPING: '/virtual-consultation',
  BUNDLES: '/bundles',
  PROMOTION_PAGE: '/100-off-bundle',
  PROMOTION_PAGE_2: '/nkdrtl2021',
  AFFIRM_LANDING: '/affirm',
  ACCESSIBILITY_STATEMENT: '/accessibility-statement',
  CAREERS_PAGE: '/careers',
  RETURN_LANDING: '/returns',
  ASSEMBLY_GUIDE_PAGE: '/assembly',
  SANDBOX_LANDING: '/contentfulsandbox',
  CREATE_ACCOUNT: '/create-account',
  ANALYTICS: '/analytics',
  EDUCATION: '/education-contact',

  // Harvest plan pages
  HARVEST_PLAN: {
    ZOOEY: '/zooey',
    CHIPOTLE: '/chipotle',
    MATTJAMES: '/matt-james',
    BRIGHTLAND: '/brightland',
    KNOT: '/the-knot',
    KNOT_SIZE: '/the-knot/:farmstandSize',
    THRIVE: '/thrive-market',
    COSTCO: '/costcobundle',
    AMAZON: '/amazonbundle',
  },

  // container pages for maker iframe instances
  marketing: {
    GET_GROWING: '/get-growing',
    BREAKDOWN: '/BREAKDOWN',
    PAKMAN: '/PAKMAN',
    BEANS: '/BEANS',
    PAULA: '/PAULA',
    NANCY: '/NANCY',
    YO: '/YO',
    FAKETHENATION: '/FAKETHENATION',
    WHOA: '/WHOA',
    LISTEN: '/LISTEN',
    INDOOR_GROWING: '/indoor-growing',
    HOLIDAY: '/holiday',
  },

  //Shop pages
  shopPages: {
    GLOW_RINGS: {
      SIZE_2: '/shop/glow-rings-2',
      SIZE_3: '/shop/glow-rings-3',
      SIZE_4: '/shop/glow-rings-4',
      SIZE_5: '/shop/glow-rings-5',
      SIZE_6: '/shop/glow-rings-6',
    },
    PREORDER_GLOW_RINGS: {
      SIZE_2: '/shop/halo-preorder-2',
      SIZE_3: '/shop/halo-preorder-3',
      SIZE_4: '/shop/halo-preorder',
      SIZE_5: '/shop/halo-preorder-5',
    },
    FARMSTAND: '/shop-farmstand',
    GIFT_CARDS: '/shop/gift-card',
    SEEDLINGS: '/shop-seedlings',
    SUPPLIES: '/shop-supplies',
  },

  // Blog Posts
  blogPosts: {
    GROWING_TIPS: '/resources/time-to-bring-the-garden-party-inside',
    LETTUCE_GIVE: '/resources/lettuce-give',
    INDOOR_GROW_GUIDE: '/resources/the-lettuce-grow-guide-to-indoor-growing',
    EAT_GREEN: '/resources/eat-green',
    GROWING_GUIDE: '/resources/the-lettuce-grow-growing-guide',
  },

  // FAQ Posts
  faqPosts: {
    CLIMATE_GUIDE: '/faq/fall-climate-guide',
    FARMSTAND_UP_AND_RUNNING: '/faq/how-do-i-get-my-farmstand-up-and-running',
    ASSEMBLE_MY_FARMSTAND: '/faq/how-do-i-assemble-my-farmstand',
    FARMSTAND_PANTRY: '/faq/the-farmstand-pantry',
    FARMSTAND_TIMER: '/faq/setup-my-Farmstand-timer',
    TRANSPLANT_SEEDLINGS: '/faq/how-do-i-transplant-my-seedlings',
    ADJUST_PH: '/faq/how-do-i-test-and-adjust-my-ph',
    RESET_FARMSTAND: '/faq/how-do-i-reset-my-farmstand',
    REFERRAL: '/faq/refer-a-friend',
  },

  // PDF Links
  GROWING_GUIDE: '/growing-guide',
  GROWING_GUIDE_PDF: STATIC_URL + '/growing-guide.pdf',
  ASSEMBLY_GUIDES: '/assembly-guides',
  ASSEMBLY_GUIDES_PDF: STATIC_URL + '/assembly-guides.pdf',
  PANTRY_SHOPPING_LIST: '/pantry-shopping-list',
  PANTRY_SHOPPING_LIST_PDF: STATIC_URL + '/pantry.pdf',
  CLIMATE_GUIDE: '/fall-climate-guide',
  CLIMATE_GUIDE_PDF: STATIC_URL + '/fall-climate-guide.pdf',
};

module.exports = paths;
