import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { setUserSetEnvironment } from 'reduxState/user';
import { Text, ButtonText } from 'elements';
import Toggle from 'components/toggle/Toggle';

import { GrowEnvironmentToggleStyled } from './GrowEnvironmentToggle.styled';
import GrowEnvironmentModal from 'components/modal/grow-environment/GrowEnvironmentModal';

import growingEnvironments from 'constants/growingEnvironments';
import { setOpenModal, MODALS } from 'reduxState/modal';
import { getCatalogEnvironment } from 'reduxState/catalog';
import { useQueryParams } from 'utils/hooks';

const { OUTDOOR, INDOOR } = growingEnvironments;

const GrowEnvironmentToggle = ({ title, modifiers, showDetailModal }) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const recommendation = useSelector(getCatalogEnvironment);
  const activeToggleLabel = 'OUTDOORS';
  const inactiveToggleLabel = 'INDOORS';
  const isTitleString = typeof title === 'string';

  const onChangeHandle = (isOn) => {
    dispatch(setUserSetEnvironment(isOn ? OUTDOOR : INDOOR));
  };

  const handleGrowEnvironmentModal = () => {
    dispatch(setOpenModal(MODALS.GROW_ENVIRONMENT));
  };

  const { environment: paramEnvironment } = useQueryParams();

  useEffect(() => {
    if (paramEnvironment) {
      dispatch(setUserSetEnvironment(paramEnvironment));
      history.replace({
        search: '',
      });
    }
    /* eslint-disable-next-line */
  }, []);

  const startingEnvironment = paramEnvironment || recommendation;

  let renderDetailModal = title;
  if (showDetailModal) {
    renderDetailModal = (
      <ButtonText onClick={handleGrowEnvironmentModal} eventData={{ action: 'Which is best for me?' }}>
        {isTitleString ? <Text modifiers='brandFont' content={title} /> : title}
      </ButtonText>
    );
  } else if (isTitleString) {
    renderDetailModal = <Text modifiers='brandFont' content={title} />;
  }

  return (
    <>
      <GrowEnvironmentToggleStyled modifiers={modifiers}>
        {renderDetailModal}
        {startingEnvironment && (
          <Toggle
            startOn={startingEnvironment !== INDOOR}
            labelOn={activeToggleLabel}
            labelOff={inactiveToggleLabel}
            onChange={onChangeHandle}
            modifiers={modifiers}
            eventDataActive={{ action: isTitleString ? `${title} ${activeToggleLabel}` : activeToggleLabel }}
            eventDataInactive={{ action: isTitleString ? `${title} ${inactiveToggleLabel}` : inactiveToggleLabel }}
          />
        )}
      </GrowEnvironmentToggleStyled>
      {showDetailModal && <GrowEnvironmentModal />}
    </>
  );
};

GrowEnvironmentToggle.propTypes = {
  modifiers: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  showDetailModal: PropTypes.bool,
};

GrowEnvironmentToggle.defaultProps = {
  title: 'I’m primarily growing:',
  showDetailModal: false,
};

export default GrowEnvironmentToggle;
