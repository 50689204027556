import client from 'utils/contentful-client';
import {
  FETCH_CONTENTFUL,
  setContentCacheMap,
  fetchContentfulMessages,
  fetchContentfulSuceeded,
  fetchContentfulError,
} from 'reduxState/contentful';
import { fetchAppSettings } from 'reduxState/appSettings';
import logError from 'utils/errorHandler';

/**
 * * Redux Middleware for Contentful fetch action - FETCH_CONTENTFUL
 *
 * @param {Parameters in action.payload}
 * @param id - contentful ID
 * @param content_type - contentful content type
 * @param include - default contentful - 3
 * @param label - contentful name ie. home, howItWorks
 * @param limit - limit of how many entries to fetch - 100 default
 * @param customSetContent - custom setting function for unique content that needs to be scrubbed (errorMessages)
 *
 */

const contentfulMiddleware = ({ dispatch, getState }) => (next) => (action) => {
  next(action);

  const store = getState();

  if (action.type === setContentCacheMap.toString()) {
    dispatch(fetchAppSettings());
    dispatch(fetchContentfulMessages());
  }

  // Middleware only for getting contentful entries
  if (action.type !== FETCH_CONTENTFUL) return;

  // grab values from payload
  const { id = '', content_type = '', include = 3, label = '', limit = 100, order = '', query, customSetter } = action.payload;

  // params object to send to contentful based on id exists or not
  const params = id
    ? {
        'sys.id': id,
        include,
      }
    : {
        content_type,
        order,
        include,
        limit,
        ...query,
      };

  client(params, store, store.appSettings.disableContentCache)
    .getEntries(params)
    .then((data) => {
      // clear loading state
      dispatch(fetchContentfulSuceeded({ label, data, customSetter }));
    })
    .catch((error) => {
      // set error in contentful store
      dispatch(fetchContentfulError({ error: error.message }));
      // dispatch error for logging
      logError(error);
    });
};

export default contentfulMiddleware;
