import React from 'react';
import { Field } from 'formik';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';

import { Text, Input, Icon, Image } from 'elements';
import { PhotoContainer, AddPhotoBtnGroup, AddPhotoBtn, PhotoWrapper, PhotosTextContent } from './ImageUploads.styled';
import CloseIcon from 'elements/icon/assets/close.svg';
import AddPhotoIcon from 'elements/icon/assets/photo-icon.svg';

import { setOpenModal, setLabel } from 'reduxState/modal';
import { imageKeysFilter, MAX_BYTE_SIZE } from './utils';

const ImageUploads = ({ setFieldValue, values, title, description, acceptedTypes = ['image/*', 'video/*'] }) => {
  const dispatch = useDispatch();
  const imagesKeys = Object.keys(values)?.filter?.(imageKeysFilter);
  const totalFileSize = imagesKeys.reduce((sum, key) => sum + (values[key]?.size || 0), 0);
  const shouldDisplayTextContent = (title || description) && imagesKeys?.length === 1;

  const handleImageUpload = (id) => async (ev) => {
    const file = ev.currentTarget?.files?.[0];
    try {
      if (file?.type?.includes('image')) {
        const imageCompression = await import('browser-image-compression');
        const compressed = await imageCompression?.default(file, { maxSizeMB: 0.8 });
        !!compressed && setFieldValue(id, compressed);
      } else {
        !!file && setFieldValue(id, file);
      }
    } catch (error) {
      if (error?.message) dispatch(setLabel({ id: 'error', label: error.message }));
      dispatch(setOpenModal('error'));
    }
  };

  const handleRemoveImage = (id) => () => {
    setFieldValue(id, null);
  };

  return (
    <>
      <PhotoContainer modifiers={[shouldDisplayTextContent && 'singlePhotoWithText']}>
        <AddPhotoBtnGroup>
          {imagesKeys.map((id) => {
            if (!values[id]) {
              return (
                <Field
                  key={id}
                  component={() => (
                    <AddPhotoBtn>
                      <Input name={id} type='file' onChange={handleImageUpload(id)} accept={acceptedTypes.join(', ')} />
                      <Icon modifiers='darkGrayColor'>
                        <AddPhotoIcon />
                      </Icon>
                    </AddPhotoBtn>
                  )}
                />
              );
            } else {
              return (
                <PhotoWrapper key={id} hasError={values[id].size > MAX_BYTE_SIZE}>
                  {values[id].type?.includes('video') ? (
                    <video width='86'>
                      <source src={URL.createObjectURL(values[id])} type={values[id].type} />
                    </video>
                  ) : (
                    <Image picture={URL.createObjectURL(values[id])} hasQueryParams={false} />
                  )}
                  <Icon onClick={handleRemoveImage(id)} modifiers='small'>
                    <CloseIcon />
                  </Icon>
                </PhotoWrapper>
              );
            }
          })}
        </AddPhotoBtnGroup>
        {shouldDisplayTextContent && (
          <PhotosTextContent modifiers={['directionColumn', 'alignStart', 'justifyCenter']}>
            <Text modifiers={['brandFont', 'xLarge', 'semibold']}>{title}</Text>
            <Text modifiers={['brandFont', 'small', 'light']}>{description}</Text>
          </PhotosTextContent>
        )}
      </PhotoContainer>
      {totalFileSize > MAX_BYTE_SIZE && (
        <Text modifiers={['tiny', 'error']} content={`Maximum total file size is ${MAX_BYTE_SIZE / 1000000} MB`} />
      )}
    </>
  );
};

ImageUploads.propTypes = {
  values: PropTypes.object,
  setFieldValue: PropTypes.func,
  title: PropTypes.string,
  description: PropTypes.string,
  acceptedTypes: PropTypes.arrayOf(PropTypes.string),
};

export default ImageUploads;
