import styled from 'styled-components/macro';

import breakPoints from 'theme/Media';

export const OrderSummaryContainer = styled.div.attrs({ className: 'OrderSummary' })`
  background: ${(props) => props.theme.farmProject.gray_2};
  display: flex;
  flex-direction: column;
  font-family: Rubik, sans-serif;
  box-sizing: border-box;
  position: ${(props) => (props.isCheckout ? 'initial' : 'fixed')};
  z-index: 2023;
  top: 0;
  right: ${(props) => (props.isOpen ? '0' : '-100vw')};
  height: 100%;
  width: 100%;
  padding: ${(props) => (props.isCheckout ? '0' : '10px 20px')};
  transition: right 300ms ease-in;
  overflow: auto;
  -ms-overflow-style: none;
  &::-webkit-scrollbar {
    width: 0 !important;
  }
  * {
    font-family: Rubik, sans-serif;
  }
`;

export const CheckoutHeader = styled.div.attrs({ className: 'CheckoutHeader' })`
  width: 100%;
  background-color: ${(props) => props.theme.farmProject.dark_gray};
  display: flex;
  justify-content: space-between;
  padding: 1rem;
  box-sizing: border-box;
  align-items: baseline;
`;

export const Header = styled.div.attrs({ className: 'Header' })`
  width: 100%;
  position: relative;
  padding-top: 0.5rem;

  button {
    position: absolute;
    top: 0;
    right: 0;
    font-size: 2rem;
    border: none;
    padding: 0;
    cursor: pointer;
    text-rendering: auto;
    background: transparent;
  }
`;

export const ItemsContainer = styled.ul.attrs({
  className: 'ItemsContainer',
})`
  display: flex;
  flex-direction: column;
  padding: 10px 0;
  margin: 1rem 0;
  width: 100%;
  box-sizing: border-box;
  overflow-y: auto;
  overflow-x: hidden;
  border-bottom: 0.6px solid #383a36;
  @media ${breakPoints.medium_break} {
    padding 0.7rem 1.5rem;
  }
`;
