// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-create-account-index-js": () => import("./../../../src/pages/create-account/index.js" /* webpackChunkName: "component---src-pages-create-account-index-js" */),
  "component---src-pages-js": () => import("./../../../src/pages/[...].js" /* webpackChunkName: "component---src-pages-js" */),
  "component---src-pages-resources-contentful-blog-category-slug-js": () => import("./../../../src/pages/resources/{contentfulBlogCategory.slug}.js" /* webpackChunkName: "component---src-pages-resources-contentful-blog-category-slug-js" */),
  "component---src-pages-resources-contentful-blog-post-slug-js": () => import("./../../../src/pages/resources/{contentfulBlogPost.slug}.js" /* webpackChunkName: "component---src-pages-resources-contentful-blog-post-slug-js" */),
  "component---src-pages-resources-index-js": () => import("./../../../src/pages/resources/index.js" /* webpackChunkName: "component---src-pages-resources-index-js" */),
  "component---src-templates-landing-page-data-provider-js": () => import("./../../../src/templates/LandingPageDataProvider.js" /* webpackChunkName: "component---src-templates-landing-page-data-provider-js" */)
}

