import React from 'react';

import { LandingPageWrapper } from 'components';
import Head from 'components/Head';

const logRocketScript = (gatsbyAppVersion) => `
  function loadLogRocket(message, source, lineno, colno, error) {
    if (
      window.location.host.indexOf('dev.lettucegrow.com') < 0 &&
      window.location.host.indexOf('staging.lettucegrow.com') < 0 &&
      window.location.host.indexOf('release.lettucegrow.com') < 0 &&
      window.location.host.indexOf('localhost') < 0 &&
      window.location.host.indexOf('lettucegrow.com') > -1 &&
      window.LogRocket
    ) {
      window.LogRocket.init('yibjp2/lg-prod', { release: 'lettucegrow-${gatsbyAppVersion}' });
      window.LogRocket.captureException(error, {
        extra: {
          message,
          source,
          lineno,
          colno,
          error,
        },
      });
    }
    console.error(error);
  }
  if (typeof window !== 'undefined') window.onerror = loadLogRocket;
`;

const WrapWithLayout = ({ element, props }) => {
  // HEADS UP - client rendered pages will have no fields/content at this point
  const fields = props.pageContext?.content?.fields;
  return (
    <>
      {props.pageContext?.slug ? (
        <Head
          title={fields?.metaTitle}
          description={fields?.metaDescription}
          ogImage={fields?.metaFogImage?.fields?.file?.url}
          twitterImage={fields?.metaTwitterImage?.fields?.file?.url}
          canonicalRoute={props.pageContext?.slug}
        >
          <meta name='viewport' content='width=device-width, initial-scale=1, maximum-scale=1' />
          <script src='https://cdn.logrocket.io/LogRocket.min.js' type='text/javascript'></script>
          <script type='text/javascript'>{logRocketScript(process.env.GATSBY_APP_VERSION)}</script>
          <script type='module' src='https://cdn.jsdelivr.net/npm/@slightlyoff/lite-vimeo@0.1.1/lite-vimeo.js'></script>
          <script src='https://a40.usablenet.com/pt/c/lettucegrow/start' type='text/javascript' async></script>
          <script src='https://cmp.osano.com/6olcGSh8XmDF3V0H/eaea4254-b86b-4e5f-8c46-f8e7ba652347/osano.js'></script>
        </Head>
      ) : (
        <Head>
          <meta name='viewport' content='width=device-width, initial-scale=1, maximum-scale=1' />
          <script src='https://cdn.logrocket.io/LogRocket.min.js' type='text/javascript'></script>
          <script type='text/javascript'>{logRocketScript(process.env.GATSBY_APP_VERSION)}</script>
          <script type='module' src='https://cdn.jsdelivr.net/npm/@slightlyoff/lite-vimeo@0.1.1/lite-vimeo.js'></script>
          <script src='https://a40.usablenet.com/pt/c/lettucegrow/start' type='text/javascript' async></script>
          <script src='https://cmp.osano.com/6olcGSh8XmDF3V0H/eaea4254-b86b-4e5f-8c46-f8e7ba652347/osano.js'></script>
        </Head>
      )}
      <LandingPageWrapper>{element}</LandingPageWrapper>
    </>
  );
};

export default WrapWithLayout;
