import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from '@reach/router';
import queryString from 'query-string';

import { setIsLoading } from 'reduxState/appSettings';
import { getLoginState, getEmail, getCAPIUserInfo } from 'reduxState/user';

import paths from 'constants/paths';
import LGTagManager from 'utils/googleTagManager';
import { useAuth } from 'utils/hooks';

// actions and events based on location change

export default () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const auth = useAuth();
  const userEmail = useSelector(getEmail);
  const capiData = useSelector(getCAPIUserInfo);
  const isLoggedIn = useSelector(getLoginState);
  const [previousPath, setPreviousPath] = useState('');

  useEffect(() => {
    const dispatchLoading = () => {
      const { pathname } = location || {};
      const newPath = pathname.endsWith('/') ? pathname.slice(0, -1) : pathname;
      const oldPath = previousPath.endsWith('/') ? previousPath.slice(0, -1) : previousPath;
      if (newPath !== oldPath) {
        dispatch(setIsLoading(true));
      }
    };

    dispatchLoading();

    const params = location ? queryString.parse(location.search) : '';
    LGTagManager.pushPath(location.pathname, params, userEmail, isLoggedIn, capiData);
    setPreviousPath(location.pathname);
    // eslint-disable-next-line
  }, [location]);

  useEffect(() => {
    // clear Firebase session for guest user after order page is navigated away from
    if (previousPath.includes(paths.ORDER_CONFIRMATION) && !location.pathname.includes(paths.ORDER_CONFIRMATION) && !isLoggedIn) {
      auth.signOut();
    }
    /* eslint-disable-next-line */
  }, [location]);
};
