import styled from 'styled-components';

import { Radio } from 'elements';

export const LightsSelectorStyled = styled.div.attrs({
  className: 'LightsSelectorStyled',
})`
  display: flex;
  flex-direction: column;
  margin: 1rem 0;
`;

export const OptionButtonGroup = styled.div.attrs({
  className: 'OptionButtonGroup',
})`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  margin: 0.25rem 0 0.5rem;
`;

export const RadioOption = styled(Radio).attrs({
  className: 'RadioOption',
})`
  font-size: 0.875rem;
  font-weight: 300;

  div {
    margin-right: 0.5rem;
  }
  &:not(:last-of-type) {
    margin-bottom: 0.35rem;
  }
`;
