import styled from 'styled-components/macro';
import { applyStyleModifiers } from 'styled-components-modifiers';

export const modifiers = {
  center: () => ({
    styles: `
      text-align: center;

      .Text {
        text-align: center;
      }
      .ButtonText {
        margin: auto;
      }
    `,
  }),
};

export const GrowEnvironmentToggleStyled = styled.div.attrs({
  className: 'GrowEnvironmentToggleStyled',
})`
  ${applyStyleModifiers(modifiers)};
`;
