import styled from 'styled-components';
import { applyStyleModifiers } from 'styled-components-modifiers';

import breakPoints from 'theme/Media';

export const modifiers = {
  inverted: ({ theme }) => ({
    styles: `
      background-color: ${theme.farmProject.dark_gray};

      .Link {
        &:hover {
          color: ${theme.farmProject.light_green_5};
        }
      }

      .Text {
        a {
          color: ${theme.farmProject.accent_primary};
        }
      }
    `,
  }),
  accent: ({ theme }) => ({
    styles: `
      background-color: ${theme.farmProject.accent_primary};
      .Text {
        color: ${theme.farmProject.dark_gray};
        *, a {
          color: ${theme.farmProject.dark_gray};
        }
      }
    `,
  }),
  gray2: ({ theme }) => ({
    styles: `
      background-color: ${theme.farmProject.gray_2};
      * {
        color: ${theme.farmProject.dark_gray};
      }
      .Text {
        color: ${theme.farmProject.dark_gray};
        *, a {
          color: ${theme.farmProject.dark_gray};
        }
      }
    `,
  }),
  brandColorFont: ({ theme }) => ({
    styles: `
      background-color: ${theme.farmProject.gray_3};
      .Text {
        color: ${theme.farmProject.green};
        *, a {
          color: ${theme.farmProject.green};
        }
      }
    `,
  }),
  lightGreen: ({ theme }) => ({
    styles: `
      background-color: ${theme.farmProject.light_green_2};
      .Text {
        color: ${theme.farmProject.dark_gray};
        *, a {
          color: ${theme.farmProject.dark_gray};
        }
      }
    `,
  }),
  lightGreen2: ({ theme }) => ({
    styles: `
      background-color: ${theme.farmProject.light_green_5};
      .Text {
        color: ${theme.farmProject.dark_gray};
        *, a {
          color: ${theme.farmProject.dark_gray};
        }
      }
    `,
  }),
  leftAlignedDesktop: () => ({
    styles: `
      @media ${breakPoints.large_break} {
        justify-content: flex-start;
      }
    `,
  }),
  rightAlignedDesktop: () => `
      @media ${breakPoints.large_break} {
        justify-content: flex-end;
      }
    `,
};

export const SlimBannerStyled = styled.div.attrs({
  className: 'SlimBanner',
})`
  width: 100%;
  height: auto;
  box-sizing: border-box;
  padding: 0.65rem;
  background-color: ${({ theme }) => theme.farmProject.brand_primary};
  display: flex;
  justify-content: center;
  align-items: center;

  * {
    color: ${({ theme }) => theme.farmProject.gray_3};
  }

  .Link {
    margin: 0 0.3rem;
    line-height: 1;
  }

  .Icon {
    margin: 0 0.5rem;
  }

  .Text {
    color: ${({ theme }) => theme.farmProject.gray_3};
    margin: 0;
    text-align: center;
    font-weight: 300;

    .Link,
    a {
      color: ${({ theme }) => theme.farmProject.gray_3};
      text-decoration: underline;
    }
  }

  ${applyStyleModifiers(modifiers)};
`;
