const environment = process.env.GATSBY_ENV; // Environment - local, development, staging, production
const gcsUrlPrefix = process.env.GATSBY_GCS_URL_PREFIX; // For backend points
const lgUrlPrefix = process.env.GATSBY_LG_URL_PREFIX; // For front end (affirm redirect)
const iosAppUrl = process.env.GATSBY_IOS_APP_URL; // For deep linking to IOS app/appstore
const contentCacheUrl = process.env.GATSBY_CONTENT_CACHE_URL;
const authDomain = process.env.GATSBY_AUTH_DOMAIN; // For firebase authentication
const stripeKey = process.env.GATSBY_STRIPE_KEY; // for Stripe payments
const klaviyoKey = process.env.GATSBY_KLAVIYO_KEY; // Klaviyo email integration public API key is linked to allan.araujo@oowlish.com
const fullStoryId = process.env.GATSBY_FULLSTORY_ORG_ID;
const yotpoAppKey = process.env.GATSBY_YOTPO_APP_KEY;
const posPassword = process.env.GATSBY_POS_PASSWORD;
const gatsbyIsPos = process.env.GATSBY_IS_POS;
const isPos = typeof gatsbyIsPos === 'string' ? gatsbyIsPos === 'true' : gatsbyIsPos;

module.exports = {
  environment,
  gcsUrlPrefix,
  authDomain,
  stripeKey,
  lgUrlPrefix,
  iosAppUrl,
  contentCacheUrl,
  klaviyoKey,
  fullStoryId,
  yotpoAppKey,
  isPos,
  posPassword,
};
