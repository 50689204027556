import React from 'react';
import PropTypes from 'prop-types';
import { graphql, StaticQuery } from 'gatsby';

import { ContentSection } from 'components';
import { GatsbyImage, Text, Title, Icon, Link } from 'elements';
import AccordionSection from 'components/deprecated/elements/AccordionSection';

import { ContentWrapper } from 'staticPages/HowItWorks/HowItWorks.styled';
import { LinkIconWrapper, AccordionWrapper, LettuceAppSection } from './GrowAppStatic.styled';

import AppStore from 'assets/images/app-store-badge.svg';
import PlayStore from 'assets/images/google-play-store-badge.svg';

const isIOS = (title) => title?.toLowerCase()?.includes('ios');

const GrowApp = ({ id, title, sections, additionalButtons }) => {
  const imageData = graphql`
    query {
      contentfulSectionBlocks(contentful_id: { eq: "4fjsAemZVeVzNkfJnv8SwZ" }) {
        mediaBackground {
          description
          gatsbyImageData(layout: FULL_WIDTH, placeholder: DOMINANT_COLOR, formats: [AUTO, WEBP], quality: 70)
        }
      }
    }
  `;

  return (
    <StaticQuery
      query={imageData}
      render={(data) => {
        return (
          <LettuceAppSection key={id}>
            <ContentSection animation='fadeMixDownUp'>
              <ContentWrapper>
                <GatsbyImage
                  modifiers='relativePosition'
                  image={data?.contentfulSectionBlocks?.mediaBackground?.[0]?.gatsbyImageData}
                  alt={data?.contentfulSectionBlocks?.mediaBackground?.[0]?.description}
                />
              </ContentWrapper>
              <ContentWrapper>
                <Title as='h3' modifiers='secondarySmall' content={title} />
                <AccordionWrapper>
                  {sections.map((section) => {
                    const id = section.sys.id;
                    const { title, body } = section.fields;
                    return (
                      <AccordionSection key={id} title={title}>
                        <Text content={body} />
                      </AccordionSection>
                    );
                  })}
                </AccordionWrapper>
                <LinkIconWrapper>
                  {additionalButtons?.map((item) => (
                    <Link key={item?.fields?.name} href={item?.fields?.linkUrl} target='_blank'>
                      <Icon>{isIOS(item?.fields?.name) ? <AppStore /> : <PlayStore />}</Icon>
                    </Link>
                  ))}
                </LinkIconWrapper>
              </ContentWrapper>
            </ContentSection>
          </LettuceAppSection>
        );
      }}
    />
  );
};

GrowApp.propTypes = {
  id: PropTypes.string,
  title: PropTypes.string,
  sections: PropTypes.array,
  additionalButtons: PropTypes.array,
};

export default GrowApp;
