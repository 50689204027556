import React from 'react';
import PropTypes from 'prop-types';
import { styleModifierPropTypes } from 'styled-components-modifiers';

import FlexStyled, { modifiers } from './Flex.styled';

const Flex = ({ id, modifiers, children, className, onClick }) => {
  return (
    <FlexStyled id={id} className={className ? 'Flex ' + className : 'Flex'} modifiers={modifiers} onClick={onClick}>
      {children}
    </FlexStyled>
  );
};

Flex.propTypes = {
  modifiers: styleModifierPropTypes(modifiers),
  children: PropTypes.any,
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  className: PropTypes.string,
  onClick: PropTypes.func,
};

export default Flex;
