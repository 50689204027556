import React from 'react';
import { Formik, Form, Field } from 'formik';
import { useSelector, useDispatch } from 'react-redux';
import * as Yup from 'yup';
import PropTypes from 'prop-types';
import { useLocation } from '@reach/router';

import { useBreakpoint } from 'utils/hooks';
import { userLogin } from 'reduxState/user';
import { setOpenModal, setCloseModal } from 'reduxState/modal';
import logError from 'utils/errorHandler';
import { axiosGet } from 'utils/api-utils';
import { useAuth } from 'utils/hooks/useAuth';
import { Title, Text, Input, SquaredButton, ButtonText } from 'elements';
import { LoginForm as LoginFormStyled } from './LoginForm.styled';

import CreateAccountDisclaimer from './components/create-account-disclaimer/CreateAccountDisclaimer';

const LoginForm = ({ onSubmit, isOnModal }) => {
  const auth = useAuth();
  const dispatch = useDispatch();
  const messages = useSelector((state) => state.contentful.messages) || {};
  const user = useSelector((state) => state.user);
  const size = useBreakpoint();
  const location = useLocation();
  const { LGE_email_invalid, LGE_email_required, LGE_required, LG_set_password_success } = messages;
  const general_error = messages['auth/network-request-failed'];

  const onCloseModal = () => {
    if (isOnModal) dispatch(setCloseModal());
  };

  const onSubmitLogin = async (values, { setSubmitting, setErrors }) => {
    // *** Form Submit Started ***
    let signedIn = null;
    try {
      signedIn = await auth.signIn(values.email, values.password);
    } catch (err) {
      window?.dataLayer?.push({ event: 'formSubmitFailure', formId: 'login' });

      setErrors({ password: general_error });
      return setSubmitting(false);
    }

    if (!signedIn) {
      // send GTM event
      window?.dataLayer?.push({ event: 'formSubmitFailure', formId: 'login' });

      // Sign in failed.
      setErrors({ password: general_error });
      return setSubmitting(false);
    }

    const idToken = await signedIn.getIdToken();
    handleSignedIn(idToken);
    setSubmitting(false);
  };

  const handleSignedIn = async (authToken) => {
    try {
      // call for any logged in user to ensure guest flag is reset
      await axiosGet(`/app/lgcom/unguest`, {}, authToken);
      dispatch(userLogin());

      onSubmit();
      // GTM Event push
      window?.dataLayer?.push({ event: 'formSubmitted', formId: 'login' });
    } catch (error) {
      console.log('Error: ', error);
      logError(error);
    }
  };

  return (
    <LoginFormStyled modifiers={isOnModal ? 'modal' : 'page'}>
      <Title as='h2' content='WELCOME BACK!' modifiers={{ MD: 'xLarge', SM: 'small' }} size={size} />
      <Text modifiers='xLarge' content='Please sign in to continue.' />
      {location?.state?.from === 'setPassword' && messages && <Text modifiers='xLarge' content={LG_set_password_success} />}
      <Formik
        onSubmit={onSubmitLogin}
        initialValues={{ email: user.email, password: '' }}
        validationSchema={Yup.object().shape({
          email: Yup.string().email(LGE_email_invalid).required(LGE_email_required),
          password: Yup.string().required(LGE_required),
        })}
      >
        <Form noValidate>
          <Field
            required
            variant='filled'
            name='email'
            type='email'
            label='E-mail'
            helperText='*Required'
            dataNw='email-input'
            component={Input}
          />
          <Field
            required
            variant='filled'
            name='password'
            type='password'
            label='Password'
            helperText='*Required'
            dataNw='password-input'
            component={Input}
          />
          <SquaredButton type='submit' label='SUBMIT' dataNw='submit-button' />
          <ButtonText
            type='button'
            label='Forgot your password'
            onClick={() => dispatch(setOpenModal('passwordReset'))}
            modifiers={['secondary', 'underline']}
          />
          <CreateAccountDisclaimer onCTAClick={onCloseModal} />
        </Form>
      </Formik>
    </LoginFormStyled>
  );
};

LoginForm.propTypes = {
  onSubmit: PropTypes.func,
  isOnModal: PropTypes.bool,
};

export default LoginForm;
