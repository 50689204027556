import 'src/theme/global.css';
import 'src/scss/main.scss';
import wrapWithProvider from './wrap-with-provider';
import wrapWithLayout from './wrap-with-layout';
import { v1 as uuidv1 } from 'uuid';
import { axiosGet } from 'utils/api-utils';

import { setStorage, getStorage } from 'utils/storageManager';

export const wrapRootElement = wrapWithProvider;
export const wrapPageElement = wrapWithLayout;

// app initially mounts
export const onInitialClientRender = () => {
  const lgUserId = getStorage('lgClientId');
  if (!lgUserId) {
    // TODO: save storage key as a constant (different branch currently)
    setStorage('lettucegrow-newUser', true);
    setStorage('lgClientId', uuidv1());
    axiosGet('/app/public/hello');
  } else {
    setStorage('lettucegrow-newUser', false);
  }
};

// fires when the client route changes
export const onRouteUpdate = ({ location, prevLocation }) => {
  if (window !== undefined) {
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({ event: 'optimize.pagechange' });

    //notify the Sidekick of the route update
    window.Gladly?.navigate?.();
  }
};
