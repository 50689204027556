import React from 'react';
import PropTypes from 'prop-types';
import MakerEnhance from 'maker-enhance';

import MarketingInner from './MarketingContainer.styled.js';

import { useStopLoading } from 'utils/hooks';

const MarketingContainer = ({ modifiers, makerSectionId }) => {
  useStopLoading(true);
  return (
    <MarketingInner modifiers={modifiers}>
      <MakerEnhance user='lettucegrow' index={makerSectionId} />
    </MarketingInner>
  );
};

MarketingContainer.propTypes = {
  modifiers: PropTypes.oneOfType([PropTypes.array, PropTypes.string]),
  makerSectionId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
};

export default MarketingContainer;
