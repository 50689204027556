import { configureStore } from '@reduxjs/toolkit';
import { getStorage } from 'utils/storageManager';
import storageKeys from 'constants/storageKeys';
import { environment } from 'utils/envConfig';

// Reducers
import cartReducer, { initialState as cartInitialState } from './cart';
import contentfulReducer from './contentful';
import modalReducer from './modal';
import userReducer, { initialState } from './user';
import appSettingsReducer from './appSettings';
import catalogReducer from './catalog';
import reservationReducer, { initialState as reservationInitialState } from './reservation/reservation';
import userGeneratedReducer from './userGenerated/userGenerated';
import bundlesSeedlingsReducer from './bundlesSeedlings';
import globalUIReducer from './globalUI';

// Middleware
import middleware from './middleware';
import sagaMiddleware, { rootSaga } from './middleware/sagas/';

// init LogRocket for staging / production
if (environment === 'staging' || environment === 'production') {
  if (typeof window !== 'undefined') {
    const filterState = (state) => {
      /* eslint-disable-next-line no-unused-vars */
      const { contentful, catalog, ...restState } = state;
      return {
        ...restState,
      };
    };

    const filterAction = (action) => {
      if (action.type && action.payload && action.type.includes('contentful')) {
        return {
          type: action.type,
          payload: { label: action.payload.label },
        };
      } else {
        return action;
      }
    };

    window.LogRocket &&
      middleware.push(window.LogRocket?.reduxMiddleware?.({ stateSanitizer: filterState, actionSanitizer: filterAction }));
  }
}

export default () => {
  const cartStorage = getStorage(storageKeys.CART) || getStorage('lettucegrow-cart-0330');
  const userStorage = getStorage(storageKeys.USER) || getStorage('lettucegrow-user-122821');
  const store = configureStore({
    reducer: {
      appSettings: appSettingsReducer,
      cart: cartReducer,
      contentful: contentfulReducer,
      modal: modalReducer,
      user: userReducer,
      catalog: catalogReducer,
      reservation: reservationReducer,
      userGenerated: userGeneratedReducer,
      bundlesSeedlings: bundlesSeedlingsReducer,
      globalUI: globalUIReducer,
    },
    middleware,
    preloadedState: {
      // load initial state from local storage
      cart: Object.assign({}, cartInitialState, cartStorage),
      user: Object.assign({}, initialState, userStorage),
      reservation: Object.assign({}, reservationInitialState, getStorage('lettucegrow-reservation-428')),
    },
  });

  sagaMiddleware.run(rootSaga);

  return store;
};
