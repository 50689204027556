import { applyStyleModifiers } from 'styled-components-modifiers';
import styled from 'styled-components/macro';

export const modifiers = {
  alignStart: () => ({
    styles: `
      align-items: flex-start;
    `,
  }),
  alignCenter: () => ({
    styles: `
      align-items: center;
    `,
  }),
  alignEnd: () => ({
    styles: `
      align-items: flex-end;
    `,
  }),
  alignSelfStretch: () => ({
    styles: `
      align-self: stretch;
    `,
  }),
  row: () => ({
    styles: `
      flex-direction: row;
    `,
  }),
  rowReverse: () => ({
    styles: `
      flex-direction: row-reverse;
    `,
  }),
  justifyCenter: () => ({
    styles: `
      justify-content: center;
    `,
  }),
  justifyEnd: () => ({
    styles: `
      justify-content: flex-end;
    `,
  }),
  spaceBetween: () => ({
    styles: `
      justify-content: space-between;
    `,
  }),
  directionColumn: () => ({
    styles: `
      flex-direction: column;
    `,
  }),
  directionColumnReverse: () => ({
    styles: `
      flex-direction: column-reverse;
    `,
  }),
  flex1: () => ({
    styles: `
      flex: 1;
    `,
  }),
  flex0: () => ({
    styles: `
      flex: 0;
    `,
  }),
};

export default styled.div`
  display: flex;
  ${applyStyleModifiers(modifiers)};
`;
