import React from 'react';
import { useLocation } from '@reach/router';
import { Link } from 'gatsby';
import PropTypes from 'prop-types';

import Shop from './Shop';
import AccountMenu from './AccountMenu';
import { List, ListItem } from './Navigation.styled';
import Search from './Search';

import paths from 'constants/paths';

import { trackClick } from 'utils/googleTagManager';

const Menu = ({ isOpen, isPageScrolled }) => {
  const location = useLocation();

  const LINKS = [
    {
      label: 'How It Works',
      path: paths.EXPERIENCE,
    },
    {
      label: 'The Farmstand',
      path: paths.FARMSTAND,
    },
    {
      label: 'Seedlings',
      path: paths.SEEDLINGS,
    },
    {
      label: 'Our Mission',
      path: paths.OUR_MISSION,
    },
    {
      label: 'Resources',
      path: paths.BLOG,
    },
  ];

  return (
    <List isOpen={isOpen} isPageScrolled={isPageScrolled}>
      <Search isExpanded={true} />
      <Shop />
      {LINKS.map((link) => {
        const isActive = location.pathname.includes(link.path);
        return (
          <Link key={link.label} to={link.path} onClick={() => trackClick({ action: link.label })}>
            <ListItem isActive={isActive}>{link.label}</ListItem>
          </Link>
        );
      })}
      <hr />
      <AccountMenu isOpen={true} />
    </List>
  );
};

Menu.defaultProps = {
  isOpen: false,
  isPageScrolled: false,
};

Menu.propTypes = {
  isOpen: PropTypes.bool,
  isPageScrolled: PropTypes.bool,
};

export default Menu;
