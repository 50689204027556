import React from 'react';
import PropTypes from 'prop-types';

import { Icon, Text, Tooltip } from 'elements';

import { IconFeatureStyled, FeatureList, ListItem } from './FarmIconFeatures.styled';

import RecycleIcon from 'elements/icon/assets/recycle-icon.svg';
import WaterIcon from 'elements/icon/assets/water-icon.svg';
import Farmstands from 'elements/icon/assets/farmstands-group-icon.svg';
import Home from 'elements/icon/assets/home.svg';
import Glowrings from 'elements/icon/assets/glowrings.svg';
import Savings from 'elements/icon/assets/glowring-savings.svg';
import SeedlingPack from 'elements/icon/assets/seedling_NEW.svg';
import Warranty from 'elements/icon/assets/warranty-icon.svg';
import MoneyBack from 'elements/icon/assets/money-back-icon.svg';

const iconMap = {
  '1B8kTNTa50D1ayQObVVqmf': <RecycleIcon />,
  l4aS4sokALfYpjpSZ8zuL: <WaterIcon />,
  '386f71mvxTGlWQmclyBA22': <Farmstands />,
  '5OO8bxs67Y0HpmD8LOofDL': <Home />,
  '3gOrqfKtZ9OOzNEbBB5YRD': <Glowrings />,
  vP40lG2Em20ogOY1lSAix: <Savings />,
  '70mvJEuGZXtMFqnYOL9o22': <SeedlingPack />,
  '7dLLPmfzX05gnbJLbSqTye': <MoneyBack />,
  '280flBlGSUtgNW6NAwuHMZ': <Warranty />,
  '5Si64RDL5iTRrVyNXtTyX6': <Farmstands />,
  '6pJfrx7chStCub93rP2BJq': <MoneyBack />,
  '1RXsReC4lXrANJuwqyhViy': <Farmstands />,
  '79BbEqTSI8FTrS2HWKVXbZ': <WaterIcon />,
};

const IconFeature = ({ data }) => {
  const renderListItem = (itemData) => {
    const id = itemData?.sys?.id;
    const title = itemData?.fields?.title || itemData?.fields?.text;
    const toolTip = itemData?.fields?.body;

    return (
      <ListItem key={id}>
        <Icon>{iconMap[id]}</Icon>
        <Text modifiers={['brandFont', 'noLineHeight']} content={title} />
        {toolTip && <Tooltip text={toolTip} contentModifiers='filledArow' uncontrolled />}
      </ListItem>
    );
  };

  return (
    <IconFeatureStyled>
      <FeatureList>{data.map(renderListItem)}</FeatureList>
    </IconFeatureStyled>
  );
};

IconFeature.propTypes = {
  data: PropTypes.array,
};

export default IconFeature;
