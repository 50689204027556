import { useState, useRef, useCallback } from 'react';

/**
 * * useIsInViewport - Custom hook to determine if element is inViewport
 *
 * @param {number} threshold - threshold option (0-1) % of how much target is in view to trigger callback
 *
 * @return {Array} [isInViewport, targetRef]
 * @return {bool} isInViewport - isInViewport is a bool that's true if target is in viewport
 * @return {node} targetRef - callback Ref for target
 *
 * */
export default function useIsInViewport(threshold = 0.5) {
  const [isInViewport, setIsInViewport] = useState(false);

  const targetRef = useRef(null);
  const setTargetRef = useCallback(
    (node) => {
      // gracefully degrade experience for IE users
      if (!window.IntersectionObserver) {
        setIsInViewport(true);
        return;
      }

      // Create IntersectionObserver
      const intersectionObserver = new IntersectionObserver(
        (entries) => {
          if (entries[0].intersectionRatio < threshold) {
            // If intersectionRatio is 0, the target left viewport.
            setIsInViewport(false);
          } else {
            // target entered viewport
            setIsInViewport(true);
          }
        },
        { threshold }
      );

      if (targetRef.current) {
        // Unobserve last ref
        intersectionObserver.unobserve(targetRef.current);
      }

      if (node) {
        // Node is element so start observing that
        intersectionObserver.observe(node);
      }

      // Save a reference to the node
      targetRef.current = node;
    },
    [threshold]
  );

  return [isInViewport, setTargetRef];
}
