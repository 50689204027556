import React from 'react';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';

import { QtyControl, AddToCartStyled } from './AddToCart.styled';
import { Text } from 'elements';

import { getItemInCart, addItem, updateItem, removeItem } from 'reduxState/cart';
import { useBreakpoint } from 'utils/hooks';
import { formatDynamicDecimalPrice } from 'utils/cart-utils';

const AddToCart = ({
  item,
  label,
  isDisabled,
  isSoldOut,
  isOutOfSeason,
  outOfSeasonLabel,
  isLoading,
  showPrice,
  onAddItem,
  onFirstAdd,
  modifiers,
  disabledModifier,
  onAddToCart,
  onRemoveFromCart,
  isTrackingEvent,
}) => {
  const cartItem = useSelector((state) => getItemInCart(state, item.sku));
  const reservedItem = useSelector((state) => state.reservation.items.find((resItem) => resItem.sku === item.sku));
  const dispatch = useDispatch();
  const size = useBreakpoint();
  const itemQty = (cartItem && cartItem.qty) || 0;
  const hasAnyItems = itemQty > 0;
  const soldOutLabel = isOutOfSeason ? outOfSeasonLabel : 'SOLD OUT';
  const isSoldOutOrOutOfSeason = isSoldOut || isOutOfSeason;

  const addToCart = () => {
    if (isDisabled) return;
    const itemToAdd = {
      ...item,
      qty: 1,
    };
    onAddItem && onAddItem();
    onFirstAdd && onFirstAdd();
    onAddToCart(itemToAdd);
    dispatch(addItem({ item: itemToAdd }));
  };

  const incrementQty = () => {
    if (isDisabled) return;
    const itemToUpdate = {
      sku: item.sku,
      qty: cartItem.qty + 1,
    };

    onAddItem && onAddItem();
    onAddToCart({ ...item, qty: cartItem.qty + 1 });
    dispatch(updateItem(itemToUpdate));
  };

  const decrementQty = () => {
    if (isDisabled) return;

    if (cartItem.qty === 1) {
      dispatch(removeItem(item.sku));
    } else {
      const itemToUpdate = {
        sku: item.sku,
        qty: cartItem.qty - 1,
      };
      dispatch(updateItem(itemToUpdate));
    }
    onRemoveFromCart({ ...item, qty: cartItem.qty - 1 });
  };
  return (
    <>
      {hasAnyItems && !isLoading ? (
        <QtyControl
          onDecrease={decrementQty}
          onIncrease={incrementQty}
          disabledIncrease={reservedItem && reservedItem.max && itemQty >= reservedItem.max}
          quantity={itemQty}
          isActive={!isDisabled && (!isSoldOutOrOutOfSeason || !!reservedItem)}
          modifiers={size === 'SM' && 'fluid'}
        />
      ) : (
        <AddToCartStyled
          label={
            isSoldOutOrOutOfSeason && !reservedItem
              ? soldOutLabel
              : isLoading
              ? '...ADDING TO CART'
              : `${label} ${showPrice && item.priceCents ? formatDynamicDecimalPrice(item.priceCents) : ''}`
          }
          onClick={addToCart}
          eventData={isTrackingEvent ? { action: `${label}: ${item.name}` } : {}}
          modifiers={[
            ...modifiers,
            size === 'SM' && 'fluid',
            'tertiary',
            (isDisabled || (isSoldOutOrOutOfSeason && !reservedItem) || isLoading) && (disabledModifier || 'disabled'),
          ]}
        />
      )}
      {reservedItem && reservedItem.max && <Text modifiers={['small', 'errorColor']}>{`Maximum of ${reservedItem.max}`}</Text>}
    </>
  );
};

AddToCart.defaultProps = {
  isDisabled: false,
  isLoading: false,
  showPrice: true,
  item: {},
  onAddItem: null,
  onFirstAdd: null,
  isSoldOut: false,
  isOutOfSeason: false,
  outOfSeasonLabel: 'OUT OF SEASON',
  label: 'ADD TO CART',
  modifiers: [],
  disabledModifier: 'disabled',
  onAddToCart: () => {},
  onRemoveFromCart: () => {},
  isTrackingEvent: true,
};

AddToCart.propTypes = {
  isDisabled: PropTypes.bool,
  isLoading: PropTypes.bool,
  showPrice: PropTypes.bool,
  item: PropTypes.shape({
    sku: PropTypes.string,
    qty: PropTypes.number,
    name: PropTypes.string,
    priceCents: PropTypes.number,
  }),
  onAddItem: PropTypes.func,
  onFirstAdd: PropTypes.func,
  isSoldOut: PropTypes.bool,
  isOutOfSeason: PropTypes.bool,
  outOfSeasonLabel: PropTypes.string,
  label: PropTypes.string,
  modifiers: PropTypes.oneOfType([PropTypes.array, PropTypes.string]),
  disabledModifier: PropTypes.string,
  onRemoveFromCart: PropTypes.func,
  onAddToCart: PropTypes.func,
  isTrackingEvent: PropTypes.bool,
};

export default AddToCart;
