import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { SquaredButton } from 'elements/index';

import { ToggleStyled, ToggleInner, ButtonGroup } from './Toggle.styled';

const Toggle = ({
  startOn = true,
  labelOn,
  labelOff,
  onChange,
  modifiers,
  onModifiers,
  offModifiers,
  shouldUseDefaultModifiers,
  eventDataActive,
  eventDataInactive,
}) => {
  const [isOn, setIsOn] = useState(startOn);
  const hasDarkGrayModifier = modifiers?.includes?.('darkGray') === true;
  const defaultOnModifiers = [
    isOn && !hasDarkGrayModifier && 'tertiary',
    !isOn && hasDarkGrayModifier && 'inverted',
    !isOn && !hasDarkGrayModifier && 'invertedTertiary',
  ];
  const defaultOffModifiers = [
    !isOn && !hasDarkGrayModifier && 'tertiary',
    isOn && hasDarkGrayModifier && 'inverted',
    isOn && !hasDarkGrayModifier && 'invertedTertiary',
  ];

  const onClickHandle = (toggleState) => {
    setIsOn(toggleState);
    onChange && onChange(toggleState);
  };

  useEffect(() => {
    if (startOn !== isOn) setIsOn(startOn);
    //eslint-disable-next-line
  }, [startOn]);

  return (
    <ToggleStyled>
      <ToggleInner>
        <ButtonGroup>
          <SquaredButton
            onClick={() => onClickHandle(true)}
            modifiers={[
              ...(shouldUseDefaultModifiers ? defaultOnModifiers : []),
              ...(typeof onModifiers === 'object' && onModifiers?.length ? onModifiers : [onModifiers]),
            ]}
            dataNw={labelOn}
            label={labelOn}
            eventData={eventDataActive}
          />
          <SquaredButton
            onClick={() => onClickHandle(false)}
            modifiers={[
              ...(shouldUseDefaultModifiers ? defaultOffModifiers : []),
              ...(typeof offModifiers === 'object' && offModifiers?.length ? offModifiers : [offModifiers]),
            ]}
            dataNw={labelOff}
            label={labelOff}
            eventData={eventDataInactive}
          />
        </ButtonGroup>
      </ToggleInner>
    </ToggleStyled>
  );
};

Toggle.propTypes = {
  startOn: PropTypes.bool,
  labelOn: PropTypes.string,
  labelOff: PropTypes.string,
  onChange: PropTypes.func,
  modifiers: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
  onModifiers: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
  offModifiers: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
  shouldUseDefaultModifiers: PropTypes.bool,
  eventDataActive: PropTypes.shape({
    action: PropTypes.string,
    currentUrl: PropTypes.string,
  }),
  eventDataInactive: PropTypes.shape({
    action: PropTypes.string,
    currentUrl: PropTypes.string,
  }),
};

Toggle.defaultProps = {
  shouldUseDefaultModifiers: true,
  eventDataActive: {},
  eventDataInactive: {},
};

export default Toggle;
