import React from 'react';
import PropTypes from 'prop-types';
import { styleModifierPropTypes } from 'styled-components-modifiers';
import { navigate } from 'gatsby';

import { prodLinkPathRegex } from 'utils/pattern-utils';
import ButtonTextBase, { modifiers } from '@lettucegrow/atoms.button-text';
import { trackClick } from 'utils/googleTagManager';

const Button = ({ id, type, disabled, onClick, label, modifiers, children, href, as, dataNw, eventData }) => {
  const handleClick = (event) => {
    eventData?.action && trackClick(eventData);
    onClick && onClick(event);

    const isHardCodedProdLink = href?.includes('lettucegrow.com') && !href?.includes('@');
    const isExternalOrActionLink = href && (href?.includes('http') || !href?.startsWith('/'));

    if (isHardCodedProdLink) {
      const pathPattern = prodLinkPathRegex;
      const path = href.match(pathPattern);
      path && path[1] && navigate(path[1]);
    } else if (isExternalOrActionLink) {
      window.location.href = href;
    } else {
      href && navigate(href);
    }
  };

  return (
    <ButtonTextBase
      id={id}
      type={type}
      label={label}
      disabled={disabled}
      onClick={handleClick}
      modifiers={modifiers}
      as={as}
      dataNw={dataNw}
    >
      {children}
    </ButtonTextBase>
  );
};

Button.propTypes = {
  id: PropTypes.string,
  as: PropTypes.string,
  type: PropTypes.string,
  disabled: PropTypes.bool,
  onClick: PropTypes.func,
  label: PropTypes.string,
  children: PropTypes.node,
  modifiers: styleModifierPropTypes(modifiers),
  href: PropTypes.string,
  dataNw: PropTypes.string,
  eventData: PropTypes.object,
};

Button.defaultProps = {
  disabled: false,
  onClick: null,
};

export default Button;
