import { v1 as uuidv1 } from 'uuid';
import shopCategories from 'constants/shopCategories';

const CheckoutURL = 'https://www.lettucegrow.com/checkout';

export const cartAddItem = ({ item, cart, supplies }) => {
  const { discountedPriceCents, name, slug, sku, category, subcategory, imageUrl, qty, shortDescription } = item;
  let priceCents = item.priceCents;
  if (!priceCents) {
    const bundle = Object.values(supplies)
      ?.filter?.((e) => e.category === shopCategories.CUSTOM_BUNDLES)
      ?.find((e) => e.sku === item.sku);
    priceCents = bundle?.priceCents;
  }

  const cartParsed = {
    $value: discountedPriceCents ? discountedPriceCents / 100 : priceCents / 100,
    AddedItemProductName: name,
    AddedItemProductID: slug,
    AddedItemSKU: sku,
    AddedItemCategories: subcategory ? [category, subcategory] : [category],
    AddedItemImageURL: imageUrl,
    AddedItemPrice: priceCents / 100,
    AddedItemQuantity: qty,
    ItemNames: [name, shortDescription],
    CheckoutURL,
    Items: cart.items.map(({ priceCents, name, slug, sku, category, subcategory, imageUrl, qty }) => ({
      ProductID: slug,
      SKU: sku,
      ProductName: name,
      Quantity: qty,
      ItemPrice: priceCents / 100,
      ImageURL: imageUrl,
      ProductCategories: subcategory ? [category, subcategory] : [category],
    })),
  };

  if (window._learnq) {
    window._learnq.push(['track', 'Added to Cart', cartParsed]);
  }
};

export const viewedProduct = (item) => {
  const { priceCents, name, sku, slug, category, subcategory, imageUrl } = item;

  const product = {
    URL: window.location.href,
    ProductName: name,
    ProductID: slug,
    SKU: sku,
    Categories: subcategory ? [category, subcategory] : [category],
    ImageURL: imageUrl,
    Price: priceCents / 100,
  };

  if (window._learnq) {
    window._learnq.push(['track', 'Viewed Product', product]);
    window._learnq.push([
      'trackViewedItem',
      {
        Title: product.ProductName,
        ItemId: product.ProductID,
        Categories: product.Categories,
        ImageUrl: product.ImageURL,
        Url: product.URL,
        Metadata: {
          Price: product.Price,
        },
      },
    ]);
  }
};

export const startedCheckout = ({ totalCents, items }) => {
  const Items = items.map(({ priceCents, name, slug, sku, category, subcategory, imageUrl, qty }) => ({
    ProductID: slug,
    SKU: sku,
    ProductName: name,
    Quantity: qty,
    ItemPrice: priceCents / 100,
    ImageURL: imageUrl,
    ProductCategories: subcategory ? [category, subcategory] : [category],
  }));

  const payload = {
    $event_id: uuidv1(),
    $value: totalCents / 100,
    CheckoutURL: CheckoutURL,
    ItemsNames: items.map(({ name }) => name),
    Items: Items,
  };

  if (window._learnq) {
    window._learnq.push(['track', 'Started Checkout', payload]);
  }
};

export const identify = ({ user = {}, customer = {} }) => {
  const track = {
    $email: user.email,
    $first_name: user.firstName,
    $last_name: user.lastName,
    $phone_number: user.phone,

    $address1: user.address || customer.address,
    $city: user.address || customer.city,
    $region: user.address || customer.state,
    $zip: user.address || customer.zip,
  };
  if (window._learnq) {
    window._learnq.push(['identify', track]);
  }
};
