import styled from 'styled-components';

import { SquaredButton } from 'elements';

export const FarmstandSizeStyled = styled.div.attrs({
  className: 'FarmstandSizeStyled',
})`
  display: flex;
  flex-direction: column;
  margin: 1rem 0;
`;

export const SizeButtonGroup = styled.div.attrs({
  className: 'SizeButtonGroup',
})`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin: 0.25rem 0 0.5rem;
`;

export const SelectSizeButton = styled(SquaredButton).attrs({
  className: 'SelectSizeButton',
})`
  height: 2.5rem;
  width: 2.5rem;
  padding: 0.25rem;
  margin-right: 0.5rem;

  span {
    font-size: 0.875rem;
  }
`;
