import styled from 'styled-components/macro';

export const ToggleStyled = styled.div.attrs({
  className: 'ToggleStyled',
})`
  width: 100%;
  max-width: 14rem;
  margin: auto;
`;

export const ToggleInner = styled.div.attrs({
  className: 'ToggleInner',
})``;

export const ButtonGroup = styled.div.attrs({
  className: 'ButtonGroup',
})`
  background-color: ${({ theme }) => theme.farmProject.gray_2};
  padding: 0.5rem;
  margin-top: 0.5rem;
  display: flex;
  justify-content: center;
  align-content: center;

  .Button {
    width: 50%;
  }
`;
